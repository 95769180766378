import React, { Component } from 'react'
import Game from './Game'

class GameContainer extends Component {
  render() {
    return (
      <Game {...this.props}/>
    )
  }
}

export default GameContainer