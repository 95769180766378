import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import { customStyles } from '../../../../utils/helpers'
import Button from '../../../Button'
import CreateSuggestion from '../../../CreateSuggestion'
import t from '../../../../utils/i18n'

const formatSuggestions = (suggestions) => {
  return suggestions.map((obj) => {
    return {
      value: obj._id,
      label: obj.title
    }
  })
}

const Suggestions = ({suggestions, axisId, onChange, defaultSuggestion, translations}) => {
  const [suggestion, onSuggestionChange] = useState(defaultSuggestion ? defaultSuggestion._id : null)
  const [newSuggestionId, onSuggestionCreated] = useState('')

  useEffect(() => {
    if (suggestions && suggestions.length > 0) {
      const selectedSuggestion = suggestions.find((obj) => obj._id === newSuggestionId)
      if (selectedSuggestion) {
        onSuggestionCreated('')
        onSuggestionChange(selectedSuggestion._id)
        onChange(selectedSuggestion._id)
      }
    }
  }, [suggestions])

  return (
    <Wrapper>     
      <StyledSelect
        styles={customStyles}
        options={formatSuggestions(suggestions)}
        onChange={(e) => { 
          onSuggestionChange(e.value) 
          onChange(e.value)
        }}
        placeholder={t('game.questions.suggestions.select')}
        value={formatSuggestions(suggestions).find(({value}) => value === suggestion)}
      />
      <AddSuggestion>
        <CreateSuggestion 
          axisId={axisId}
          onSuccess={(id) => { 
            onSuggestionCreated(id) 
          }}
        >
          <Button
            fullWidth={false}
            outline
          >
          {t('game.questions.suggestions.create')}
          </Button>
        </CreateSuggestion>
      </AddSuggestion>      
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 1rem 0;
  display: flex;
  align-items: center;
`

const StyledSelect = styled(Select)`
  flex-grow: 1;
`
const AddSuggestion = styled.div`
  padding-left: 1rem;
`

export default Suggestions