import React from 'react'
import styled from 'styled-components'
import IconSelector from './assets'

const Material = ({data}, props) => (
  <Wrapper>
    <IconSelector type={data.type} />
    <StyledAnchor href={data.link} target="_blank">{data.name}</StyledAnchor>
  </Wrapper>
)

const Wrapper = styled.div`
  display: flex; 
  align-items: center;
`
const StyledAnchor = styled.a `
  text-decoration: none;
  color: ${props => props.theme.gray};
`

export default Material