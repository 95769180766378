import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import RootComponent from "./app/Root";
import "./lang/i18n";
import configureStore from "./app/ConfigureStore";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import ReactGA from "react-ga";
import { hotjar } from "react-hotjar";

require("dotenv").config();

const store = configureStore();

const renderApp = () => {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALITYCS);
  hotjar.initialize(process.env.REACT_APP_HOTJAR_HJID, process.env.REACT_APP_HOTJAR_HJSV);
  return render(
    <Provider store={store}>
      <RootComponent />
    </Provider>,
    document.getElementById("root") || document.createElement("div") // for testing purposes
  );
};

// if (process.env.NODE_ENV !== 'production' && module.hot) {
//   module.hot.accept('./app/r', renderApp)
// }
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
renderApp();
serviceWorker.unregister();
