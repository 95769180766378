import { handleActions } from 'redux-actions'
import * as actions from './actions'

const initialState = {
  state: 'init',
  error: '',
  profile: {}
}

const reducer = handleActions({
  [actions.fetchProfile.START]: (state) => ({
    ...state,
    status: 'pending'
  }),
  [actions.fetchProfile.FAILURE]: (state, action) => ({
    ...state,
    status: 'failure',
    error: action.payload.error
  }),
  [actions.fetchProfile.SUCCESS]: (state, action) => ({
    ...state,
    status: 'success',
    profile: {
      ...action.payload.profile
    }
  })
}, initialState)

export default reducer