import { connect} from 'react-redux' 

import * as actions from '../actions'
import OverviewContainer from '../components/OverviewContainer'

const mapStateToProps = state => ({
  metrics: state.overview.metrics
})

const mapDispatchToProps = dispatch => ({
  onMount: () => dispatch(actions.fetchMetrics())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OverviewContainer)