import React, { useState } from 'react'
import styled from 'styled-components'
import t from '../../utils/i18n'
import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks' 

const ADD_AXIS = gql`
  mutation CreateAxis($name: String!) {
    createAxis(name: $name) {
      _id
      name
    }
  }
`

const AxisButton = ({ addAxis }) => {
  const [axisTitle, setAxisTitle] = useState('')

  const [createAxis] = useMutation(ADD_AXIS, {
    refetchQueries: ['game'],
    onCompleted: (data) => { 
      setAxisTitle('')       
    }
  })
  return (
    <AxisInputWrapper>
      <Input 
        type="text" 
        placeholder={t('game.axes.new')} 
        value={axisTitle} 
        onChange={(ev) => setAxisTitle(ev.target.value)} 
        size={axisTitle.length}
      />
      { axisTitle &&
        <Button onClick={() => createAxis({ 
          variables: {name: axisTitle}          
        }) }>+</Button>
      }
      </AxisInputWrapper>
  )
}

const AxisInputWrapper = styled.div`
  align-items: flex-start;
  background-color: #fafafd;
  border: 1px solid #C6CBD6;
  box-sizing: border-box;
  border-radius: 16px;
  cursor: text;
  display: flex;
  margin-bottom: 2rem;
  padding: 0.8rem;
  position: relative;
`

const Input = styled.input`
  background-color: #fafafd;
  border: none;
  color: #000000;
  font-size: 2rem;
  font-size: ${props => props.theme.font_size.medium};
  font-weight: bold;
  text-align: center;
`

const Button = styled.button`
  align-items: center;
  bottom: -12px;
  background-color: ${props => props.theme.primary};
  border: none;
  box-sizing: border-box;
  border-radius: 100%;
  color: white;
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  font-weight: bold;
  height: 40px;
  justify-content: center;
  position: absolute;
  right: -12px;
  width: 40px;
`

export default AxisButton