import React, { useState } from 'react'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import ArrowIcon from '../Icons/Arrow'
import TrashIcon from '../Icons/Trash'
import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import Modal from '../Modals/Small'
import Button from '../Button'


const DELETE_QUESTION = gql`
  mutation DeleteQuestion($_id: String!) {
    deleteQuestion(_id: $_id)
  }
`

const QuestionCard = ({ t, ...props }) => {

  const [ isOpen, handleModal ] = useState(false)

  const [deleteQuestion, { loading }] = useMutation(DELETE_QUESTION, {
    refetchQueries: ['game'],
    onCompleted: () => handleModal(false)
  })

  return (
    <React.Fragment>
      {isOpen &&(
        <Modal onCloseModal={() => handleModal(false)}>
          <ModalWrapper>
              <ModalText dangerouslySetInnerHTML={{__html: t('game.questions.delete.warning') }} />
              <ModalFooter>
                <CancelButton outline fullWidth={false} onClick={() => { handleModal(false) }}>
                  { t('game.questions.delete.cancel') }
                </CancelButton>
                <Button loading={loading} fullWidth={false} onClick={() => deleteQuestion({
                  variables: {
                    _id: props.data._id,
                  }
                })}>
                { t('game.questions.delete.confirm') }
                </Button>
              </ModalFooter>
          </ModalWrapper>
        </Modal>
      )}
      <Wrapper onClick={props.onClick}>      
        <TitleWrapper>
            <Title>{props.data.content}</Title>   
            <DeleteButton
              onClick={(e) => {          
                e.stopPropagation()
                handleModal(true)
              }}
            >
              <TrashIcon />
            </DeleteButton>     
        </TitleWrapper>

        { props.data.answers.map((ans) => (
          <AnswerWrapper key={ans._id}>
            <Answer>
              <AnswerDot></AnswerDot>
              <AnswerContent>
                { ans.content || `${t('game.questions.edit.answer.placeholder')} ${props.itemCount}` }
              </AnswerContent>
            </Answer>
            <ArrowIcon/>
            <Next>
              { ans.next ? ans.next.content : `${t('game.questions.edit.next')} ${props.itemCount}` }
            </Next>
          </AnswerWrapper>
        ))}
      </Wrapper>
    </React.Fragment>    
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.3rem 2rem;
  width: 51rem;
  min-height: 10rem;
  border-radius: 16px;
  border: 1px solid #d8d8d8;
  background: white;
  box-shadow 0;
  transition: box-shadow 0.2s;
  cursor: pointer;

  &:hover { 
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
`

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const DeleteButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #d8d8d8;
  cursor: pointer;
  height: 50px;
  width: 50px;
  transition: all 0.2s;

  > svg {
    height: 20px;
    width: 20px;
    fill: #3F4B63;
  }

  &:hover {
    background: ${props => props.theme.primary};  
    > svg {
      fill: white;
    }
  }
`

const ModalWrapper = styled.div`
  padding-top: 1rem;
`

const ModalText = styled.p`
  max-width: 360px;
  text-align: center;
`

const ModalFooter = styled.div`
  padding-top: 2rem;
  display: flex;
  justify-content: space-around;
`

const CancelButton = styled(Button)`
  margin: 0 1rem;
`

const Title = styled.div`
  color: #3F4B63;
  padding: 1rem;
  flex: 1;
  font-size: 1.2rem;
`

const AnswerWrapper = styled.div`
  display: flex;  
  justify-content: space-between;
  flex: 1;
  padding: 1rem 0;
`

const Answer = styled.div`
  display: flex;
  width: 40%;
`

const AnswerContent = styled.div`
  word-break: break-word;  
`

const AnswerDot = styled.div`
  height: 5px;
  min-width: 5px;
  background-color: ${props => props.theme.primary};
  border-radius: 50%;
  margin: 0.5rem 1rem;
`
const Next = styled.div`
  width: 40%;
`

export default withTranslation()(QuestionCard)
