import React from 'react';
import styled from 'styled-components';

const CardsContainer = ({ children }) => {
  return (
    <StyledCardContainer>
     {children}
    </StyledCardContainer>
  );
}

const StyledCardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;

export default CardsContainer;
