import React, { Component } from 'react'
import styled from 'styled-components'
import Tree from './components/Tree'

class QuestionsTree extends Component {

  constructor(props){
    super(props)
    this.state = {
      tree: null,
    }
  }

  componentDidMount() {
    if (this.props.questions) {
      this.parseTree()
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.questions && this.props.questions) {
      this.parseTree()
      return
    }

    if (prevProps.selectedAxis.name !== this.props.selectedAxis.name) {
      this.parseTree()
      return
    }
  }

  parseTree = () => {
    const firstQuestion = this.props.questions[0]
    const tree = {
      answers: firstQuestion.answers,      
      name: firstQuestion.content,
      _id: firstQuestion._id,
      children: this.cleanAnswers(firstQuestion.answers).map((ans) => { return this.parseQuestion(ans.next._id)})
    }

    this.setState({
      tree: tree
    })
  }

  cleanAnswers = (answers) => {
    let cleanAnswers = []
    answers.filter((ans) => { return !!(ans.next)}).forEach((a) => {
      if (!cleanAnswers.find(cleanA => cleanA.next._id == a.next._id )) {
        cleanAnswers.push(a)
      }
    })

    return cleanAnswers
  }

  parseQuestion = (questionId) => {
    const question = this.props.questions.find((q) => q._id == questionId)
    return {      
      answers: question.answers,
      name: question.content,
      _id: question._id,
      children: this.cleanAnswers(question.answers)        
        .map((ans) => { return this.parseQuestion(ans.next._id)})
    }
  }

  render(){
    return (
      <div>        
        <TreeWrapper>
          <Tree 
            tree={this.state.tree}
          />
        </TreeWrapper>
      </div>
    )
  }
}

const TreeWrapper = styled.div`
  margin: 2rem 0;
  border: 1px solid ${props => props.theme.primary};
  border-radius: 16px;
`

export default QuestionsTree