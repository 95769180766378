import React from "react";
import styled from "styled-components";
import t from "../../../../utils/i18n";
import Stars from "../../../../components/Icons/Stars";
import Title from "../../../Title";
import CustomTable from "../../../CustomTable";

const SuggestionsCount = ({ selectedAxis, suggestions }) => {
  return (
    <Wrapper>
      <TitleContainer>
        <Stars width={"20px"} padding={"0 0 10px"} />
        <Title color={'#1F2937'} fontSize={'medium'} fontWeight={'600'} padding={'18px 0 0px '} >
          {t("axes_metrics.top_suggestions.title")}
        </Title>
      </TitleContainer>
      <CustomTable>
        <tbody>
          {selectedAxis &&
            suggestions &&
            suggestions
              .filter((sug) => sug.axis._id === selectedAxis)
              .sort((a, b) => b.count - a.count)
              .slice(0, 5)
              .map((obj) => (
                <tr key={obj.suggestion._id}>
                  <TableCell>
                    <FlexContainer>
                      <Title
                        color={'#1F2937'} fontSize={'medium'} fontWeight={'500'} padding={'0 0.4rem 0.4rem'}
                      >
                        {obj.suggestion.title}
                      </Title>
                      <RecommendationNumber>
                        {obj.count}
                        </RecommendationNumber>
                    </FlexContainer>
                  </TableCell>
                </tr>
              ))}
        </tbody>
      </CustomTable>
    </Wrapper>
  );
};

const Wrapper = styled.div`
`;


const TableCell = styled.td`
  border-bottom: 0.1px solid ${(props) => props.theme.slate_gray};
  padding: 10px;
  font-size: 18px;
  width: 350px;
  vertical-align: top;
`;



const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RecommendationNumber = styled.div`
border-radius: 50%;
display: flex;
justify-content: center;
padding: 10px;
height: 28px;
width: 28px;
color: white;
background: ${(props) => props.theme.primary};
font-size: ${(props) => props.theme.font_size.xs};
text-align: center;
margin-bottom: 7px;
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
`;

// const Title = styled.p`
//   font-size: 18px;
// `

const CardsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
`;

const Card = styled.table`
  display: flex;
  align-items: center;
  padding: 0 1rem;
  margin: 0rem 1rem 0rem 0;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.slate_gray};
`;

const CardTitle = styled.p`
  padding-right: 2rem;
  font-weight: 600;
  font-size: 20px;
`;

export default SuggestionsCount;
