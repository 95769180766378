import React, { useState, useEffect } from "react";
import axios from "axios";
import t from "../../../utils/i18n";
import styled from "styled-components";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import Button from "../../../components/Button";
import Insight from "../../overview/components/_commons/Insight";
import BigLoader from "../../../components/BigLoader";
import Table from "../../../components/Table";
import AchievementCount from "../../../components/Achievements";
import Loader from "../../../components/Loader";
import Building from "../../../components/Icons/UserScene";
import CountUp from "react-countup";
import Controller from "../../../components/Icons/Controller";
import ClockHistory from "../../../components/Icons/ClockHistory";
import searchItem from "./searchItem";
import { calculateLastAccess } from "../../../utils/Date/calculateLastAccess";
import NoElements from "../../../components/NoElements";
import Card from "../../../components/Card";
import CardsContainer from "../../../components/CardsContainer";
import Status from "../../../components/Status";
import TableIcon from "../../../components/Icons/TableSortIcon";
import { parseDate } from "../../../utils/getParsedDate";


export const GET_RANKING = gql`
  query usersRanking($game: String) {
    usersRanking(game: $game) {
      achievementsCount
      playerStatus
      axesNotStarted
      axesInProgress
      axesComplete
      lastTimePlayed
      global_id
      organization_id
    }
  }
`;

const parseUsers = (users, data, handleRankedUsers) => {
  const parsedData = data.usersRanking.map((obj) => {

    const user = users.find((aux) => {
      return aux.id === obj.global_id;
    });

    return {
      achievements: obj.achievementsCount,
      ...user,
      ...obj,
    };
  });
  handleRankedUsers(parsedData);
};


const exportUsers = async (handleLoading) => {
  handleLoading(true);
  const res = await axios.get(
    `${process.env.REACT_APP_GAME_URL}/api/users/export`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/vnd.safaritec.v1+json",
        Authorization: `Token ${localStorage.getItem("token")}`,
        "X-Language": localStorage.getItem("i18nextLng"),
        "Game-id": process.env.REACT_APP_ORGANIZATION_ID,
      },
    }
  );

  const hiddenEl = window.document.createElement("a");
  hiddenEl.href = "data:text/csv;charset=utf-8," + encodeURI(res.data);
  hiddenEl.target = "_blank";
  hiddenEl.download = "export_usuarios.csv";
  hiddenEl.click();
  handleLoading(false);
};

const STATUES = {
  FINISHED: 'Completado',
  PLAYING: 'Jugando',
  INACTIVE: 'Inactivo',
  NOT_STARTED: 'Sin empezar'
}

const Ranking = ({ users, organizations, ranking }) => {

  const [rankedUsers, handleRankedUsers] = useState([]);
  const [searchByName, setSearchByName] = useState('')
  const [searchByOrganization, setSearchByOrganization] = useState('');
  const [filterByStatus, setFilterByStatus] = useState('');
  const [sortDirection, setSortDirection] = useState(null);
  const [achievementsSortDirection, setAchievementsSortDirection] = useState(null);

  const searchName = (e) => {
    setSearchByName(e.target.value);
  };
  const searchOrganization = (e) => {
    setSearchByOrganization(e.target.value);
  };
  const filterByStatuses = (e) => {
    setFilterByStatus(e.target.value);
  };

  const { data, loading } = useQuery(GET_RANKING, {
    variables: {
      game: process.env.REACT_APP_ORGANIZATION_ID,
    },
  });

  useEffect(() => {
    if (!loading && users && users.length > 0) {
      parseUsers(users, data, handleRankedUsers);
    }
  }, [users, loading]);


  const formatedRow = rankedUsers.map((row, i) => {
    return {
      index: i + 1,
      achievements: <AchievementCount achievement={row.achievements} />,
      name: row.name,
      organization: row.organization_name || " - ",
      status: STATUES[row?.playerStatus],
      axisInProgress: row.axesInProgress?.[0] || ' - ',
      completed: row.axesComplete.length || ' 0 ',
      last_sign_in_at: calculateLastAccess(parseInt(row.lastTimePlayed)) || ' - ',
    };
  });

  //translations header table
  const achievementsTable =  t("tables.players.achievements");
  const nameTable =  t("tables.players.name");
  const organizationTable =  t("tables.players.organization");
  const statusTable =  t("tables.players.status");
  const axisTable =  t("tables.players.axis");
  const completedTable =  t("tables.players.completed");
  const accesTable =  t("tables.players.access");
  const player_name = t("players.player_name");
  const organizationSelect = t("players.organization");
  const statusSelect = t("players.status");

  const headerTable = [
    "#",
    <div
    onClick={() => toggleSortDirection('achievements')}
    style={{ cursor: 'pointer', textWrap: 'nowrap'}}
  >
    {achievementsTable}
    <TableIcon height={'11px'} color={'#6B7280'}/>
  </div>,
    nameTable,
    organizationTable,
    statusTable,
    axisTable,
    completedTable,
    <div onClick={() => toggleSortDirection('access')} style={{ cursor: "pointer", textWrap: 'nowrap' }}>
    {accesTable}
    <TableIcon height={"11px"} color={"#6B7280"} />
  </div>
    // accesTable,
  ];

  const rowTable = formatedRow;

  //ordenar de + - último acceso de los usuarios
  const toggleSortDirection = (type) => {
    if (type === 'achievements') {
      if (achievementsSortDirection === 'asc') {
        setAchievementsSortDirection('desc');
        setSortDirection(null); 
      } else {
        setAchievementsSortDirection('asc');
        setSortDirection(null);
      }
    } else if (type === 'access') {
      if (sortDirection === 'asc') {
        setSortDirection('desc');
        setAchievementsSortDirection(null);
      } else {
        setSortDirection('asc');
        setAchievementsSortDirection(null);
      }
    }
  };

  if (sortDirection) {
    const sortOrder = sortDirection === 'asc' ? 1 : -1;
  
    rowTable.sort((a, b) => {
      const dateA = parseDate(a.last_sign_in_at);
      const dateB = parseDate(b.last_sign_in_at);
      return (dateA - dateB) * sortOrder;
    });
  
  } else if (achievementsSortDirection) {
    const achievementsSortOrder = achievementsSortDirection === 'asc' ? 1 : -1;
    rowTable.sort((a, b) => (a.achievements.props.achievement - b.achievements.props.achievement) * achievementsSortOrder);
  }

  let filteredOrganizations = searchItem(rowTable,["name", 'organization', 'status'],[searchByName, searchByOrganization, filterByStatus]);
 
  const copyTableAndModifyUiState = [...filteredOrganizations].map(el => {
    return {
      ...el,
      status: <Status status={el?.status}/>
    }
  });

  
  const sumPendingAndCompletedAxes = (items) => {
    let progressAxes = 0;
    let completedAxes = 0;
    
    items.forEach((obj) => {
     if (obj.axesInProgress) {
         progressAxes += obj.axesInProgress.length;
     } 
      
     if (obj.axesComplete) {
        completedAxes += obj.axesComplete.length;
     }
    });
    return {
      progressAxes: progressAxes,
      completedAxes: completedAxes
    };
  };

 const {progressAxes, completedAxes} = sumPendingAndCompletedAxes(rankedUsers);


  const rankingText = t("overview.ranking.export");

  // Esta traduccion la hago acà poruqe si lo hago en el mismo label me tira error,
  // si lo pueden modificar par que les ande poniendolo directamente en el label bienvenido sea.
  // Verifique poniendo withTranslation en el export del componente y tampoco me funciono.
  const playing = t("players.playing");
  const completedAllAxes = t("players.completedAllAxes");
  const pendingAxes = t("players.pendingAxes");
  const noElements_players = t("no_elements.players");


  const playersWithStatusPlaying = data?.usersRanking?.filter(user => user.playerStatus === "PLAYING").length;

  if(rowTable.length === 0) return ( <LoaderWrapper>
    <BigLoader />
  </LoaderWrapper>)

  return (
    <React.Fragment>
      {loading ? (
        <LoaderWrapper>
          <BigLoader />
        </LoaderWrapper>
      ) : (
        <>
          {/* <ButtonWrapper>
            <div>
              <Button
                loading={exportLoadinsg}
                onClick={() => {
                  exportUsers(handleExportLoading);
                }}
              >
                {rankingText}
              </Button>
            </div>
          </ButtonWrapper> */}
          <CardsContainer>
            <Card
              title={ <CountUp end={playersWithStatusPlaying} duration={1} />}
              icon={<Controller />}
              description={playing}
            />
                
            <Card
              title={ <CountUp end={completedAxes} duration={1} />}
              icon={<Building color="#1F2937" width="20px"/>}
              description={completedAllAxes}
            />

          <Card
              title={ <CountUp end={progressAxes} duration={1} />}
              icon={<ClockHistory />}
              description={pendingAxes}
            />
          </CardsContainer>

          <WrapperInputs>
            <Input type="text" placeholder={player_name} name='name' onChange={searchName}/>

            <Select placeholder={organizationSelect} onChange={searchOrganization}>
              <option value={""} selected>
              {organizationSelect}
              </option>
               {organizations?.map(org => {
               return <option value={org?.name}>{org?.name}</option>
              })} 
            </Select> 
            <Select placeholder={statusSelect} onChange={filterByStatuses}>
              <option value={""} selected>
                {statusSelect}
              </option>
              {['Completado', 'Jugando', 'Inactivo', 'Sin empezar'].map(status => {
               return <option value={status}>{status}</option>
              })} 
            </Select>
          </WrapperInputs>

          {filteredOrganizations.length === 0 ? (
            <NoElements text={noElements_players}/>
          ) : (
            <Table headerTable={headerTable} rowTable={copyTableAndModifyUiState} />
          )}
        </>
      )}
    </React.Fragment>
  );
};




const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 6rem;
`;

const WrapperInputs = styled.div`
  display: flex;
  padding: 5px;
  margin-bottom: 15px;
`;

const Insights = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 2rem;
  justify-content: space-between;
  margin: 2rem 0;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin: 5px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
`;
const Select = styled.select`
  width: 100%;
  padding: 10px;
  margin: 5px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
`;

export default Ranking;
