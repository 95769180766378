import React from 'react'
import AxesMetrics from '../../../components/AxesMetrics'



const AxesMetricsContainer = () => {
  return (
    <div>
      <AxesMetrics />
    </div>
  )
}

export default AxesMetricsContainer