import React from "react";
import styled from "styled-components";

function Icon({ color, width, height }) {
  return (
    <IconContent color={color || "#1F2937"} height={height} width={width}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7.5 1V8H8.5V1H7.5Z" fill={color || "#1F2937"} />
        <path
          d="M3 8.81199C2.99976 7.91896 3.23874 7.04216 3.69211 6.27277C4.14548 5.50338 4.79668 4.86948 5.578 4.43699L5.093 3.56299C3.93352 4.20506 3.01927 5.21296 2.49297 6.42937C1.96667 7.64577 1.85795 9.00221 2.18376 10.2869C2.50958 11.5716 3.25161 12.7123 4.294 13.5309C5.33639 14.3494 6.62048 14.7998 7.94581 14.8117C9.27113 14.8236 10.5631 14.3963 11.62 13.5966C12.6769 12.7969 13.4393 11.6697 13.7881 10.391C14.1369 9.11238 14.0526 7.75421 13.5482 6.52856C13.0438 5.30291 12.1478 4.27876 11 3.61599L10.499 4.48099C11.2592 4.91967 11.8906 5.55075 12.3296 6.31079C12.7686 7.07082 12.9998 7.93303 13 8.81075C13.0002 9.68847 12.7693 10.5508 12.3305 11.311C11.8918 12.0712 11.2607 12.7025 10.5006 13.1415C9.74056 13.5804 8.87834 13.8116 8.00062 13.8117C7.1229 13.8118 6.26061 13.5809 5.50044 13.1421C4.74027 12.7033 4.10899 12.0721 3.67007 11.312C3.23115 10.5519 3.00005 9.68971 3 8.81199Z"
          fill={color || "#1F2937"}
        />
      </svg>
    </IconContent>
  );
}

const IconContent = styled.div`
  fill: ${(props) => props.color || props.theme.primary || "#1F2937"};
  width: ${(props) => props.width || "20px"};
  height: ${(props) => props.height || "20px"};
`;

export default Icon;
