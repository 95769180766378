import React from 'react'
import { calcTotal } from '../components/AxesMetrics/components/ComparisonProgressBar';

export const calculateAchievement = (totalUsers) => {
    const result = totalUsers?.reduce((accumulator, user) => {
        const { achievementsByAxis } = user;

        achievementsByAxis.forEach((userAxis) => {
            const { axisName, count } = userAxis;

            if (!accumulator[axisName]) {
                accumulator[axisName] = { count: 0 };
            }

            accumulator[axisName].count += count;
        });

        return accumulator;
    }, {});

    let maxAxis = null;
    let maxCount = -1;

    // Encontrar el eje con la mayor cantidad de logros
    for (const axisName in result) {
        if (result[axisName].count > maxCount) {
            maxCount = result[axisName].count;
            maxAxis = axisName;
        }
    }
    return maxAxis;
};



export default calculateAchievement
