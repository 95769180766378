import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import t from '../../utils/i18n'
import themes from '../../utils/themes'
const InnerNavbar = ({match, paths}) => (
  <div>
    <StyledUl>
      {paths.map((path, key) => (
        <StyledItem key={key}>
          <StyledLink to={`${match.url}/${path.url}`}>{t(path.name)}</StyledLink>
        </StyledItem>
      ))}
    </StyledUl>
  </div>
)

const StyledUl = styled.ul`
  list-style: none;
  display: flex;
  font-size: 1.75rem;
  font-family: sans-serif;
  margin: 2.5rem 0;
  padding: 0;
`
const StyledItem = styled.li`
  padding-right: 2.5rem;
  font-size: ${props => props.theme.font_size.medium};
`
const StyledLink = styled(NavLink)`
  color: #1F2937;
  text-decoration: none;
  font-size: 0.875rem	;
  &:hover{
    color: black;
  }
  &.active{
    color: black;
    color: white;
    background: ${props => props.theme.primary};
    border: none;
    padding: 14px 18px;
    border-radius: ${(props) => props.theme.border_radius.rounded_md};
  }
`

export default InnerNavbar