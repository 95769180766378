import React, { useState, useEffect } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { put } from '../../../../../utils/api'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import OrgAvatar from '../../../../../components/OrgAvatar'
import Modal from '../../../../../components/Modals/index'
import LinkIcon from '../../../../../components/Icons/Link'
import LocationIcon from '../../../../../components/Icons/Location'
import EditIcon from '../../../../../components/Icons/Pencil'
import SaveIcon from '../../../../../components/Icons/Check'
import ClearIcon from '../../../../../components/Icons/Close'
import TextInput from '../../../../../components/TextInput'

import t from '../../../../../utils/i18n'

export const GET_ORGANIZATION_DATA = gql`
  query organizationMetrics($organization_id: String) {
    organization(organization_id: $organization_id) {
      users
      achievements
    }
  }
`

const getWidthByName = (name) => {
  if (name) {
    return (name.length * 13) + 70
  }
  return 0
}

const OrganizationProfile = ({ onCloseModal, organization, fetchOrganizations }) => {
  const { data } = useQuery(GET_ORGANIZATION_DATA, {
    variables: {
      organization_id: organization.id
    }
  })

  const [editing, toggleEditing] = useState(false)
  const [error, handleError] = useState("")
  const [originalName, handleOriginalName] = useState(organization ? organization.name : null)
  const [organizationName, handleOrganizationName] = useState(organization ? organization.name : null)


  const updateOrganizationName = async(id, name) => {
    const res = await put(`organizations/${id}`, {
      name: name
    })
    
    if (res.error && res.error.status === 422) {
      console.log('ERROR NAME TAKEN')
      handleError("errors.organizations.profile.name_taken")
    } else {
      handleOriginalName(name)
      toggleEditing(false)
      fetchOrganizations()
    }  
  }

  return (
    <Modal onCloseModal={onCloseModal}>
      <TitleWrapper>
        <OrgAvatar name={organization.name} size={80} />mo
        <TitleInputWrapper>

          { editing ? (
            <React.Fragment>

              <TitleInput
                customWidth={getWidthByName(organizationName)}                
                value={organizationName} 
                type="text" 
                onChange={e => {
                  handleOrganizationName(e.target.value) 
                  handleError("")
                }}
              />

              <SaveIconWrapper onClick={() => {                   
                  updateOrganizationName(organization.id, organizationName, fetchOrganizations, handleError)
                }}
              >
                <SaveIcon />
              </SaveIconWrapper>
              <ClearIconWrapper 
                onClick={() => { 
                  toggleEditing(false)
                  handleError("")
                  handleOrganizationName(originalName)
                }}
              >
                <ClearIcon />
              </ClearIconWrapper>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <TitleLabel
                customWidth={getWidthByName(organizationName)}
              > 
                {originalName} 
              </TitleLabel>
              <EditIconWrapper onClick={() => { 
                  toggleEditing(true)
                  handleOriginalName(organizationName)
                }}
              >
                <EditIcon />
              </EditIconWrapper>
            </React.Fragment>            
          )}
        </TitleInputWrapper>
      </TitleWrapper>

      <ErrorWrapper customVisibility={error && error.length > 0}>
        { t(error) }
      </ErrorWrapper>

      <InfoWrapper>
        <div>
          <LocationIcon/>
          { organization.location }
        </div>
        <Link href={organization.site} target="_blank" alt="organization-site">
          <LinkIcon/>
          { organization.site }
        </Link>
      </InfoWrapper>

      <Insights show={!!(data)}>
        <Row withSeparator>
          <FullRow >
            <InsightsTitle>{ data && data.organization.users} </InsightsTitle>
            <InsightsSpan>{t('organizations.modal.users')}</InsightsSpan>
          </FullRow>
        </Row>
        <Row>
          <FullRow>
            <InsightsTitle>{ data && data.organization.achievements }</InsightsTitle>
            <InsightsSpan>{t('organizations.modal.achievements')}</InsightsSpan>
          </FullRow>
        </Row>
      </Insights>
    
    </Modal>
  )
}

const TitleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 1rem;
`

const InfoWrapper = styled.div`
  padding: 1rem 1rem 2rem 1rem;
  > div, a {
    display: flex;
    align-items: center;
    font-size: 600;
    color: #8e8e93;
    padding: 0.5rem 0;

    > svg {
      margin-right: 1rem;
      fill: #8e8e93;
      width: 30px;
      height: 20px
    }
  }
`

const Link = styled.a`
  cursor: pointer;
  border: none;
  transition: all 0.2s;
`

const Title = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0.06px;
  margin-left: 1rem;
`


const Insights = styled.div`
  background-color: #efeff4;
  padding: 1rem;
  opacity: ${props => props.show ? '1' : '0'};
  transition: opacity 0.2s;
`

const InsightsTitle = styled.span`
  color: #8e8e93;
  display: block;
  font-size: 2rem;
  font-weight: bold;
`

const InsightsSpan = styled.span`
  color: #8e8e93;
  display: block;
  font-size: 0.8rem;
  margin-top: 1rem;
  text-transform: uppercase;
`

const Row = styled.div`
  display: flex;
  border-bottom: ${props => props.withSeparator ? '1px solid #D8D8D8' : ''};
`

const FullRow = styled.div`
  grid-column-start: 1;
  grid-column-end: end;
  padding: 1rem 1.5rem;
`

const TitleInputWrapper = styled.div`
  display: flex;
  align-items: center;  
  margin-left: 1rem;
`

const TitleInput = styled(TextInput)`
  font-size: 26px;
  font-weight: 900;  
  width: ${props => props.customWidth}px;
`

const TitleLabel = styled.div`
  font-size: 26px;
  padding: 2.25rem 0rem 2rem
  font-weight: 700;
  width: ${props => props.customWidth}px;
`

const IconWrapper = styled.div`
  display: inline;
  border-radius: 50%;
  background: #ddd;
  transition: all 0.2s;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
  cursor: pointer;

  > svg {
    width: 16px;
    height: 16px;
  }
`

const EditIconWrapper = styled(IconWrapper)`

  &:hover {
    background: ${props => props.theme.primary};

    > svg {
      fill: white;
    }
  }
`

const SaveIconWrapper = styled(IconWrapper)`
  > svg {
    fill: ${props => props.theme.success};
  }

  &:hover {
    background: ${props => props.theme.success};

    > svg {
      fill: white;
    }
  }
`

const ClearIconWrapper = styled(IconWrapper)`
  > svg {
    fill: ${props => props.theme.error};
  }

  &:hover {
    background: ${props => props.theme.error};

    > svg {
      fill: white;
    }
  }
`

const ErrorWrapper = styled.div`
  visibility: ${props => props.customVisibility ? 'visible' : 'hidden'};
  background: ${props => props.theme.error};
  padding: 1rem;
  border-radius: 4px;
  font-weight: 900;
  color: white;
  margin: 1rem 0;
`

OrganizationProfile.propTypes = {
  onCloseModal: PropTypes.func,
  organization: PropTypes.object
}

export default OrganizationProfile