import { call, put, takeEvery} from 'redux-saga/effects'

import { get, post, patch } from '../../utils/api'
import * as actions from './actions'
import { ORGANIZATION_LIST } from './constants'

export function * fetchAllWorker () {
  yield put(actions.fetchAll.start())

  const { res, error } = yield call(get, '/organizations')

  if(error) {
    return yield put(actions.fetchAll.failure({error: error.data.error}))
  }
  
  return yield put(actions.fetchAll.success({list: res.organizations}))
}

export function * fetchRequestsWorker(){
  yield put(actions.fetchRequests.start())

  const {res, error} = yield call(get, '/organizations/requests')

  if(error){
    return yield put(actions.fetchRequests.failure({error: error.data.error}))
  }

  return yield put(actions.fetchRequests.success({requests: res.requests}))
}

export function * fetchInvitesWorker(){
  yield put(actions.fetchInvites.start())

  const { res, error } = yield call(get, '/invites')
  
  if(error){
    return yield put(actions.fetchInvites.failure({error: error.data.error}))
  }

  return yield put(actions.fetchInvites.success({invites: res.invites}))
}

export function * sendInviteWorker(action){
  yield put(actions.sendInvite.start())
  const { email, subject } = action.payload

  const { res, error } = yield call(post, 'users', {email, subject})

  if(error){
    return yield put(actions.sendInvite.failure({error: error}))
  }
  
  return yield put(actions.sendInvite.success())
}

export function * editOrganizationWorker(action){
  yield put(actions.editOrganization.start())

  const { data } = action.payload

  const {res, error} = yield call(patch, `/organizations/${data.id}`, {organization: data})
  
  if(error){
    yield put(actions.editOrganization.failure({error}))
  }

  yield put(actions.editOrganization.success({ organization_id: res.organization.id}))
}

export function * fetchAllWatcher () {
  yield takeEvery(actions.fetchAll.type, fetchAllWorker)
}

export function * fetchRequestWatcher(){
  yield takeEvery(actions.fetchRequests.type, fetchRequestsWorker)
}

export function * fetchInvitesWatcher(){
  yield takeEvery(actions.fetchInvites.type, fetchInvitesWorker)
}

export function * sendInviteWatcher(){
  yield takeEvery(actions.sendInvite.type, sendInviteWorker)
}

export function * editOrganizationWatcher(){
  yield takeEvery(actions.editOrganization.type, editOrganizationWorker)
}

export default [
  fetchAllWatcher,
  fetchRequestWatcher,
  fetchInvitesWatcher,
  sendInviteWatcher,
  editOrganizationWatcher
]