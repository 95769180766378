import React from 'react'

export const calculateAxesInMetricsCards = (usersAxes, axes) => {

const completedAndIncompleteAxes = axes?.reduce((accumulator, obj) => {
  accumulator[obj.name] = { completed: 0, incompleted: 0};
  return accumulator;
}, {});


for (let i = 0; i < usersAxes?.length; i++) {
  const { axesComplete } = usersAxes[i] ;

  for (const ax of axesComplete || []) {

    if (completedAndIncompleteAxes[ax]) {
      completedAndIncompleteAxes[ax].completed += 1;
    } else {
      console.log('No existe');
    }
  };

  const missingValues = Object.keys(completedAndIncompleteAxes).filter(value => !axesComplete.includes(value));

  missingValues.forEach(value => {
    completedAndIncompleteAxes[value].incompleted += 1;
  });
}


return {
  completedAndIncompleteAxes: completedAndIncompleteAxes
}

}


export default calculateAxesInMetricsCards