import React from 'react'
import styled from 'styled-components'

const TextArea = ({label, name, value, onChange}) => {
  return(
    <Wrapper>
      <StyledLabel htmlFor={name}>{label}</StyledLabel>
      <StyledTextArea 
        id={name}
        name={name} 
        value={value}
        onChange={onChange}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-bottom: 0.8rem;
`
const StyledLabel = styled.label`
  display: block;
  font-size: 0.8rem;
  font-weight: ${props => props.isBold ? 'bold' : '400'};
  margin-bottom: 0.5rem;
  font-family: sans-serif;
  color: #5B5E62;
`
const StyledTextArea = styled.textarea`
  outline: none;
  padding: 0.5rem;
  border-width: 2px;
  border-radius: .25rem;
  width: 100%;
  border-color: #d2d7dc;
  height: 14rem;
  font-size: 1rem;
  color: #5B5E62;
  &:focus{
    border-color: ${props => props.theme.primary};
  }
`
export default TextArea