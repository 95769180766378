import React from 'react'
import styled, { keyframes} from 'styled-components'

const MagMove = keyframes`
  0%  {transform: rotate(0deg)}
  50% {transform: rotate(-3deg)}
  100% {transform: rotate(0deg)}
`

const Group = styled.g`
  animation: ${MagMove} 2s ease-in-out infinite;
`

const Glass = (props) => {
  return (
    <svg width={360} height={200} viewBox="0 0 512 500" {...props}>
      <title>{"graph"}</title>
      <g fill="rgb(70, 70, 72)" fillRule="nonzero">
        <path d="M326.6 127.1c13.8 0 25-11.2 25-25s-11.2-25-25-25-25 11.2-25 25c0 5.6 1.9 10.8 5 15l-41.1 57.4c-2.5-.8-5.1-1.2-7.8-1.2-6.6 0-12.6 2.6-17 6.7l-27.4-15.5c.4-1.7.6-3.5.6-5.3 0-13.8-11.2-25-25-25s-25 11.2-25 25c0 1.8.2 3.6.6 5.3L137.1 180c-4.5-4.2-10.4-6.7-17-6.7-13.8 0-25 11.2-25 25s11.2 25 25 25 25-11.2 25-25c0-1.8-.2-3.6-.6-5.3l27.4-15.5c4.5 4.2 10.4 6.7 17 6.7s12.6-2.6 17-6.7l27.4 15.5c-.4 1.7-.6 3.5-.6 5.3 0 13.8 11.2 25 25 25s25-11.2 25-25c0-5.6-1.9-10.8-5-15l41.1-57.4c2.5.7 5.1 1.2 7.8 1.2zm0-35c5.5 0 10 4.5 10 10s-4.5 10-10 10-10-4.5-10-10c0-5.6 4.5-10 10-10zM120.1 208.2c-5.5 0-10-4.5-10-10s4.5-10 10-10 10 4.5 10 10-4.5 10-10 10zm68.9-39.1c-5.5 0-10-4.5-10-10s4.5-10 10-10 10 4.5 10 10c0 5.6-4.5 10-10 10zm68.8 39.1c-5.5 0-10-4.5-10-10s4.5-10 10-10 10 4.5 10 10-4.5 10-10 10z" />
        <path d="M418.8 371H21.2c-3.4 0-6.2-2.8-6.2-6.2V55.3c0-3.4 2.8-6.2 6.2-6.2h35.4c4.1 0 7.5-3.4 7.5-7.5s-3.4-7.5-7.5-7.5H21.2C9.5 34.1 0 43.6 0 55.3v309.4C0 376.4 9.5 386 21.2 386h51.7v16.5c0 9.4 7.7 17.1 17.1 17.1h75.1l-35.3 69c-1.9 3.7-.4 8.2 3.3 10.1 1.1.6 2.3.8 3.4.8 2.7 0 5.4-1.5 6.7-4.1l38.8-75.8h82.9l38.8 75.8c1.3 2.6 4 4.1 6.7 4.1 1.2 0 2.3-.3 3.4-.8 3.7-1.9 5.1-6.4 3.3-10.1l-35.3-69h75.1c9.4 0 17.1-7.6 17.1-17.1V386h45c4.1 0 7.5-3.4 7.5-7.5s-3.6-7.5-7.7-7.5zm-60 31.5c0 1.1-.9 2.1-2.1 2.1H90c-1.1 0-2.1-.9-2.1-2.1V386h270.9v16.5zM86.6 49.1h339c3.4 0 6.2 2.8 6.2 6.2v71.3c0 4.1 3.4 7.5 7.5 7.5s7.5-3.4 7.5-7.5V55.3c0-11.7-9.5-21.2-21.2-21.2H265V19.4C265 9 256.6.6 246.2.6h-45.6c-10.4 0-18.8 8.4-18.8 18.8v14.7H86.6c-4.1 0-7.5 3.4-7.5 7.5s3.3 7.5 7.5 7.5zm110.1-29.7c0-2.1 1.7-3.8 3.8-3.8h45.6c2.1 0 3.8 1.7 3.8 3.8v14.7h-53.3l.1-14.7z" />
        <path d="M104.9 261.6c-4.1 0-7.5 3.4-7.5 7.5v66.3c0 4.1 3.4 7.5 7.5 7.5h30.4c4.1 0 7.5-3.4 7.5-7.5v-66.3c0-4.1-3.4-7.5-7.5-7.5h-30.4zm22.9 66.2h-15.4v-51.3h15.4v51.3zM173.7 223.2c-4.1 0-7.5 3.4-7.5 7.5v104.6c0 4.1 3.4 7.5 7.5 7.5h30.4c4.1 0 7.5-3.4 7.5-7.5V230.7c0-4.1-3.4-7.5-7.5-7.5h-30.4zm23 104.6h-15.4v-89.6h15.4v89.6zM242.6 261.6c-4.1 0-7.5 3.4-7.5 7.5v66.3c0 4.1 3.4 7.5 7.5 7.5H273c4.1 0 7.5-3.4 7.5-7.5v-66.3c0-4.1-3.4-7.5-7.5-7.5h-30.4zm22.9 66.2h-15.4v-51.3h15.4v51.3zM311.4 305.8c-4.1 0-7.5 3.4-7.5 7.5v22c0 4.1 3.4 7.5 7.5 7.5h30.4c4.1 0 7.5-3.4 7.5-7.5s-3.4-7.5-7.5-7.5h-22.9v-14.5c0-4.1-3.3-7.5-7.5-7.5z" />
        <Group>
          <path d="M508.2 378l-43-74.5c10.9-11.6 18.8-25.8 23.1-41.6 6.8-25.3 3.3-51.7-9.8-74.4-27-46.8-87.1-62.9-133.9-35.9-46.8 27-62.9 87.1-35.9 133.9 13.1 22.7 34.2 38.9 59.5 45.7 8.4 2.3 17 3.4 25.5 3.4 7.4 0 14.8-.9 22.1-2.5l43 74.5c5.3 9.1 14.9 14.2 24.7 14.2 4.8 0 9.7-1.2 14.2-3.8 8.8-5.1 14.3-14.5 14.3-24.7 0-5-1.3-9.9-3.8-14.3zm-136.1-61.3c-21.4-5.7-39.3-19.5-50.4-38.7-22.9-39.6-9.3-90.5 30.4-113.4 13-7.5 27.3-11.1 41.4-11.1 28.7 0 56.7 14.9 72 41.5 11.1 19.2 14 41.6 8.3 63-5.7 21.4-19.5 39.3-38.7 50.4-19.3 11.1-41.6 14-63 8.3zM490.2 404c-6.5 3.7-14.7 1.5-18.5-4.9l-41.4-71.7c4.1-1.7 8.2-3.7 12.2-6 3.9-2.3 7.7-4.8 11.2-7.6l41.4 71.7c1.2 2.1 1.8 4.4 1.8 6.7.1 4.9-2.5 9.4-6.7 11.8z" />
          <path d="M461.5 257.6c5.3-17.1 4-35.3-3.6-51.4-1.8-3.7-6.2-5.4-10-3.6-3.7 1.8-5.4 6.2-3.6 10 6 12.7 7 27.1 2.8 40.6s-13.3 24.9-25.6 32c-13 7.5-28.2 9.5-42.7 5.6-14.5-3.9-26.6-13.2-34.1-26.2-15.5-26.8-6.3-61.3 20.6-76.8 19.6-11.3 44-9.8 62.1 3.9 3.3 2.5 8 1.8 10.5-1.5s1.8-8-1.5-10.5c-22.9-17.3-53.7-19.2-78.6-4.9-16.5 9.5-28.3 24.9-33.2 43.2-4.9 18.3-2.4 37.6 7.1 54 9.5 16.5 24.9 28.3 43.2 33.2 6.1 1.6 12.4 2.5 18.5 2.5 12.3 0 24.5-3.2 35.5-9.6 15.8-8.9 27.3-23.3 32.6-40.5z" />
        </Group>
      </g>
    </svg>
  )
}

export default Glass