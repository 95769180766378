import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import detector from "i18next-browser-languagedetector"
import en from './messages/en.json'
import es from './messages/es.json'
import fr from './messages/fr.json'

//translations (move them in a json file and import them)
const resources = {
  en: {
    translation: en
  },
  es: {
    translation: es
  },
  fr: {
    translation: fr
  }
}

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    order: ['localStorage'],
    lookupLocalStorage: 'lang',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      wait: true
    },
  })

export default i18n