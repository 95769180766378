import React from 'react'
import styled from 'styled-components'
import { Route, Redirect } from 'react-router-dom'
import InnerNavbar from '../../../components/InnerNavbar'
import Main from '../scenes/Main'
import { INNER_PATHS } from '../constants'
import t from '../../../utils/i18n'
import Title from '../../../components/Title'

const Profile = ({match}) => (
  <div>
    <HeaderWrapper>
      <Title fontSize={'large'} color={'black'} padding={'2rem 0 0 0'}>{ t('profile.title') }</Title>
    </HeaderWrapper>
    <InnerNavbar match={match} paths={INNER_PATHS} />
    <Route exact path={`${match.url}/`} render={() => (<Redirect to={`${match.url}/main`} />)} />
    <Route exact path={`${match.url}/main`}  component={Main}/>
  </div>
)

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export default Profile