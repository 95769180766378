import React from "react";
import { withTheme } from 'styled-components'

const getColor = (props) => {
  if (props.color === 'primary' || props.color === 'secondary') {
    return props.theme[props.color]
  }
  //Custom colors
  return props.color
}

const Icon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={getColor(props)}
    >
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z"></path>
    </svg>
  );
}

Icon.defaultProps = {
  color: "primary"
}

export default withTheme(Icon);
