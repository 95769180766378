import React from 'react'
import styled from 'styled-components'
import t from '../../utils/i18n'


const NoElements = ({text}) => {
  const theresNo = t("no_elements.no")
const elements = t("no_elements.elements")
const filter = t("no_elements.filter")
  return (
    <Wrapper>
       {theresNo} {text || `${elements}`} {filter}
    </Wrapper>
  )
}

const Wrapper = styled.section`
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: #f7f7f7;
  color: black;
`

export default NoElements