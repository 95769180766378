import React, { Component } from 'react'
import styled, { withTheme } from 'styled-components'
import * as d3 from 'd3'
import QuestionLabel from './components/QuestionLabel'

class Tree extends Component {
  
  constructor(props){
    super(props)
    this.state = {
      selectedItem: null
    }
  }

  componentDidMount(){
    if (this.props.tree) {
      this.initTree()
    }
  }

  componentDidUpdate(prevProps) {
    if(!prevProps.tree && this.props.tree) {
      this.initTree()
      return
    }

    if (prevProps.tree !== this.props.tree) {
      this.initTree()
    }
  }

  initTree = () => {
    if (!this.props.tree) {
      return 
    }
    
    const container = window.document.getElementById('treeWrapper')
    const width = container.offsetWidth
    const height = 600
    const nodeWidth = 60;
    const nodeHeight = 20;
    const horizontalSeparationBetweenNodes = 4;
    const verticalSeparationBetweenNodes = 40;
  
    d3.select("#treeWrapper").selectAll("svg").remove();
    let svg = d3.select('#treeWrapper')
      .append('svg')
        .attr('viewBox', [0, 0, width, height])

    let g = svg.append('g')    

    let cluster = d3.cluster()
    .nodeSize([nodeWidth + horizontalSeparationBetweenNodes, nodeHeight + verticalSeparationBetweenNodes])
    .separation(function(a, b) {
        return a.parent == b.parent ? 1 : 1.25;
    });

    let root = d3.hierarchy(this.props.tree, function(d) {
      return d.children
    })
    cluster(root)

    // add the links between the nodes
    g.selectAll('path')
      .data(root.descendants().slice(1))
      .enter()
      .append('path')    
      .attr('d', function(d, i) {
        return "M" + d.x + "," + d.y
                + "C" + (d.parent.x) + "," + d.y
                + " " + (d.parent.x) + "," + d.parent.y // 50 and 150 are coordinates of inflexion, play with it to change links shape
                + " " + (d.parent.x) + "," + d.parent.y;
      })
      .style('fill', 'none')
      .style('stroke-width', 3)
      .attr('stroke', this.props.theme.secondary)    

    // add a circle for each node
    g.selectAll('g')
      .data(root.descendants())
      .enter()
      .append('g')
      .attr('transform', function(d) {
        return "translate(" + d.x + "," + d.y + ")"
      })
      .append('circle')
        .attr('r', 7)
        .attr('data-question', (d, i) => {
          return d.data.name
        })
        .style('fill', this.props.theme.primary)
        .attr('stroke', this.props.theme.primary)
        .style('stroke-width', 2)
        .style('cursor', 'pointer')      
      .on('mouseover', (d, i) => {      
        const el = d3.selectAll(`[data-question="${i.data.name}"]`)
        el.style('fill', this.props.theme.secondary)
        this.setState({
          selectedItem: i.data
        })
      })
      .on('mouseout', (d, i) => {
        const el = d3.selectAll(`[data-question="${i.data.name}"]`)
        el.style('fill', this.props.theme.primary) 
        this.setState({
          selectedItem: null
        })
      })

    // add zoom capabilities and center on beginning of tree
    svg.call(this.zoom)
    svg.transition().call(
      this.zoom.transform,
      d3.zoomIdentity.translate(width / 2, height / 2)
    )

    this.g = g
    this.svg = svg
  }

  zoom = d3.zoom().on('zoom', (ev) => {
    this.g.attr("transform", ev.transform)
  })  

  render(){
    return (
      <TreeWrapper id="treeWrapper">      
        <QuestionLabelWrapper>
          <QuestionLabel question={this.state.selectedItem} />
        </QuestionLabelWrapper>
        <ZoomButtons>
          <ZoomButton onClick={() => { this.svg.transition().call(this.zoom.scaleBy, 2) }}>
            +
          </ZoomButton>
          <ZoomButton onClick={() => { this.svg.transition().call(this.zoom.scaleBy, 0.5) }}>
            -
          </ZoomButton>
        </ZoomButtons>
      </TreeWrapper>
    )
  }
}

const TreeWrapper = styled.div`
  width: 100%;
  height: 600px;
  position: relative;
`

const QuestionLabelWrapper = styled.div`
  position: absolute;
  left: 10px;
  top: 10px;
`

const ZoomButtons = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`

const ZoomButton = styled.div`
  height: 40px;
  width: 40px;
  font-weight: 900;
  color: white;
  border-radius: 50%;
  background: ${props => props.theme.secondary};
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 2px solid ${props => props.theme.secondary};
  transition: 0.5s all;

  &:hover {
    border: 2px solid ${props => props.theme.primary} 
  }

  &:active {
    background: ${props => props.theme.primary};
  }
`
export default withTheme(Tree)