import React from 'react'

const globalTheme = {
  pink: '#ef5777',
  white: '#ffffff',
  gray: "#4A5568",
  slate_gray: "#E5E7EB",
  dark_gray: '#1F2937',
  background: "#fafafa",
  error: '#ff6961',
  success: '#4BB543',
  spacing: {
    xs: '0.5rem',
    small: '1rem',
    medium: '1.75rem',
    large: '2.5rem'
  },
  font_size: {
    heading: '3.5rem',
    xxl: '2.5rem',
    xl: '2rem',
    large: '1.5rem',
    medium: '1rem',
    small: '0.75rem',
  },
  status: {
    warning: '#ffc107'
  },
  font_size: {
    heading: '3.5rem',
    xxl: '2.5rem',
    xl: '2rem',
    large: '1.5rem',
    medium: '1rem',
    small: '0.85rem',
    xs: '0.6rem'
  },
  border_radius: {
    rounded_sm: '4px',
    rounded: '8px',
    rounded_md: '12px',
    rounded_lg: '16px'
  }
}


const themes = {
  default: {
    primary: '#2266E9',
    ...globalTheme
  },
  yellow: {
    primary: '#EFA51B',
    ...globalTheme
  },
  red: {
    primary: '#E42C1E', 
    ...globalTheme
  },
  purple: {
    primary: '#794BC4',
    ...globalTheme
  },
  orange: {
    primary: '#EE6A0B',
    ...globalTheme
  },
  green: {
    primary: '#3A9E56',
    ...globalTheme
  },
  blue: {
    primary: '#2266E9',
    ...globalTheme
  },
  violet: {
    primary: '#8C41BA',
    ...globalTheme
  },
  fuchsia: {
    primary: '#F20091',
    ...globalTheme
  },
  light_blue: {
    primary: '#498EAB',
    ...globalTheme
  },
}

export const ThemeContext = React.createContext({
})

export default themes