import React from 'react'
import styled from 'styled-components'
import TrophyIcon from '../../../Icons/Trophy'
import LightbulbIcon from '../../../Icons/Lightbulb'
import t from '../../../../utils/i18n'

// Suma del total de wins o suggestions para luego hacer el calculo del width.
const achievementsSum = (achs) => {
  let ach = achs;
  let sum = 0;

  ach.forEach(achievement => sum += achievement.wins)
  
  return sum;
}

export const calcTotal = (achievements, suggestions) => {
  const wins = achievementsSum(achievements);
  // const suggs = suggestionsSum(suggestions);

  return {
    achievementsSum: wins,
    // suggestionsSum: suggs
  };
}

const ComparisonProgressBar = ({axes, achievements, selectedAxis}) => {
    
  if (!achievements) { 
    return 
  }

  const filteredAchievements = achievements.filter(achievement => achievement.question && achievement.question.axis._id === selectedAxis)
  
  const { achievementsSum, suggestionsSum } = calcTotal(filteredAchievements);

  return (
    <div>

      <div>
        <div>

          <div customWidth={achievementsSum}>{achievementsSum}</div>

        </div>
      </div>
    </div>
  )
}




export default ComparisonProgressBar