import React from 'react'
import styled from 'styled-components'
import LightbulbIcon from '../../../../../Icons/Lightbulb'
import TrophyIcon from '../../../../../Icons/Trophy'

const QuestionLabel = ({ question }) => {
  return question &&
    <Wrapper>
      <Content>
        <QuestionName>        
          <span>
            { question.name }
          </span>  
        </QuestionName>      
        { question.answers.map((ans) => (
          <AnswerWrapper>            
            <Answer key={ans._id}>
              { ans.content }
            </Answer>
            <Icon show={ans.achievement || ans.suggestion}>
              { ans.achievement && (
                <TrophyIcon />
              )}
              { ans.suggestion && (
                <LightbulbIcon />
              )}
            </Icon>
          </AnswerWrapper>
        ))}
      </Content>
    </Wrapper>  
}


const Wrapper = styled.div`
  display: flex;  
  min-width: 300px;
  color: #424242;
  transition: all 0.2s;  
  border: 2px solid ${props => props.theme.primary};
  border-radius: 4px;
  background: white;
  padding: 0.5rem;
`

const Content = styled.div`
  width: 100%;
`

const QuestionName = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 4px;
  background: ${props => props.theme.primary};  
  font-size: 16px;
  color: white;
  font-weight: 800;
  margin-bottom: 0.2rem;
`

const Answer = styled.div`
  display: flex;
  align-items: center; 
  font-size: 14px;
  padding: 0 0.5rem; 
`

const AnswerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.3rem 0;
`

const Icon = styled.div`
  width: 30px;
  height: 30px;  
  margin-right: 1rem;
  background: ${props => props.show ? props.theme.secondary : '#FFF'};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    width: 15px;
    height: 15px;
  }
`

export default QuestionLabel