import React from 'react'
import styled from 'styled-components'

const TooltipCard = ({type = 'warning', margin = '0', children}) => {

  return (
    <Wrapper type={type} margin={margin}>
      { children }
    </Wrapper>
  )
}


const Wrapper = styled.div`  
  padding: 1rem;
  color: white;
  font-weight: 900;  
  background: ${props => props.theme.status[props.type]};
  margin: ${props => props.margin};
  border-radius: 4px;  
`

export default TooltipCard