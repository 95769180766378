import React from 'react';
import styled from 'styled-components';
import Title from '../Title';
import BroadCard from '../BroadCard';

const Card = ({ title, icon, description }) => {
  return (
      <BroadCard>
        <Title fontSize={'large'} padding={"0.8rem 0 0 0"}>{title}</Title>
        <Item>
          {icon}
          <Description>{description}</Description>
        </Item>
      </BroadCard>
  );
}

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #1f2937;
  font-size: ${(props) => props.theme.font_size.small};
  font-weight: 600;
`;

const Description = styled.p`
  text-wrap: nowrap;
`;

export default Card;
