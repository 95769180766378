import React, { Fragment } from "react";
import styled, { withTheme } from "styled-components";
import { lighten } from "polished";
import CountUp from "react-countup";
import { withTranslation } from "react-i18next";
import { useQuery } from "@apollo/react-hooks";
import { isEmpty } from "ramda";
import { GET_DASHBOARD_METRICS } from "../../../queries/Metrics/getDashboardMetrics";
import EmptyMetrics from "../../../components/EmptyMetrics";
import BigLoader from "../../../components/BigLoader";
import Map from "./_commons/Map";
import Insight from "./_commons/Insight";
import PieChart from "./_commons/PieChart";
import BarChart from "./_commons/BarChart";
import Building from "../../../components/Icons/UserScene";
import Controller from "../../../components/Icons/Controller";
import Heart from "../../../components/Icons/Heart";
import CardsContainer from "../../../components/CardsContainer";
import Card from "../../../components/Card";

const Metrics = ({ metrics, members, fetching, t, theme }) => {

  const { loading, error, data } = useQuery(GET_DASHBOARD_METRICS, {
    variables: {
      game: process.env.REACT_APP_ORGANIZATION_ID,
    },
  });

  const filteredUsers = data
    ? data.users.filter(
        (user) => !isEmpty(user.progress) && user.role !== "admin"
      ).length
    : 0;
  if (!loading && fetching !== "pending" && (!metrics || !data)) {
    return <EmptyMetrics />;
  }



  return (
    <Wrapper>
      {loading || fetching == "pending" ? (
        <LoaderWrapper>
          <BigLoader />
        </LoaderWrapper>
      ) : (
        <Fragment>
          <CardsContainer>
            <Card
              title={
                metrics.map ? (
                  <CountUp end={metrics.map.length} duration={1} />
                ) : (
                  <CountUp end={0} />
                )
              }
              icon={<Building color="#1F2937" width="20px" />}
              description={t("overview.metrics.institutions")}
            />

            <Card
              title={
                data ? (
                  <CountUp end={filteredUsers} duration={1} />
                ) : (
                  <CountUp end={0} />
                )
              }
              icon={<Controller />}
              description={t("overview.metrics.users")}
            />

            <Card
              title={data && data.axisPopular ? data.axisPopular.name : ""}
              icon={<Heart />}
              description={t("overview.metrics.axis_popular")}
            />
          </CardsContainer>
          {/* <Insights>
            <Insight label={ t('overview.metrics.institutions') } img={<Building/>}>
              <Value>
                { metrics.map
                  ? <CountUp end={metrics.map.length} duration={1} />
                  : <CountUp end={0} />
                }
              </Value>
            </Insight>
            <Insight label={ t('overview.metrics.users') }>
              <Value>
                { data 
                  ? <CountUp end={filteredUsers} duration={1} />
                  : <CountUp end={0} />
                }
              </Value>
            </Insight> 

          <Insight label={ t('overview.metrics.axis_popular') } img={<Heart/>}>
              <Value>
                { (data && data.axisPopular)
                  ? data.axisPopular.name
                  : ''
                }
              </Value>
            </Insight>
          </Insights>  */}

          <Graphics>
            {metrics.members && <BarChart bars={metrics.members} />}
            {data && data.axisMetrics && (
              <PieChart
                label={t("overview.metrics.pie_chart.type_1")}
                data={{
                  labels: data.axisMetrics.map((axis) => axis.name),
                  datasets: [
                    {
                      data: data.axisMetrics.map((axis) => axis.count),
                      backgroundColor: data.axisMetrics.map((value, i) =>
                        lighten(`0.${i}`, theme.primary)
                      ),
                    },
                  ],
                }}
              />
            )}
          </Graphics>

          <Map
            translations={t("overview.metrics.map", { returnObjects: true })}
            data={metrics.map}
          />
        </Fragment>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-right: 100px;
  margin-bottom: 30px;
`;

const PieWrapper = styled.div`
  display: flex;
`

const Insights = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 2rem;
  justify-content: space-between;
  margin: 2rem 0;
`;

const Value = styled.span`
  color: #e42c1e;
  display: block;
  font-weight: 700;
  font-size: 1.5rem;
`;

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 6rem;
`;

const Graphics = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default withTheme(withTranslation()(Metrics));

{
  /* <PieChart 
  data={data.axisMetrics} 
  label={t('overview.metrics.pie_chart.type_1')}
  data={{
    labels: data.axisMetrics.map((axis => axis.name )),
    datasets: [{
      data: data.axisMetrics.map((axis => axis.count)),
      backgroundColor: data.axisMetrics.map((value, i) =>  lighten(`0.${i}`, theme.primary))
    }]
  }}
/>  */
}
