import React from 'react'
import styled, { css } from 'styled-components'

const FullCircle = styled.div`
  background: ${props => props.background};
  border-radius: 50%;
  margin: 0 1rem;
  position: relative;
  width: 3rem;
  height: 3rem;
  display: inline-block;
  transition: transform 0.2s;
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
  }

  ${props => props.active && css`
    transform: scale(1.2);
    box-shadow: rgba(0,0,0,0.16) 1px 1px 21px 6px;
  `}
`

const ColorsContainer = styled.div`
  width: max-content;
`

const Palettes = (props) => {
  
  const colors = [
    {
      primary: '#2266E9',
      name: 'blue'
    },
    {
      primary: '#EFA51B',
      name: 'yellow'
    },
    {
      primary: '#E42C1E',
      name: 'red'
    },
    {
      primary: '#794BC4',
      name: 'purple'
    },
    {
      primary: '#EE6A0B',
      name: 'orange'
    },
    {
      primary: '#3A9E56',
      name: 'green'
    },
    {
      primary: '#F20091',
      name: 'fuchsia'
    },
    {
      primary: '#498EAB',
      name: 'light_blue'
    },
    {
      primary: '#8C41BA',
      name: 'violet'
    },
  ]

  return (
    <ColorsContainer>
      {colors.map((obj, index) => (
        <FullCircle key={index} onClick={() => { props.onChange(obj.name) }} active={obj.name === props.value} background={obj.primary} />
      ))} 
    </ColorsContainer>
  )
}

export default Palettes