import React from 'react'
import styled from 'styled-components'
import { useMutation } from '@apollo/react-hooks'
import Icon from './assets/addIcon'
import { CREATE_QUESTION } from '../../queries/questions'
import t from '../../utils/i18n'
import Button from '../../components/Button'


const NewQuestion = ({axisId, onSuccess}) => { 
  const [createQuestion, { loading }] = useMutation(CREATE_QUESTION, {
    refetchQueries: ['game'],
    onCompleted: (data) => onSuccess(data.createQuestion)
  })

  const placeholder = t('game.questions.placeholder')

  return (
    <Wrapper onClick={(e) => {
      e.preventDefault()
      createQuestion({ variables: {
        content: placeholder,
        axis: axisId + ''
      }})
    }}
    loading={loading}>
        <IconContainer>
          <Icon />
        </IconContainer>
        <Content>{t('game.questions.new')}</Content>
    </Wrapper>
  )
}

const Wrapper = styled(Button)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  bottom: 10px;
  right: 15px;
  width: 270px;
  margin-bottom: 1rem;
`

const Content = styled.span`
  color: white;
  text-transform: uppercase;
  font-weight: bold;
`
const IconContainer = styled.span`
`
export default NewQuestion