import React from "react";

function Heart() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_445_3937)">
        <path
          d="M8.66664 2.74799L7.94964 2.01099C6.26664 0.280988 3.18064 0.877988 2.06664 3.05299C1.54364 4.07599 1.42564 5.55299 2.38064 7.43799C3.30064 9.25299 5.21464 11.427 8.66664 13.795C12.1186 11.427 14.0316 9.25299 14.9526 7.43799C15.9076 5.55199 15.7906 4.07599 15.2666 3.05299C14.1526 0.877988 11.0666 0.279988 9.38364 2.00999L8.66664 2.74799ZM8.66664 15C-6.66636 4.86799 3.94564 -3.04001 8.49064 1.14299C8.55064 1.19799 8.60964 1.25499 8.66664 1.31399C8.72306 1.25504 8.78178 1.19833 8.84264 1.14399C13.3866 -3.04201 23.9996 4.86699 8.66664 15Z"
          fill="#1F2937"
        />
      </g>
      <defs>
        <clipPath id="clip0_445_3937">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.666626)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Heart;
