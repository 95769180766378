import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import t from '../../../utils/i18n'
import InnerNavbar from '../../../components/InnerNavbar'
import OrganizationsRanking from '../scenes/OrganizationsRanking'
import RankingContainer from '../components/RankingContainer'
import Metrics from '../scenes/Metrics'
// import Impact from '../scenes/Impact'
import AxesMetricsContainer from '../scenes/AxesMetricsContainer'
import { INNER_PATHS } from '../constants'
import Title from '../../../components/Title'
import Dashboard from '../../../components/Icons/Dashboard'
import themes from '../../../utils/themes'

const Overview = ({ match }) => {
  return (
    <div>
      <HeaderWrapper>
        <Dashboard width={'20px'} padding={'14px 0 0 0'} color={themes.default}/>
        <Title color={'#1F2937'} fontSize={'large'} padding={'30px 0 0 0'}>{t('overview.title')}</Title>
      </HeaderWrapper>
      <InnerNavbar match={match} paths={INNER_PATHS} />
      <Route exact path={`${match.url}/`} render={() => (<Redirect to={`${match.url}/metrics`} />)} />
      <Route exact path={`${match.url}/metrics`}  component={Metrics}/>
      <Route exact path={`${match.url}/axes-metrics`}  component={AxesMetricsContainer}/>
      <Route exact path={`${match.url}/organizations-leaderboard`}  component={OrganizationsRanking}/>
      <Route exact path={`${match.url}/leaderboard`}  component={RankingContainer}/>
      {/* <Route exact path={`${match.url}/impact`} component={Impact} /> */}
    </div>
  )
}

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`


export default Overview