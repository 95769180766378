import { connect} from 'react-redux' 

import * as actions from '../actions'
import InviteContainer from '../components/InviteContainer'

const mapStateToProps = state => ({
  invites: state.organizations.invites,
  status: state.organizations.status
})

const mapDispatchToProps = dispatch => ({
  onMount: () => dispatch(actions.fetchInvites())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InviteContainer)