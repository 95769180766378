
import reducer from './reducers'
import login from './scenes/Auth'
import sagas from './sagas'
import * as constants from './constants'
import * as selectors from './selectors'

export default {
  selectors,
  constants,
  reducer,
  scenes: {
    login
  },
  sagas: [
    ...sagas
  ]
}