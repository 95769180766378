import { connect} from 'react-redux' 

import * as actions from '../actions'
import ListContainer from '../components/ListContainer'

import * as modalActions from '../../modals/actions'

const mapStateToProps = state => ({
  list: state.organizations.list,
  status: state.organizations.status
})

const mapDispatchToProps = dispatch => ({
  onMount: () => dispatch(actions.fetchAll()),
  onSelectOrganization: (organization) => dispatch({
    type: modalActions.openModal,
    payload: {
      type: 'organizationProfile',
      selectedOrganization: organization,
      fetchOrganizations: () => dispatch(actions.fetchAll())
    }
  })
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListContainer)