import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="#FFF"
    >
      <path        
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        d="M13.59 1.01h-1.797c.007-.199.01-.399.01-.6a.41.41 0 00-.41-.41H2.607a.41.41 0 00-.41.41c0 .201.003.401.01.6H.41a.41.41 0 00-.41.41c0 1.838.48 3.572 1.352 4.883.863 1.296 2.01 2.039 3.251 2.111.281.306.579.556.889.746v1.823h-.689a1.51 1.51 0 00-1.508 1.509v.688h-.03a.41.41 0 000 .82h7.47a.41.41 0 100-.82h-.03v-.688a1.51 1.51 0 00-1.508-1.509h-.689V9.16c.31-.19.607-.44.889-.746 1.24-.072 2.388-.815 3.25-2.111C13.52 4.993 14 3.258 14 1.421a.41.41 0 00-.41-.41zM2.035 5.85C1.316 4.767.893 3.354.83 1.83h1.424c.147 1.872.587 3.602 1.274 4.976.11.218.223.425.342.62-.682-.258-1.313-.797-1.834-1.578zm9.93 0c-.52.781-1.152 1.32-1.834 1.578.119-.195.233-.402.342-.62.687-1.374 1.126-3.104 1.274-4.976h1.424c-.064 1.523-.487 2.936-1.206 4.018z"
        transform="translate(-584 -817) translate(576 809) translate(8 8)"
      ></path>
    </svg>
  );
}

export default Icon;
