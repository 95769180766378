import { gql } from 'apollo-boost'

export const CREATE_SUGGESTION = gql`
  mutation CreateSuggestion($title: String, $description: String, $axis: String!, $materials: [MaterialInput]){
    createSuggestion(title: $title, description: $description, axis: $axis, materials: $materials) {
      _id
    }
  }
`

export const DELETE_SUGGESTION = gql`
  mutation DeleteSuggestion($_id: String!){
    deleteSuggestion(_id: $_id)
  }
`

export const UPDATE_SUGGESTION = gql`
  mutation UpdateSuggestion($_id: String! $title: String, $description: String, $materials: [MaterialInput]){
    updateSuggestion(_id: $_id, title: $title, description: $description, materials: $materials)
  }
`