import { gql } from 'apollo-boost'

export const GET_DASHBOARD_METRICS = gql`
  query users($game: String) {
    users(game: $game) {
      role
      progress{
        axis{
          _id
        }
      }
    }
    axisPopular {
      name
    }
    axisMetrics {
      name
      count
    }
  }
`