import React from 'react'
import styled from 'styled-components'

const OrganizationTable = ({children, type}) => (
  <Wrapper>
    <Header>
      {/* <div width="7rem">
        <Button color="#5856D6" outline>{ t('organizations.list.filter') }</Button>
      </div>
      {type === 'list' && (
        <div width="12rem">
          <Button color="#5856D6" outline>{ t('organizations.list.message_all') }</Button>
        </div>
      )} */}
    </Header>
    <Content>
      {children}
    </Content>
  </Wrapper>
)

const Wrapper = styled.div`
  background-color: #fff;
`
const Header = styled.div`
  display: none;
  justify-content: space-between;
  padding: 1.5rem;
  border-bottom: 1px solid #D8D8D8;
`
const Content = styled.div`
  padding: 0 1rem;
`



export default OrganizationTable