import { connect } from 'react-redux'

import * as actions from '../actions'
import MainContainer from '../components/MainContainer'

const mapStateToProps = state => ({
  profile: state.profile.profile
})

const mapDispatchToProps = dispatch => ({
  onMount: (id) => {
    return dispatch(actions.fetchProfile({ id }))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainContainer)