import React from 'react'

const LogoRupu = (props) => (
  <svg viewBox="0 0 486.01 201.38" width={props.width || "100px"} color={props.color || '#4559f5' } {...props}>
      <title>{"Logo rupu"}</title>
      <g id="prefix__Capa_2" data-name="Capa 2">
        <g id="prefix__SECTION_1" data-name="SECTION 1">
          <g id="prefix__Capa_16_copia" data-name="Capa 16 copia">
            <path
              fill="currentColor"
              d="M70.76 37.24a32.38 32.38 0 00-13 2.66 34.13 34.13 0 00-10.64 7.18 32.3 32.3 0 00-7 10.64 30.59 30.59 0 00-2.26 13v70.49H0V70.76a70.42 70.42 0 0120.48-50A71.75 71.75 0 0143 5.59 68.54 68.54 0 0170.49 0H79v37.24z"
            />
            <path
              fill="currentColor"
              d="M114.12 70.76a32.3 32.3 0 002.66 13A34 34 0 00124 94.44a31.91 31.91 0 0010.77 7A34.74 34.74 0 00147.9 104a32.67 32.67 0 0023.41-9.58 34.13 34.13 0 007.18-10.64 32.37 32.37 0 002.66-13V0h37v70.76a70 70 0 01-5.45 27.53 71.11 71.11 0 01-14.9 22.48 69.17 69.17 0 01-49.35 20.48 70.62 70.62 0 01-27.8-5.45 71.07 71.07 0 01-37.92-37.37 68.24 68.24 0 01-5.59-27.67V0h37z"
            />
            <path
              fill="currentColor"
              d="M280.38 0A71 71 0 01308 5.45a69.64 69.64 0 0122.61 15 72.2 72.2 0 0115.16 22.35 67.62 67.62 0 015.59 27.4A70 70 0 01346 97.76a71.08 71.08 0 01-37.38 37.91 67.6 67.6 0 01-27.4 5.59 74.14 74.14 0 01-20.75-2.93V97.1a33.38 33.38 0 0020.75 6.65 31.39 31.39 0 0012.9-2.66 34.49 34.49 0 0010.51-7.18 32.34 32.34 0 007-10.64 33.81 33.81 0 002.53-13 31.46 31.46 0 00-2.66-12.9 33.54 33.54 0 00-7.32-10.51 33.93 33.93 0 00-23.81-9.58 31.44 31.44 0 00-12.9 2.66A34.61 34.61 0 00257 47.09a32.3 32.3 0 00-7 10.64 33.77 33.77 0 00-2.53 13v130.65h-37.32V70.76a70.42 70.42 0 0120.48-50A72.21 72.21 0 01253 5.59 67.6 67.6 0 01280.38 0z"
            />
            <path
              fill="currentColor"
              d="M382 70.76a32.3 32.3 0 002.66 13 34 34 0 007.18 10.64 31.9 31.9 0 0010.77 7 34.73 34.73 0 0013.17 2.6 32.67 32.67 0 0023.41-9.58 34.14 34.14 0 007.18-10.64 32.37 32.37 0 002.66-13V0h37v70.76a70 70 0 01-5.45 27.53 71.1 71.1 0 01-14.9 22.48 69.17 69.17 0 01-49.35 20.48 70.61 70.61 0 01-27.8-5.45 71.07 71.07 0 01-37.93-37.37 68.23 68.23 0 01-5.6-27.67V0h37z"
            />
          </g>
        </g>
      </g>
    </svg>
)

export default LogoRupu