import { gql } from 'apollo-boost'

export const GET_GAME = gql`
  query game($_id: String) {
    game(_id: $_id) {
      _id
      name
      lang
      palette
      logo
      isShow
      description
      title
    }
  }
`

export const GET_GAME_AXES = gql`
  query game($_id: String){
    game(_id: $_id) {
      _id
      name
      axes {
        _id
        name
        questions {
          _id
          content
          answers {
            _id
            content
            next {
              _id
              content
            }
            achievement {
              _id
              title
              description
              
            }
            suggestion {
              _id
            }
          }
        }
        suggestions {
          _id
          title
          description
          materials{
            _id
            type
            name
            link
          }
        }
      }
    }
  }
`