import React from 'react'
import Organization from './Organization'
class OrganizationContainer extends React.Component{
  
  render(){
    const { onInviteOrganization } = this.props
    return (
      <Organization {...this.props}
        handleButtonClick={this.handleButtonClick}
        onInviteOrganization={onInviteOrganization} />
    )
  }
}

export default OrganizationContainer