import React, { useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { useMutation } from '@apollo/react-hooks'
import { UPDATE_GAME } from '../../../queries/Game/updateGame'
import i18n from '../../../lang/i18n'
import WithThemeContext from '../../../components/WithThemeContext'
import Button from '../../../components/Button'
import TextInput from '../../../components/TextInput'
import Select from '../../../components/Select'
import Palettes from '../../../components/Palettes'
import Thumbnail from '../../../components/Thumbnail'
import t from '../../../utils/i18n'
import LogoUploader from './LogoUploader';


const MainForm = ({ profile, ...props }) => {
  const options = [
    { name: t('profile.general.languages.es'), value: 'es' },
    { name: t('profile.general.languages.fr'), value: 'fr' },
    { name: t('profile.general.languages.en'), value: 'en' },
  ]

  const [name, setName] = useState(props.data.game.name || t('profile.general.name'))
  
  const [lang, setLang] = useState(props.data.game.lang)

  const [description, setDescription] = useState(props.data.game.description)

  const [title, setTitle] = useState(props.data.game.title)

  const [isShow, toggleIsShow] = useState(props.data.game.isShow)

  const [palette, setPalette] = useState(props.data.game.palette)
  
  const [updateGame, { data, loading }] = useMutation(UPDATE_GAME);

  const changePalette = (palette) => {
    setPalette(palette)
  }


  const changeLang = (newLang) => {
    localStorage.setItem('lang', newLang)
    setLang(newLang)
  }

  const submitForm = (e) => {
    e.preventDefault()
    i18n.changeLanguage(lang)
    props.toggleGameParams({
      name: name,
      lang: lang,
      palette: palette,
      description: description,
      title: title,
      isShow: isShow
    })
    
    updateGame({
      variables: {
        _id: props.data.game._id,
        name: name,
        lang: lang,
        palette: palette,
        description: description,
        title: title,
        isShow: isShow
      },
      refetchQueries: ['game']
    })
  }

  const resetPalette = () => {
    setPalette('default')
    updateGame({
      variables: {
        _id: props.data.game._id,
        palette: palette,
        logo: ''
      },
      refetchQueries: ['game']
    })
  }

  // const [showPlatform, setShowPlatform] = useState(false);

  // const handleShowPlatform = () => {
  //   setShowPlatform(!showPlatform);
  // }


  return (
    <Wrapper>
      <ProfileForm onSubmit={submitForm}>
        <ProfileTitle>{ t('profile.general.title') }</ProfileTitle>
        <TextInput label={ t('profile.general.name') + ': ' } value={name} type="text" onChange={e => setName(e.target.value)} />
        <TextInput label={ t('profile.general.organization') + ': ' } value={profile.organization_name} type="text" disabled />
        <TextInput label={ t('profile.general.email') + ': ' } value={profile.email}  disabled type="text" />
        <TextInput label={ t('profile.general.date') + ': ' } value={moment(profile.organization_created_at).format('l')} disabled type="text" />
        <Select label={ t('profile.general.language') + ': ' } values={options} value={lang} onChange={e => changeLang(e.target.value)} />
        
        <TitleWrapper>
          <PlatformTitle>{ t('profile.about_platform.about')}</PlatformTitle>
          <Optional>{ t('profile.about_platform.optional') }</Optional>
        </TitleWrapper>
        <TextInput label={ t('profile.about_platform.title') + ': ' } value={title} onChange={e => setTitle(e.target.value)} type="text" />
        <PlatformInputWrapper>
          <PlatformLabel>{ t('profile.about_platform.description') + ': ' }</PlatformLabel>
          <PlatformInput onChange={e => setDescription(e.target.value)}>{description}</PlatformInput>
        </PlatformInputWrapper>
       
        <PlatformLabel>{ t('profile.about_platform.show_title_and_description')}</PlatformLabel>
        <input type='checkbox' defaultChecked={isShow} onChange={() => toggleIsShow(!isShow)} />

        <CustomizeTitle>{ t('profile.branding.title') }</CustomizeTitle>
        <ThumbnailWrapper>
          <Thumbnail large />
          <ThumbnailDiv>
            <span>/Wingulogo.svg</span>
            <Button outline>{ t('profile.branding.logo_button') }</Button>
          </ThumbnailDiv>
        </ThumbnailWrapper>
        <ProfileTitle>{ t('profile.palette.title') }</ProfileTitle>
        <Palettes 
          value={palette}
          onChange={(palette) => changePalette(palette)} />
        <BtnContainer>
          <ButtonWrapper>
          <Button onClick={resetPalette} outline loading={loading}>{ t('profile.default') }</Button>
        </ButtonWrapper> 
        <ButtonWrapper>
          <Button type='submit' loading={loading}>{ t('profile.submit') }</Button>
        </ButtonWrapper>
        </BtnContainer>
      </ProfileForm>
      <LogoUploader imgUrl={props.data.game.logo} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 4rem;
  display: flex;
`

const ProfileForm = styled.form`
  width: 600px;
  margin-right: 2rem;
`
const ProfileTitle = styled.h3`
  font-size: 1rem;
  font-weight: 600;
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
`

const ButtonWrapper = styled.div`
  margin: 4rem 0;
  display: flex;
  justify-content: space-between;
`

const CustomizeTitle = styled.h3`
  font-size: 1rem;
  font-weight: 600;
  margin-top: 2rem;
  text-transform: uppercase;
`
const PlatformTitle = styled.h3`
  font-size: 1rem;
  font-weight: 600;
  margin-top: 2rem;
  text-transform: uppercase;
  cursor: pointer;
`

const PlatformLabel = styled.label`
  font-size: 12px;
  color: #464648;
  font-weight: 500;
  font-size: 0.75rem;
  margin: 2.5rem 0;
`

const PlatformInput = styled.textarea`
  border: 2px solid #D8D8D8;
  color: ${props => props.status === 'failure' ? '#d43328':'#000000'};
  font-size: 1rem;
  outline: none;
  transition: border-color 0.2s;
  width: 100%;

  &:focus{
    border: 2px solid ${props => props.theme.primary};
  }

  display: block;
  resize: both;
  user-modify: read-write;
  font-family: inherit;
  margin: 1rem 0;

  display: block;
  overflow-x: hidden;
  overflow-y: scroll;
  resize: both;
  min-height: 12rem;
  line-height: 20px;
`

const PlatformInputWrapper = styled.div`
  margin-bottom: 1rem;
  max-width: 100%;
`

const TitleWrapper = styled.div`
  display: flex;
`

const Optional = styled.p`
  padding: 1rem 0 0 0.5rem;
  font-size: 15px;

`

const ThumbnailWrapper = styled.div`
  display: none;
  margin: 3rem 0;
`

const ThumbnailDiv = styled.div`
  align-content: space-around;
  display: flex;
  flex-wrap: wrap;
  margin-left: 3rem;
  width: 180px;
`
const BtnContainer = styled.div`
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-around;
`

export default WithThemeContext(MainForm)