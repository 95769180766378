import React from 'react'
import DocIcon from './icons/docs'
import PdfIcon from './icons/pdf'
import VideoIcon from './icons/video'
import PptIcon from './icons/ppt'

const IconSelector = ({type}) => {
  switch(type){
    case 'PDF': {
      return <PdfIcon />
    }
    case 'VIDEO': {
      return <VideoIcon />
    }
    case 'PPT': {
      return <PptIcon />
    }

    default: {
      return <DocIcon />
    }
  }
}

export default IconSelector