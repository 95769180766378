import React from 'react'

import propTypes from 'prop-types'
import { connect } from 'react-redux'

import modals from './types'
import * as actions from '../actions'

import InviteOrganization from './InviteOrganization/index'
import OrganizationProfile from './OrganizationProfile/index'
import * as selectors from '../selectors'

const Modal = props => {
  const { type } = props
  switch(type){
    case modals.inviteOrganization:
      return <InviteOrganization {...props} />
    case modals.organizationProfile:
      return <OrganizationProfile {...props} />
    default:
      return <span />
  }
}

Modal.propTypes = {
  type: propTypes.string,
  onConfirm: propTypes.oneOfType([
    propTypes.string,
    propTypes.func
  ]),
  onCancel: propTypes.oneOfType([
    propTypes.string,
    propTypes.func
  ])
}

const mapStateToProps = state => ({
  type: selectors.type(state),
  onConfirm: selectors.onConfirm(state),
  onCancel: selectors.onCancel(state)
})

const mapDispatchToProps = dispatch => ({
  onCloseModal: () => dispatch({ type: actions.closeModal})
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Modal)