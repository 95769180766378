import React from 'react'
import AxisCard from '../../../components/AxisCard'
import styled from 'styled-components'
import AxisInput from '../../../components/AxisInput'

const Axes = ({ data, changeSelectedAxis, selectedAxis }) =>  {
  return(
    <AxesWrapper>
      {selectedAxis && (
        <>
          {data.map((axis) => (
            <AxisCard 
              list={data}
              axis={axis}
              handleClick={() => changeSelectedAxis(axis)}
              key={axis._id}
              active={axis._id === selectedAxis._id}        
            />
          ))}
        </>
      )}
      <AxisInput/>
    </AxesWrapper>
  )
}

const AxesWrapper = styled.div`
  background-color: white;
  display: flex;
  flex-wrap: wrap;
`
export default Axes