import React from 'react'

const DownArrow = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
    <title>Path</title>
    <desc>Created with Sketch.</desc>
    <g id="UX-1c" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Profile/Pagos" transform="translate(-819.000000, -678.000000)" fill="#000000" fillRule="nonzero">
            <g id="Contenido" transform="translate(310.000000, 383.000000)">
                <g id="Enviar-mensaje-a-todas">
                    <g id="Metodo-de-Pago" transform="translate(0.000000, 237.000000)">
                        <g id="Formulario" transform="translate(0.000000, 56.000000)">
                            <g id="General-Information">
                                <g id="Group-7">
                                    <g id="Input-Copy">
                                        <g id="Forma-de-pago" transform="translate(111.000000, 0.000000)">
                                            <g id="Stacked-Group">
                                                <g id="_ionicons_svg_md-arrow-dropdown-circle" transform="translate(398.000000, 2.000000)">
                                                    <path d="M12,0 C5.37259615,0 0,5.37259615 0,12 C0,18.6274038 5.37259615,24 12,24 C18.6274038,24 24,18.6274038 24,12 C24,5.37259615 18.6274038,0 12,0 Z M12,15.6923077 L6.46153846,10.1538462 L17.5384615,10.1538462 L12,15.6923077 Z" id="Shape"></path>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
  </svg>
)

export default DownArrow