import React from 'react'
import styled from 'styled-components'
import WithThemeContext from '../WithThemeContext'
import RupuLogo from '../RupuLogo'

const Wrapper = styled.div`
  align-items: center;
  bottom: 1.5rem;
  position: absolute;
  display: flex;
  
`

const StyledSpan = styled.span`
  color: #616161;
  font-size: 0.9rem;
  margin-right: 0.5rem;
  padding-bottom: 0.4rem;
  opacity: 0.7;
`

const LoginFooter = ({ gameParams: { logo } }) => {
  if (!logo) return null
  return (
    <Wrapper>
      <StyledSpan>Powered by</StyledSpan>
      <RupuLogo width="40px" />
    </Wrapper>
  )
}

export default WithThemeContext(LoginFooter)