import React from "react";
import { withTheme } from 'styled-components'

const Icon = (props) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' fill='rgb(253,71,113)' viewBox='0 0 42.88 61.5' width='20px' height='20px'>
      <g data-name='Capa 2'>
        <path
          d='M21.44 31.78a4.15 4.15 0 00-4.14 4.14v6.21a4.14 4.14 0 108.28 0v-6.21a4.15 4.15 0 00-4.14-4.14zm2.07 10.35a2.07 2.07 0 11-4.14 0v-6.21a2.07 2.07 0 014.14 0z'
          className='cls-1'
          fill={props.theme.primary}
        ></path>
        <path
          d='M32.82 22.8V11.32C32.82 5.49 27.71.75 21.44.75S10.06 5.49 10.06 11.32V22.8a20.69 20.69 0 1022.76 0zm-20.69-1.2V11.32c0-4.69 4.17-8.5 9.31-8.5s9.31 3.81 9.31 8.5V21.6c-.19-.1-.39-.18-.59-.27-.41-.19-.82-.37-1.24-.54l-.82-.29c-.26-.1-.53-.19-.8-.27-.46-.14-.91-.25-1.37-.35l-.61-.13a21.3 21.3 0 00-1.63-.25h-.37c-.63-.06-1.25-.1-1.88-.1s-1.26 0-1.88.1h-.37c-.55.06-1.1.15-1.63.25l-.61.13c-.46.1-.91.21-1.37.35l-.81.26-.81.3c-.42.16-.83.34-1.24.54-.2.09-.4.17-.59.27zm9.31 37.08a18.6 18.6 0 01-9.57-34.56 19.31 19.31 0 013-1.45l.55-.2.86-.28c.35-.1.71-.19 1.07-.27l.79-.16c.38-.07.76-.14 1.15-.18h.54c.54-.05 1.09-.09 1.65-.09s1.11 0 1.65.09h.54c.38 0 .77.11 1.15.18l.79.16c.36.08.72.17 1.07.27s.57.18.86.28l.55.2a19.31 19.31 0 013 1.45 18.6 18.6 0 01-9.57 34.56z'
          className='cls-1'
          fill={props.theme.primary}
        ></path>
      </g>
    </svg>
  );
}

Icon.defaultProps = {
  color: "primary"
}

export default withTheme(Icon);