import React, { Component } from "react";
import Ranking from "./OrganizationsRanking";
import { get } from '../../../utils/api'


class RankingContainer extends Component {
  componentDidMount() {
    this.props.onMount();
    this.fetchUsers()
  }
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      data: null,
    }

  }

  fetchUsers = async() => {
    try {
      const res = await get('users')
      this.setState({
        data: res.res.users
      })
    } catch (err) {
      this.setState({
        error: err
      })
    }
  }

  

  render() {

    return (
      <>
        {" "}

        <Ranking
          data={this.props.ranking}
          onSelectOrganization={this.props.onSelectOrganization}
          users={this.state.data}
          organizationName={this.state.organizationName}
        />

      </>
    );
  }
}



export default RankingContainer;
