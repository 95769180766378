import React from 'react'
import styled from 'styled-components'
import RupuLogo from '../RupuLogo'
const Logo = (props) => {
  return (
    <LogoWrapper>
      {props.imgUrl
      ? <Image src={props.imgUrl} alt="logo" width="200px" />
      : <RupuLogo />
      }
    </LogoWrapper>
  )
}

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
`
const Image = styled.img`
  height: auto;
`
export default Logo