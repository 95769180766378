import React from 'react'
import styled, {keyframes} from 'styled-components'
const SkeletonTable = () => (
  <Wrapper>
    <Header>
      <HeaderBoxes></HeaderBoxes>
      <HeaderBoxes></HeaderBoxes>
    </Header>
    <Content>
      <Row>
        <Circle></Circle>
        <ContentBox></ContentBox>
        <ContentBox></ContentBox>
        <ContentBox></ContentBox>
        <ContentBox></ContentBox>
      </Row>
      <Row>
        <Circle></Circle>
        <ContentBox></ContentBox>
        <ContentBox></ContentBox>
        <ContentBox></ContentBox>
        <ContentBox></ContentBox>
      </Row>
      <Row>
        <Circle></Circle>
        <ContentBox></ContentBox>
        <ContentBox></ContentBox>
        <ContentBox></ContentBox>
        <ContentBox></ContentBox>
      </Row>
      <Row>
        <Circle></Circle>
        <ContentBox></ContentBox>
        <ContentBox></ContentBox>
        <ContentBox></ContentBox>
        <ContentBox></ContentBox>
      </Row>
      <Row>
        <Circle></Circle>
        <ContentBox></ContentBox>
        <ContentBox></ContentBox>
        <ContentBox></ContentBox>
        <ContentBox></ContentBox>
      </Row>
      <Row>
        <Circle></Circle>
        <ContentBox></ContentBox>
        <ContentBox></ContentBox>
        <ContentBox></ContentBox>
        <ContentBox></ContentBox>
      </Row>
    </Content>
  </Wrapper>
)

const gradientBG = keyframes`
  0%{
    background-position: 0% 50%;
  }
  50%{
    background-position: 100% 50%;
  }
  100%{
    background-position: 0% 50%;
  }
`

const Wrapper = styled.div`
  background-color: #fafafb;
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #D8D8D8;
`
const HeaderBoxes = styled.div`
  height: 2rem;
  width: 9rem;
  background: linear-gradient(-45deg, #fcfcfc, #cccccc, #fcfcfc );
  background-size: 400% 400%;
  animation: ${gradientBG} 5s ease infinite;
`
const Content = styled.div`
  padding: 0 1rem;
`
const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  border-bottom:1px solid #D8D8D8;
`
const Circle = styled.div`
  width: 3rem;
  height: 3rem;
  margin-right: 4rem;
  border-radius: 100%;
  background: linear-gradient(-45deg, #fbfbfb, #cccccc, #fbfbfb );
  background-size: 400% 400%;
  animation: ${gradientBG} 2s ease infinite;

`
const ContentBox = styled.div`
  width: 7rem;
  height: 1rem;
  margin-right: 4rem;
  background: linear-gradient(-45deg, #fbfbfb, #cccccc, #fbfbfb );
  background-size: 400% 400%;
  animation: ${gradientBG} 2s ease infinite;
  
`

export default SkeletonTable