import React from "react";
import styled from "styled-components";

const BoardCard = ({ color, icon, children, margin }) => {
  return (
    <Wrapper>
      <StyledBoardCard color={color} margin={margin}>
      {children}
      </StyledBoardCard>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 0rem;
  width: 100%;
  padding: 5px;
`;

const IconWrapper = styled.div`
font-size: 2.8rem;
padding: 1rem 1.5rem;
width: 3rem;
`;

const StyledBoardCard = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: start;
min-height: 115px;
  padding: 0.5rem 1rem;
  width: 100%;
  height: auto;
  background: white;
  border-radius: ${(props) => props.theme.border_radius.rounded_lg};
  border: 1px solid ${(props) => props.theme.slate_gray };
  margin: ${props => props.margin || '1rem 0'};
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default BoardCard;
