import React from 'react'
import styled from 'styled-components'
import { useQuery } from '@apollo/react-hooks'
import { GET_GAME } from '../../../queries/Game/getGame'
import QuestionsContainer from '../scenes/Questions'

 
const Game = ({match}) => {
  const { loading, error, data } = useQuery(GET_GAME, {
    variables: {
      _id: process.env.REACT_APP_ORGANIZATION_ID
    }
  })

  return (
    <div>
        <HeaderWrapper>
          { data &&
            <Title>{ data.game.name }</Title>
          }
        </HeaderWrapper>
        <QuestionsContainer />
    </div>
  )
  
}
const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.2rem 0 2rem 0;
`
const Title = styled.h1`
  font-size: 1.2rem;
`

export default Game