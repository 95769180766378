import React from 'react'
import styled from 'styled-components'
import Modal from '../../../../../components/Modals/index'
import ModalForm from './ModalForms'
import LoaderButton from '../../../../../components/LoaderButton'
import t from '../../../../../utils/i18n'

const InviteOrganization = ({sendInvite, onChange, email, subject, onCloseModal}) => (
  <Modal onCloseModal={onCloseModal}>
    <ModalTitle>{ t('organizations.modal.title') }</ModalTitle>
    <FormWrapper>
      <ModalForm onChange={onChange}/>
    </FormWrapper>
    <LoaderButton handleClick={()=>sendInvite(email, subject)} text={ t('organizations.modal.button') } />
  </Modal>
)

const ModalTitle = styled.h2`
  text-align: center;
  font-size: 2rem;
`
const FormWrapper = styled.div`
  padding-bottom: 1rem;
`
export default InviteOrganization