export const NAMESPACE = 'overview'

export const INNER_PATHS = [
  { url: 'metrics', name: 'constants.generals' },
  { url: 'organizations-leaderboard', name: 'constants.organizations' }, 
  { url: 'leaderboard', name: 'constants.players'},
  { url: 'axes-metrics', name: 'constants.axis'}
]

export const METRICS = {
  total: 2365,
  playing_orgs: 2009,
  inactive_orgs: 45,
  average_level: 2,
  average_axis: 'Comunicación' 
} 

export const ORGANIZATION_LIST = [
  { 
    name: 'Wingu', 
    type: 'Non profit',
    level: '1',
    img: 'https://store.playstation.com/store/api/chihiro/00_09_000/container/AR/es/99/UP2538-CUSA05620_00-AV00000000000101//image?_version=00_09_000&platform=chihiro&w=720&h=720&bg_color=000000&opacity=100',
    country: 'argentina',
    email: 'email@nadanada.com',
    progress: '70',
    axis: 'Comunicacion'
  },
  { 
    name: 'Wingu', 
    type: 'Non profit',
    level: '1',
    img: 'https://store.playstation.com/store/api/chihiro/00_09_000/container/AR/es/99/UP2538-CUSA05620_00-AV00000000000101//image?_version=00_09_000&platform=chihiro&w=720&h=720&bg_color=000000&opacity=100',
    country: 'argentina',
    email: 'email@nadanada.com',
    progress: '70',
    axis: 'Comunicacion'
  },
  { 
    name: 'Wingu', 
    type: 'Non profit',
    level: '1',
    img: 'https://store.playstation.com/store/api/chihiro/00_09_000/container/AR/es/99/UP2538-CUSA05620_00-AV00000000000101//image?_version=00_09_000&platform=chihiro&w=720&h=720&bg_color=000000&opacity=100',
    country: 'argentina',
    email: 'email@nadanada.com',
    progress: '70',
    axis: 'Comunicacion'
  },
  { 
    name: 'Wingu', 
    type: 'Non profit',
    level: '1',
    img: 'https://store.playstation.com/store/api/chihiro/00_09_000/container/AR/es/99/UP2538-CUSA05620_00-AV00000000000101//image?_version=00_09_000&platform=chihiro&w=720&h=720&bg_color=000000&opacity=100',
    country: 'argentina',
    email: 'email@nadanada.com',
    progress: '70',
    axis: 'Comunicacion'
  },
  { 
    name: 'Wingu', 
    type: 'Non profit',
    level: '1',
    img: 'https://store.playstation.com/store/api/chihiro/00_09_000/container/AR/es/99/UP2538-CUSA05620_00-AV00000000000101//image?_version=00_09_000&platform=chihiro&w=720&h=720&bg_color=000000&opacity=100',
    country: 'argentina',
    email: 'email@nadanada.com',
    progress: '70',
    axis: 'Comunicacion'
  },
  { 
    name: 'Wingu', 
    type: 'Non profit',
    level: '1',
    img: 'https://store.playstation.com/store/api/chihiro/00_09_000/container/AR/es/99/UP2538-CUSA05620_00-AV00000000000101//image?_version=00_09_000&platform=chihiro&w=720&h=720&bg_color=000000&opacity=100',
    country: 'argentina',
    email: 'email@nadanada.com',
    progress: '70',
    axis: 'Comunicacion'
  },
  { 
    name: 'Wingu', 
    type: 'Non profit',
    level: '1',
    img: 'https://store.playstation.com/store/api/chihiro/00_09_000/container/AR/es/99/UP2538-CUSA05620_00-AV00000000000101//image?_version=00_09_000&platform=chihiro&w=720&h=720&bg_color=000000&opacity=100',
    country: 'argentina',
    email: 'email@nadanada.com',
    progress: '70',
    axis: 'Comunicacion'
  },
  { 
    name: 'Wingu', 
    type: 'Non profit',
    level: '1',
    img: 'https://store.playstation.com/store/api/chihiro/00_09_000/container/AR/es/99/UP2538-CUSA05620_00-AV00000000000101//image?_version=00_09_000&platform=chihiro&w=720&h=720&bg_color=000000&opacity=100',
    country: 'argentina',
    email: 'email@nadanada.com',
    progress: '70',
    axis: 'Comunicacion'
  },
]