import React from 'react'

const ClockHistory = () => {
  return (
<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_445_3598)">
<path d="M9.1815 1.019C9.01013 1.00635 8.83835 1.00001 8.6665 1V0C8.86304 9.58276e-05 9.05951 0.00743414 9.2555 0.022L9.1815 1.019ZM11.1855 1.469C10.865 1.3453 10.5357 1.24537 10.2005 1.17L10.4195 0.194C10.8025 0.28 11.1795 0.394 11.5455 0.536L11.1855 1.469ZM12.5555 2.179C12.4125 2.08365 12.2661 1.99359 12.1165 1.909L12.6095 1.039C12.9514 1.23274 13.2786 1.45132 13.5885 1.693L12.9735 2.482C12.8379 2.37623 12.6985 2.2755 12.5555 2.18V2.179ZM14.3895 3.969C14.1917 3.68798 13.9734 3.42192 13.7365 3.173L14.4605 2.483C14.7305 2.768 14.9805 3.073 15.2075 3.393L14.3895 3.969ZM15.1335 5.321C15.0679 5.16246 14.9965 5.00636 14.9195 4.853L15.8125 4.403C15.9891 4.75409 16.1395 5.11774 16.2625 5.491L15.3125 5.804C15.2588 5.64087 15.1991 5.47976 15.1335 5.321ZM15.6635 7.828C15.6554 7.48434 15.622 7.14174 15.5635 6.803L16.5485 6.633C16.6155 7.019 16.6545 7.411 16.6645 7.803L15.6645 7.828H15.6635ZM15.5325 9.366C15.5655 9.196 15.5925 9.027 15.6135 8.856L16.6065 8.979C16.5585 9.36915 16.4816 9.75521 16.3765 10.134L15.4125 9.867C15.4585 9.702 15.4985 9.535 15.5325 9.366ZM14.5805 11.745C14.7645 11.455 14.9265 11.151 15.0665 10.837L15.9805 11.242C15.8205 11.602 15.6355 11.948 15.4255 12.28L14.5805 11.745ZM13.6165 12.95C13.7385 12.828 13.8555 12.702 13.9665 12.572L14.7245 13.225C14.5961 13.3738 14.4624 13.5179 14.3235 13.657L13.6165 12.95Z" fill="#1F2937"/>
<path d="M8.66645 1C7.51533 1.00008 6.382 1.28405 5.36683 1.82674C4.35167 2.36943 3.486 3.1541 2.84651 4.11125C2.20702 5.0684 1.81345 6.16848 1.70065 7.31406C1.58785 8.45964 1.7593 9.61536 2.19983 10.6789C2.64036 11.7423 3.33636 12.6808 4.22618 13.4111C5.116 14.1413 6.17219 14.6409 7.30119 14.8655C8.43019 15.0901 9.59715 15.0328 10.6987 14.6987C11.8003 14.3645 12.8024 13.7639 13.6165 12.95L14.3235 13.657C13.3932 14.5878 12.2477 15.2747 10.9885 15.657C9.72928 16.0393 8.39522 16.105 7.10452 15.8485C5.81381 15.5919 4.60631 15.021 3.589 14.1862C2.57169 13.3515 1.77599 12.2787 1.27239 11.0629C0.768787 9.84711 0.57284 8.52588 0.701908 7.21627C0.830976 5.90666 1.28107 4.6491 2.01232 3.55502C2.74357 2.46094 3.73339 1.56411 4.89408 0.944003C6.05477 0.323895 7.3505 -0.000348732 8.66645 2.81458e-07V1Z" fill="#1F2937"/>
<path d="M8.1665 3C8.29911 3 8.42629 3.05268 8.52006 3.14645C8.61383 3.24021 8.6665 3.36739 8.6665 3.5V8.71L11.9145 10.566C12.0263 10.6334 12.1073 10.7419 12.1401 10.8683C12.173 10.9946 12.1551 11.1288 12.0904 11.2422C12.0256 11.3556 11.919 11.4391 11.7935 11.4749C11.6679 11.5108 11.5334 11.4961 11.4185 11.434L7.9185 9.434C7.84198 9.39029 7.77836 9.32712 7.73411 9.25091C7.68986 9.17469 7.66653 9.08813 7.6665 9V3.5C7.6665 3.36739 7.71918 3.24021 7.81295 3.14645C7.90672 3.05268 8.0339 3 8.1665 3Z" fill="#1F2937"/>
</g>
<defs>
<clipPath id="clip0_445_3598">
<rect width="16" height="16" fill="white" transform="translate(0.666504)"/>
</clipPath>
</defs>
</svg>
  )
}

export default ClockHistory