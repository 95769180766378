import React from 'react'
import styled from 'styled-components'
import Button from '../../../components/Button'
import OrganizationTable from './_commons/OrganizationTable'
import SkeletonTable from '../../../components/SkeletonTable';
import t from '../../../utils/i18n'

const Invite = ({ data, status }) => {
  if(status === 'pending') {
    return <SkeletonTable />
  } else {
    return (
      <OrganizationTable type='invite'>
        {data.length > 0 ? (
          <>
            {data.map( (d, key) => (
              <Row key={key}>
                <Bullet></Bullet>
                <HighlighItem>wingu</HighlighItem>
                <Item minWidth>{ t('organizations.invites.sent') } el 23 de abril de 2019</Item>
                <Item minWidth>{ t('organizations.invites.status') }: Aún no ha entrado</Item>     
                <HoverItem>
                  <Button>{ t('organizations.invites.resent') }</Button>
                </HoverItem>
              </Row>
            ))}
          </>
        ) : <EmptyMessage>{t('organizations.invites.empty')}</EmptyMessage>}
      </OrganizationTable>
    )
  }

}

const Bullet = styled.div`
  background-color: #000000;
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid #000000;
  border-radius: 100%;
  margin-right: 1rem;
`

const Item = styled.div`
  padding-right: 1rem;
  width: ${props => props.minWidth ? '15rem': ''};
  color: #AEAEB2;
`
const HighlighItem = styled.div`
  color: #000000;
  width: 9rem;
`
const HoverItem = styled.div`
  display: none;
  margin-left: auto;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  height: 2.5rem;
  border-bottom:1px solid #D8D8D8;
  &:hover{
    ${HoverItem}{
      display: flex;
    }
    ${HighlighItem}{
      font-weight: bold;
    }
  } 
`

const EmptyMessage = styled.p`
  text-align: center;
  font-weight: bold;
  padding: 2rem;
`

export default Invite