import { applyMiddleware, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import monitorReducersEnhancer from './enhancers/monitorReducer'
import loggerMiddleware from './middleware/logger'

import rootReducer from './reducers'
import rootSaga from './sagas'

export default function configureStore(preloadedState) {
  const sagaMiddleware = createSagaMiddleware()
  const middlewares = [loggerMiddleware, thunkMiddleware, sagaMiddleware]
  const middlewareEnhancer = applyMiddleware(...middlewares)


  const enhancers = [middlewareEnhancer, monitorReducersEnhancer]
  const composeEnhancers = composeWithDevTools(...enhancers)

  const store = createStore(rootReducer, preloadedState, composeEnhancers)
  
  sagaMiddleware.run(rootSaga)
  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
  }

  return store
}