import React from 'react'
import styled from 'styled-components'


const STATUS_BADGE_BG = {
    Jugando : "#CCFBF1",
    Inactivo : "#FEE2E2",
    "Sin empezar": "#F5F5F5",
    Completado: '#14B8A6',
}
const COLOR_TEXT_BADGE = {
    Jugando: "#14B8A6",
    Inactivo : "#EF4444",
    "Sin empezar": "#9B9B9B",
    Completado: '#FFFFFF',
}

const Status = ({status}) => {
    const backgroundColor = STATUS_BADGE_BG[status];
    const textColor = COLOR_TEXT_BADGE[status];

  return (
    <WrapperBadge>
       <StatusText backgroundColor={backgroundColor} textColor={textColor}>{status}</StatusText>
    </WrapperBadge>
  )
}

const WrapperBadge = styled.section`
  display: flex;
  justify-content: "center";
  align-items: center;
`
const StatusText = styled.p`
 border-radius: 20px;
 padding: 8px;
 background-color: ${(props) => props.backgroundColor || "gray"};
 color: ${(props) => props.textColor || "black"};
 font-size: 13px;
 width: 100px;
 display:flex;
 justify-content: center;
 align-items: center;
`

export default Status