import React, { Component } from 'react'
import Metrics from './Metrics'

class MetricsContainer extends Component {
  componentDidMount() {
    this.props.onMount()
  }
  render () {
    return (
      <Metrics 
        metrics={this.props.metrics}
        fetching={this.props.fetching}
      />
    )
  }
}

export default MetricsContainer