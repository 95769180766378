import React from 'react'

const Icon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style={{enableBackground:"new 0 0 512 512"}} space="preserve" width="20px" height="20px">
    <g>
      <g>
        <path d="M256,512c-3.086,0.001-6.047-1.225-8.229-3.407c-2.182-2.182-3.408-5.143-3.407-8.229V11.636   C244.364,5.21,249.573,0,256,0s11.636,5.21,11.636,11.636v488.727c0.001,3.086-1.225,6.047-3.407,8.229   C262.047,510.775,259.086,512.001,256,512z" dataOriginal="#428DFF" className="active-path" fill="white"/>
        <path d="M500.364,267.636H11.636C5.21,267.636,0,262.427,0,256s5.21-11.636,11.636-11.636h488.727   c6.427,0,11.636,5.21,11.636,11.636S506.79,267.636,500.364,267.636z" dataOriginal="#428DFF" className="active-path" fill="white"/>
      </g>
    </g> 
  </svg>
)

export default Icon
