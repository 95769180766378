import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 512.183 512.183"
      version="1.1"
      viewBox="0 0 512.183 512.183"
      xmlSpace="preserve"
    >
      <path d="M312.923 199.259a116.167 116.167 0 00-20.053-16.128c-38.498-24.667-87.699-25.197-126.72-1.365a120.636 120.636 0 00-22.528 17.835L34.993 308.23c-46.657 46.657-46.657 122.303 0 168.96 46.657 46.657 122.303 46.657 168.96 0l89.6-89.6a8.534 8.534 0 00-6.058-14.592h-3.413a143.626 143.626 0 01-54.613-10.581 8.533 8.533 0 00-9.301 1.877l-64.427 64.512c-20.006 20.006-52.442 20.006-72.448 0-20.006-20.006-20.006-52.442 0-72.448l108.629-108.8c19.913-20.077 52.33-20.21 72.407-.297l.297.297c13.518 12.895 34.781 12.895 48.299 0a34.135 34.135 0 009.899-22.016 34.142 34.142 0 00-9.901-26.283zm-12.445 36.51l-.013.013c-6.776 6.404-17.373 6.404-24.149 0-26.656-26.639-69.856-26.639-96.512 0L71.259 344.411c-26.651 26.675-26.632 69.904.043 96.555 26.675 26.651 69.904 26.632 96.555-.043l60.501-60.587a158.987 158.987 0 0039.339 8.96l-75.776 75.861c-40.059 40.059-105.008 40.059-145.067 0-40.059-40.059-40.059-105.008 0-145.067l108.8-108.459a103.432 103.432 0 0119.029-15.275 102.393 102.393 0 0153.419-14.677 102.397 102.397 0 0155.296 16.128 99.018 99.018 0 0117.067 13.824c6.668 6.663 6.674 17.469.013 24.138z"></path>
      <path d="M477.19 34.993c-46.657-46.657-122.303-46.657-168.96 0l-89.515 89.515a8.533 8.533 0 00-1.792 9.387 8.532 8.532 0 008.021 5.205h3.157a143.354 143.354 0 0154.528 10.667 8.533 8.533 0 009.301-1.877l64.512-64.512c20.006-20.006 52.442-20.006 72.448 0 20.006 20.006 20.006 52.442 0 72.448l-80.043 79.872-.683.768-27.904 28.16c-19.913 20.077-52.331 20.21-72.407.297l-.297-.297c-13.518-12.895-34.781-12.895-48.299 0a34.135 34.135 0 00-9.899 22.016 34.133 34.133 0 009.899 26.283 118.447 118.447 0 0034.133 23.893c1.792.853 3.499 1.621 5.376 2.304 1.877.683 3.499 1.451 5.461 2.048s3.669 1.28 5.461 1.792l5.035 1.365c3.413.853 6.827 1.536 10.325 2.133 4.214.626 8.458 1.025 12.715 1.195h12.458c1.877 0 3.84 0 6.059-.512h2.901l5.888-.853 2.731-.512 4.949-1.024h.939a119.456 119.456 0 0055.381-31.403L477.19 203.953c46.657-46.657 46.657-122.303 0-168.96zm-11.861 157.098l-108.8 108.459a102.402 102.402 0 01-47.872 27.051l-4.437.853-2.56.512-4.864.683h-2.901l-4.864.427H278.279a97.109 97.109 0 01-10.24-1.024c-2.901-.512-5.888-1.109-8.533-1.792l-4.352-1.195c-1.451 0-2.816-.853-4.608-1.536s-3.072-1.109-4.693-1.707l-4.608-1.963a101.626 101.626 0 01-29.269-20.48c-6.669-6.669-6.669-17.481 0-24.149 6.669-6.669 17.481-6.669 24.149 0 26.656 26.639 69.856 26.639 96.512 0l28.331-28.672.683-.768 79.36-79.275c26.445-26.65 26.407-69.653-.085-96.256-26.645-26.675-69.869-26.699-96.544-.054l-.054.054-60.587 60.587a159.677 159.677 0 00-39.168-9.045l75.691-75.776c40.059-40.059 105.008-40.059 145.067 0 40.057 40.059 40.057 105.007-.002 145.066z"></path>
    </svg>
  );
}

export default Icon;
