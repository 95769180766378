import React from "react";
import styled from "styled-components";

function Icon({ color, width, height, padding }) {
  return (
    <ArrowFilled color={color} width={width} height={height} padding={padding}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.99996 3C6.91931 3.00042 5.86789 3.35093 5.00316 3.99904C4.13844 4.64715 3.50697 5.55797 3.20332 6.59508C2.89968 7.63219 2.94021 8.73976 3.31884 9.7519C3.69748 10.764 4.39384 11.6263 5.30362 12.2094C6.2134 12.7926 7.28763 13.0653 8.36542 12.9868C9.4432 12.9082 10.4665 12.4826 11.2821 11.7736C12.0977 11.0647 12.6616 10.1105 12.8895 9.0542C13.1173 7.99784 12.9968 6.89611 12.546 5.914C12.4969 5.7946 12.4959 5.66084 12.5432 5.54072C12.5905 5.42061 12.6824 5.32344 12.7998 5.26957C12.9171 5.21569 13.0507 5.20927 13.1726 5.25167C13.2945 5.29406 13.3954 5.38197 13.454 5.497C13.9949 6.67559 14.1395 7.99773 13.866 9.26538C13.5925 10.533 12.9157 11.678 11.9369 12.5286C10.9581 13.3793 9.73 13.89 8.4366 13.9842C7.1432 14.0783 5.85411 13.7509 4.76243 13.0509C3.67074 12.3509 2.83522 11.3161 2.38102 10.1014C1.92682 8.88676 1.87839 7.55762 2.24299 6.31311C2.60759 5.0686 3.36559 3.97571 4.40344 3.19815C5.44129 2.42059 6.70314 2.00022 7.99996 2V3Z"
          fill={color}
        />
        <path
          d="M8 4.46599V0.533995C8.00002 0.486491 8.01357 0.439976 8.03907 0.399896C8.06457 0.359816 8.10096 0.32783 8.14398 0.307684C8.187 0.287538 8.23487 0.280066 8.28198 0.286142C8.32909 0.292219 8.3735 0.311592 8.41 0.341995L10.77 2.30799C10.89 2.40799 10.89 2.59199 10.77 2.69199L8.41 4.65799C8.3735 4.6884 8.32909 4.70777 8.28198 4.71385C8.23487 4.71992 8.187 4.71245 8.14398 4.69231C8.10096 4.67216 8.06457 4.64017 8.03907 4.60009C8.01357 4.56001 8.00002 4.5135 8 4.46599Z"
          fill={color}
        />
      </svg>
    </ArrowFilled>
  );
}

const ArrowFilled = styled.span`
  fill: ${(props) => props.color || props.theme.primary || "white"};
  width: ${(props) => props.width || "16px"};
  height: ${(props) => props.height || "16px"};
  height: ${(props) => props.padding || "18px"};
`;

export default Icon;
