import React from 'react'
import styled from 'styled-components'
import { darken } from 'polished'
import t from '../../../utils/i18n'
import LoginInput from '../../../components/LoginInput'
import Button from '../../../components/Button'
import ErrorMessage from '../../../components/ErrorMessage'
import Background from '../../../components/Background'
import GameLogo from '../../../components/GameLogo'
import LoginFooter from '../../../components/LoginFooter'
import Spinner from '../../../components/Spinner'

const ForgotPassword = ({
  handleChange, 
  submit, 
  submitting, 
  password, 
  passwordConfirmation, 
  error, 
  checkingHash,
  success,
  t
}) => { 

  return (
    <Wrapper>
      <Background />
      <FormContainer>
        <Header>
          <GameLogo />
          <Subtitle>admin</Subtitle>
        </Header>
        <FormWrapper>
          { checkingHash && (
            <LoaderWrapper>
              <div>
                <Spinner primary />
              </div>
            </LoaderWrapper>
          )}
          <Form onSubmit={submit} show={!checkingHash}>
            { success ? (
              <React.Fragment>
                <Title>
                  {t('auth.reset.success')}
                </Title>
                <ButtonWrapper>
                  <Button onClick={() => {
                    window.location = '/'
                  }}>
                    {t('auth.reset.success_button')}
                  </Button>
                </ButtonWrapper>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <FormTitle>{t('auth.reset.title')}</FormTitle>
                <LoginInput 
                  name="password" 
                  label={t('auth.reset.labels.password')} 
                  placeholder={t('auth.reset.labels.password')}
                  type="password" 
                  password
                  value={password}
                  onChange={handleChange}
                />
                <LoginInput 
                  name="passwordConfirmation" 
                  label={t('auth.reset.labels.confirm_password')} 
                  placeholder={t('auth.reset.labels.confirm_password')} 
                  type="password" 
                  password
                  value={passwordConfirmation}
                  onChange={handleChange}
                />          

                { error && (
                  <ErrorMessage message={error} />
                )}
                
                <ButtonWrapper>
                  <Button
                    disabled={submitting}
                    loading={submitting}
                  >
                    {t('auth.reset.button')} 
                  </Button>
                </ButtonWrapper>
              </React.Fragment>
            )}
          </Form> 
        </FormWrapper>
        <LoginFooter />
      </FormContainer>
    </Wrapper>
  )
}


const Wrapper = styled.div`
  align-items: center;
  background: ${props => darken(0.15, props.theme.primary)};
  display: flex;
  flex-wrap: wrap;
  height: 100vh;
  overflow: hidden;
`

const FormContainer = styled.div`
  align-content: center;
  background-color: white;
  display: flex;
  flex-direction: column;
  font-family: Rubik, Roboto, sans-serif;
  height: 100vh;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  width: 30%;
  z-index: 10;

  @media(max-width: 768px) {
    width: 100vw;
  }
`

const Header = styled.header`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  padding: 0 1rem;
`

const Title = styled.h2`
  color: ${props => props.theme.secondary};
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
  padding: 1rem 0;
  text-align: center;
`

const Subtitle = styled.h2`
  color: #616161;
  font-size: 1.25rem;
  margin: 0.5rem 0 0 0.5rem;
  opacity: 0.7;
`

const FormTitle = styled.h2`
  color: ${props => props.theme.secondary};
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
  padding: 1rem 0;
  text-align: left;
`

const Logo = styled.img`
  border: 8px solid white;
  border-radius: 50%;
  box-shadow:
  rgba(0, 0, 0, 0.16) 1px 1px 21px 6px;
  width: 125px;
  height: 125px;
`


const Form = styled.form`
  width: 90%;
  margin: 1rem auto 0;
  opacity: ${props => props.show ? '1' : '0'};
  transition: opacity: 0.5s;
`

const ButtonWrapper = styled.div`
  padding: 1rem 0;
`

const FormWrapper = styled.div`
  position: relative;
`

const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  > div {
    position: relative;
  }
`
export default ForgotPassword