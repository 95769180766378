import { handleActions } from 'redux-actions'

import * as actions from './actions'

const initialState = {
  status: 'init',
  error: '',
  user: {}
}

const reducer = handleActions({
  [actions.loginUser.START]: (state) => ({
    ...state,
    status: 'pending'
  }),
  [actions.loginUser.FAILURE]: (state, action) => ({
    ...state,
    status: 'failure',
    error: action.payload.errors
  }),
  [actions.loginUser.SUCCESS]: (state, action) => ({
    ...state,
    status: 'success',
    user: action.payload.user
  })
}, initialState)

export default reducer