import React from 'react';
import styled from 'styled-components';

const CustomTable = ({ children }) => {
  return (
    <StyledTable>
      {children}
    </StyledTable>
  );
};

const StyledTable = styled.table`
width: 100%;
border-collapse: separate;
border-spacing: 0;
border-bottom: 1px solid ${(props) => props.theme.slate_gray};

border: 1px solid ${(props) => props.theme.slate_gray};
border-radius: ${(props) => props.theme.border_radius.rounded_md};
margin: 0 0 1rem 0;
td: {
    background: #fff;
  vertical-align: middle;
}
td:first-child {
  td:last-child {

`;

export default CustomTable;
