import React from 'react'
import styled from 'styled-components';

const Insight = ({ img, label, children }) => (
  <Wrapper>
    <div>
      { children } 
      <Label isLabel>{img} <Text>{ label }</Text></Label>
    </div>
  </Wrapper>
)


const Label = styled.span`
  color: #AEAEB2;
  display: block;
  font-size: 0.7rem;
  text-transform: uppercase;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`

const Text = styled.span`
 margin-left: 5px
`


const Wrapper = styled.div`
  align-items: center;
  border: 1px solid #EFEFF4;
  border-radius: 15px;
  display: flex;
  padding: 1.3rem;
  box-shadow: 2px 2px 4px 2px #F5F5F5;
  }
`

export default Insight