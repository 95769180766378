import { isObject } from 'lodash'

export const normalizeAnswers = (answers) => {
  const answerObject = answers.map( a => {
    let answer = {
      content: a.content,
      _id: a._id || null,
      achievement: a.achievement && a.achievement._id ? a.achievement._id : null,
      suggestion: a.suggestion ? (isObject(a.suggestion) ? a.suggestion._id : a.suggestion) : null,
      deleteAch: a.deleteAch
    }
    if (a.next) {
      answer['next'] = a.next._id? a.next._id : a.next
    }

    return answer
  })

  return answerObject

}