export const calculateLastAccess = (date) => {

    const dateToParsed = new Date(date);

    let day = dateToParsed.getDate();
    let month = dateToParsed.getMonth() + 1; 
    const year = dateToParsed.getFullYear();
  
    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }
  
    return day + '/' + month + '/' + year;
  }
