import React, { Component } from 'react'
import { connect } from 'react-redux'

import OrganizationProfile from './components/Modal'

class OrganizationProfileContainer extends Component {
  render () {
    const { onCloseModal, selectedOrganization, fetchOrganizations } = this.props
    return (
      <OrganizationProfile
        onCloseModal={onCloseModal}
        organization={selectedOrganization}  
        fetchOrganizations={fetchOrganizations}
      />
    )
  }
}

const mapStateToProps = state => ({
  selectedOrganization: state.modals.selectedOrganization,
  fetchOrganizations: state.modals.fetchOrganizations,
})

export default connect(
  mapStateToProps,
  null
)(OrganizationProfileContainer)