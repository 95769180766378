import React from 'react'
import styled from "styled-components"

const Title = ({color, fontSize, fontWeight, children, padding}) => {
  return (<StyledTitle color={color} fontSize={fontSize} fontWeight={fontWeight} padding={padding}>
    {children}
  </StyledTitle>)
}

const StyledTitle = styled.h1`
  font-size: ${props => props.fontSize || props.theme.font_size.large}
  font-weight: ${props => props.fontWeight || '700'}
  color: ${props => props.color || props.theme.primary};
  margin: 0;
  padding: ${props => props.padding || '0.8rem 0rem 0rem 0.8rem'}
`

export default Title