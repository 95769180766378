import React, { Component } from 'react'
import { get } from '../../../utils/api'
import Ranking from './Ranking'

export default class RankingContainer extends Component {

  constructor(props){
    super(props)
    this.state = {
      error: null,
      data: null,
      organizations: null
    }
  }
  componentDidMount(){
    this.fetchUsers()
    this.fetchOrganizations()
  }

  fetchUsers = async() => {
    try {
      const res = await get('users')
      this.setState({
        data: res.res.users
      })
    } catch (err) {
      this.setState({
        error: err
      })
    }
  }

  fetchOrganizations = async() => {
    try {
      const res = await get('organizations')
      this.setState({
        organizations: res.res.organizations
      })
    } catch (err) {
      this.setState({
        error: err
      })
    }
  }

  
  render(){
    return (
      <Ranking
        users={this.state.data}
        organizations={this.state.organizations}
      />
    )
  }
}