import React from 'react'
import styled from 'styled-components'
import Icon from './assets/DownArrow'

const Select = ({ label, values, value,  ...props }) => (
  <Wrapper>
    { label && <Label>{ label }</Label> }
    <StyledSelect value={value} {...props }>
      { values.map((v) => (
        <option value={v.value} key={v.value}>{v.name}</option>
      ))}
    </StyledSelect>
    <IconWrapper>
      <Icon />
    </IconWrapper>
  </Wrapper>
)

const Wrapper = styled.div`
  align-items: flex-end;
  border-bottom: 1px solid ${props => props.theme.primary};
  display: flex;
  font-family: sans-serif;
  padding: 1.25rem 0rem 0.75rem;
`

const Label = styled.label`
  color: #464648;
  font-size: 0.75rem;
  margin-right: 0.5rem;
`

const StyledSelect = styled.select`
  appearance: none;
  border: none;
  font-size: 1.25rem;
  font-weight: 500;
`

const IconWrapper = styled.div`
  margin-left: 0.5rem;
`

export default Select