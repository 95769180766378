import React from 'react'
import styled, { withTheme } from 'styled-components'
import PropTypes from 'prop-types'

import Thumbnail from '../Thumbnail'

const NavbarHeader = ({ img, title }) => (
  <Wrapper>
    <Span>{ title }</Span>
  </Wrapper>
)

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  margin-top: 1.5rem;
  text-align: center;
`

const Span = styled.span`
  color: ${props => props.theme.primary};
  font-family: 'Roboto', sans-serif;
  font-size: 1.5em;
  font-weight: bold;
`


NavbarHeader.propTypes = {
  img: PropTypes.string,
  title: PropTypes.string
}

export default withTheme(NavbarHeader)