import React from "react";
import styled from "styled-components";

function Icon({ color, height, width, padding }) {
  return (
    <FaceFilled color={color} height={height} width={width} padding={padding}>
      <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_445_3615)">
          <path
            d="M8.6665 15.5C6.80999 15.5 5.02951 14.7625 3.71676 13.4497C2.404 12.137 1.6665 10.3565 1.6665 8.5C1.6665 6.64348 2.404 4.86301 3.71676 3.55025C5.02951 2.2375 6.80999 1.5 8.6665 1.5C10.523 1.5 12.3035 2.2375 13.6163 3.55025C14.929 4.86301 15.6665 6.64348 15.6665 8.5C15.6665 10.3565 14.929 12.137 13.6163 13.4497C12.3035 14.7625 10.523 15.5 8.6665 15.5ZM8.6665 16.5C10.7882 16.5 12.8231 15.6571 14.3234 14.1569C15.8236 12.6566 16.6665 10.6217 16.6665 8.5C16.6665 6.37827 15.8236 4.34344 14.3234 2.84315C12.8231 1.34285 10.7882 0.5 8.6665 0.5C6.54477 0.5 4.50994 1.34285 3.00965 2.84315C1.50936 4.34344 0.666504 6.37827 0.666504 8.5C0.666504 10.6217 1.50936 12.6566 3.00965 14.1569C4.50994 15.6571 6.54477 16.5 8.6665 16.5Z"
            fill={color}
          />
          <path
            d="M4.95165 12.933C5.06649 12.9993 5.20296 13.0173 5.33105 12.9829C5.45913 12.9486 5.56834 12.8648 5.63465 12.75C5.94176 12.2177 6.3837 11.7756 6.91597 11.4684C7.44825 11.1612 8.05207 10.9996 8.66665 11C9.96165 11 11.0926 11.703 11.6986 12.75C11.7654 12.8639 11.8745 12.9468 12.0022 12.9805C12.1298 13.0143 12.2657 12.9962 12.38 12.9301C12.4943 12.8641 12.5779 12.7556 12.6125 12.6281C12.6471 12.5007 12.6299 12.3648 12.5646 12.25C12.1698 11.5656 11.6016 10.9974 10.9173 10.6024C10.233 10.2074 9.45675 9.99964 8.66665 10C7.87658 9.99984 7.1004 10.2077 6.41614 10.6027C5.73189 10.9976 5.16368 11.5658 4.76865 12.25C4.70235 12.3648 4.68438 12.5013 4.7187 12.6294C4.75302 12.7575 4.83681 12.8667 4.95165 12.933ZM7.66665 7C7.66665 7.828 7.21865 8.5 6.66665 8.5C6.11465 8.5 5.66665 7.828 5.66665 7C5.66665 6.172 6.11465 5.5 6.66665 5.5C7.21865 5.5 7.66665 6.172 7.66665 7ZM11.6666 7C11.6666 7.828 11.2186 8.5 10.6666 8.5C10.1146 8.5 9.66665 7.828 9.66665 7C9.66665 6.172 10.1146 5.5 10.6666 5.5C11.2186 5.5 11.6666 6.172 11.6666 7Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_445_3615">
            <rect
              width="16"
              height="16"
              fill={color}
              transform="translate(0.666504 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </FaceFilled>
  );
}

const FaceFilled = styled.span`
  fill: ${(props) => props.color || props.theme.primary || "#1F2937"};
  width: ${(props) => props.width || "14px"};
  padding: ${(props) => props.padding || "0 0 5px 0"};
  height: ${(props) => props.height || "10px"};
`;

export default Icon;
