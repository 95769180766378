import React, { Component } from "react";
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import Achievement from "./Achievement";
import Suggestions from "./Suggestions";
// import ArrowIcon from '../../../components/Icons/Arrow'
import CloseIcon from "../assets/CloseIcon";
import LightbulbIcon from "../../Icons/Lightbulb";
import TrophyIcon from "../../Icons/Trophy";
import Select from "react-select";
import { customStyles } from "../../../utils/helpers";
import _ from "lodash";
import TooltipCard from "../../TooltipCard";

class EditableAnswer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answer: props.value,
      nextInput: props.next ? props.next._id : props.next,
      nextType: null,
    };
  }

  onAnswerChange = (e) => {
    this.setState({
      answer: e.target.value,
    });
  };

  onNextInputChange = (e) => {
    this.setState(
      {
        nextInput: e.value,
      },
      this.props.updateAnswer("next", e.value)
    );
  };

  formatNextQuestions = () => {
    const nextQ = this.props.questionsList.map((q) => ({
      label: q.content,
      value: q._id,
    }));
    return nextQ;
  };

  changeType = (name) => {
    this.setState(
      {
        nextType: !(this.state.nextType === name) && name,
      },
      this.props.cleanAnswer
    );
  };

  componentDidMount() {
    if (this.props.achievement && this.props.achievement._id) {
      this.setState({
        nextType: "achievement",
      });
    }
    if (this.props.suggestion && this.props.suggestion._id) {
      this.setState({
        nextType: "suggestion",
      });
    }
  }

  render() {
    const { answer, nextInput } = this.state;
    const { itemNumber, removeAnswer, updateAnswer, translations, t } =
      this.props;
    return (
      <Wrapper>
        <AnswerWrapper>
          <Label>
            {`${t("game.questions.edit.answer.placeholder")} ${itemNumber}`}
          </Label>
          <Answer
            type="text"
            rows="6"
            onChange={this.onAnswerChange}
            value={answer}
            placeholder={t("game.questions.edit.answer.add")}
            onBlur={() => updateAnswer("content", answer)}
          />
          <RemoveAnswerWrapper onClick={removeAnswer}>
            <IconWrapper>
              <CloseIcon />
            </IconWrapper>
          </RemoveAnswerWrapper>
        </AnswerWrapper>
        <SelectWrapper>
          <Label>{t("game.questions.edit.next")}</Label>
          <Select
            styles={customStyles}
            options={this.formatNextQuestions()}
            onChange={(ev) => this.onNextInputChange(ev)}
            value={this.formatNextQuestions().filter(
              ({ value }) => value === nextInput
            )}
            placeholder={t("game.questions.edit.select")}
          />
          {(!nextInput || nextInput.length == 0) && (
            <TooltipCard type="warning" margin="1rem 0 0 0">
              {t("game.questions.edit.next_warning")}
            </TooltipCard>
          )}
        </SelectWrapper>

        <TypeWrapper>
          <Type
            selected={this.state.nextType === "achievement"}
            onClick={() => {
              this.changeType("achievement");
            }}
          >
            <TrophyIcon />
          </Type>
          <Type
            selected={this.state.nextType === "suggestion"}
            onClick={() => {
              this.changeType("suggestion");
            }}
          >
            <LightbulbWrapper>
              <LightbulbIcon />
            </LightbulbWrapper>
          </Type>
        </TypeWrapper>
        {this.state.nextType === "achievement" ? (
          <Achievement
            achievementsWithError={this.props.achievementsWithError}
            translations={translations.edit.achievements}
            onChange={this.props.onAchievementChange}
            data={this.props.achievement || {}}
          />
        ) : this.state.nextType === "suggestion" ? (
          <Suggestions
            translations={translations.edit.suggestions}
            defaultSuggestion={this.props.suggestion}
            axisId={this.props.axisId}
            suggestions={this.props.suggestions}
            onChange={this.props.onSuggestionChange}
          />
        ) : null}
      </Wrapper>
    );
  }
}

const TypeWrapper = styled.div`
  display: flex;
`;

const Type = styled.div`
  background-color: ${(props) =>
    props.selected ? props.theme.primary : "#D8D8D8"};
  display: flex;
  margin-right: 1rem;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  transition: box-shadow 0.2s;
  cursor: pointer;

  &:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
`;

const LightbulbWrapper = styled.div`
  height: 40px;
  width: 40px;
  padding: 0.5rem;
`;

const AnswerWrapper = styled.div`
  position: relative;
`;

const Label = styled.div`
  font-size: 1rem;
  color: ${(props) => props.theme.primary};
  font-family: Rubik;
  padding: 1rem 0;
`;

const Answer = styled.textarea`
  color: #3f4b63;
  width: 100%;
  border: 2px solid #d8d8d8;
  border-radius: 4px;
  padding: 1rem;
  font-size: 1.2rem;
  outline: none;
  resize: none;
  transition: border-color 0.2s;

  &:focus {
    border: 2px solid ${(props) => props.theme.primary};
  }
`;

const SelectWrapper = styled.div`
  padding: 1rem 0;
`;

const RemoveAnswerWrapper = styled.div`
  cursor: pointer;
  display: block;
  position: absolute;
  top: 20px;
  right: 20px;
`;
const IconWrapper = styled.div`
  position: absolute;
`;
const Wrapper = styled.div`
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
`;

export default withTranslation()(EditableAnswer);
