import React, { useEffect } from 'react'
import styled from 'styled-components'

const AxisSelector = ({axes, selected, onChange, setSelectAxis}) => {

  useEffect(() => {
    if (axes && axes.length > 0 && !selected) {
      onChange(axes[0]._id)
      setSelectAxis(axes[0]?.name)
    }
  }, [axes])

  const selectedAxis = (axisId, axesName) => {
    onChange(axisId)
    setSelectAxis(axesName.name)
  }
  return (
    <Wrapper>
      { axes && axes.map((axis) => (
        <AxisItem key={axis._id} selected={selected == axis._id} onClick={() => {
          selectedAxis(axis._id, axis)
        }}>
          { axis.name }
        </AxisItem>
      ))}
    </Wrapper>
  )
}


const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const AxisItem = styled.div`
  border-radius: 10px;
  margin-right: 1rem;
  padding: 0.5rem 2rem;
  font-weigth: 600;
  background: white;
  border: ${props => props.theme.secondary};
  border: 2px solid ${props => props.theme.slate_gray};

  cursor: pointer;
  color: #6B7280;
  ${props => props.selected && `
    color: white;
    background: ${props.theme.primary};
    border: none;
  `}
  margin-top: 15px

`

export default AxisSelector