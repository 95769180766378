import React, { useEffect } from "react";
import styled from "styled-components";
import t from "../../../../utils/i18n";
import CustomTable from "../../../CustomTable";
import Award from "../../../../components/Icons/Award";
import Title from "../../../Title";
import themes from "../../../../utils/themes";

const AchievementsCount = ({ selectedAxis, achievements }) => {
  return (
    <Wrapper>
      <TitleContent>
        <Award width={"20px"} />
        <Title color={'#1F2937'} fontSize={'medium'} fontWeight={'600'} padding={'15px 0 0px '}>{t("axes_metrics.top_achievements.title")}</Title>
      </TitleContent>
      <CustomTable>
        <tbody>
          {selectedAxis &&
            achievements &&
            achievements
              .filter((ach) => !!ach.question)
              .filter((ach) => ach.question.axis._id === selectedAxis)
              .sort((a, b) => b.wins - a.wins)
              .slice(0, 5)
              .map((obj) => (
                <TableRow key={obj._id}>
                  <TableCell>
                    <FlexContainer>
                      <Title color={'#1F2937'} fontSize={'medium'} fontWeight={'500'} padding={'0 0.4rem 0.4rem'}>
                        {obj.title}
                      </Title>
                      <WinsNumber>{obj.wins}</WinsNumber>
                    </FlexContainer>
                    <Content>{obj.question.content}</Content>
                  </TableCell>
                </TableRow>
              ))}
        </tbody>
      </CustomTable>
    </Wrapper>
  );
};

const Wrapper = styled.div`
`;

const TableRow = styled.tr`
`;
const Content = styled.p`
  font-size: 13px;
  font-weigth: 400;
  margin: 0;
  padding-left: 8px;
  color: #1F2937
`;

const TableCell = styled.td`
border-bottom: 0.1px solid ${(props) => props.theme.slate_gray}; 
 padding: 10px;
  vertical-align: top;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: initial;

`;

const WinsNumber = styled.span`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  padding: 10px;
  height: 28px;
  width: 28px;
  color: white;
  background: ${(props) => props.theme.primary};
  font-size: ${(props) => props.theme.font_size.xs};
  text-align: center;
  `;

const TitleContent = styled.div`
  display: flex;
  align-items: center
  gap: 10px;
  margin-bottom: 18px;
`;

// const Title = styled.p`
//   font-size: 16px;

// `;


export default AchievementsCount;
