import React from 'react'
import Invite from './Invite';

class InviteContainer extends React.Component{
  
  componentDidMount(){
    this.props.onMount()
  }
  
  render(){
    const { invites, status } = this.props
    return(
      <Invite data={invites} status={status}/>
    )
  }
}

export default InviteContainer