import React from "react";
import styled from "styled-components";

function Icon({color, height, width}){
  return (
    <IconContent color={color} height={height} width={width}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_744_301)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.763 0.0750045C14.8354 0.119799 14.8952 0.18235 14.9367 0.256728C14.9782 0.331105 15 0.414844 15 0.500005V15.5C15 15.6326 14.9473 15.7598 14.8536 15.8536C14.7598 15.9473 14.6326 16 14.5 16H11.5C11.3674 16 11.2402 15.9473 11.1464 15.8536C11.0527 15.7598 11 15.6326 11 15.5V14H10V15.5C10 15.6326 9.94732 15.7598 9.85355 15.8536C9.75979 15.9473 9.63261 16 9.5 16H0.5C0.367392 16 0.240215 15.9473 0.146447 15.8536C0.0526784 15.7598 0 15.6326 0 15.5V10C7.96467e-05 9.89511 0.0331481 9.79289 0.0945249 9.70783C0.155902 9.62276 0.242478 9.55915 0.342 9.526L6 7.64V4.5C6 4.40723 6.02582 4.31628 6.07456 4.23734C6.12331 4.15839 6.19305 4.09457 6.276 4.053L14.276 0.0530045C14.3523 0.0148103 14.4371 -0.00321934 14.5224 0.00063155C14.6076 0.00448244 14.6904 0.0300857 14.763 0.0750045ZM6 8.69401L1 10.36V15H6V8.69401ZM7 15H9V13.5C9 13.3674 9.05268 13.2402 9.14645 13.1465C9.24021 13.0527 9.36739 13 9.5 13H11.5C11.6326 13 11.7598 13.0527 11.8536 13.1465C11.9473 13.2402 12 13.3674 12 13.5V15H14V1.309L7 4.809V15Z"
            fill={color}
          />
          <path
            d="M2 11H3V12H2V11ZM4 11H5V12H4V11ZM2 13H3V14H2V13ZM4 13H5V14H4V13ZM8 9H9V10H8V9ZM10 9H11V10H10V9ZM8 11H9V12H8V11ZM10 11H11V12H10V11ZM12 9H13V10H12V9ZM12 11H13V12H12V11ZM8 7H9V8H8V7ZM10 7H11V8H10V7ZM12 7H13V8H12V7ZM8 5H9V6H8V5ZM10 5H11V6H10V5ZM12 5H13V6H12V5ZM12 3H13V4H12V3Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_744_301">
            <rect width={"16"} height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </IconContent>
  );
}

const IconContent =  styled.div`
fill: ${(props) => props.color || props.theme.primary || 'black'};
width: ${props => props.width || "20px"};
height: ${props => props.height || "20px"};
`

export default Icon;
