import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { lighten } from 'polished'

const NavbarTab = ({ link, name, icon, ...props }) => (
   <Wrapper {...props} to={link}>
    <ContentWrapper>   
     <IconWrapper>
      { icon }
    </IconWrapper>
    <TextWrapper>{ name }</TextWrapper>
    </ContentWrapper>
  </Wrapper>
)


const IconWrapper = styled.div`
  margin-right: 1rem;
    &:hover {
      path {
        fill: ${props => props.theme.primary};
      }
    }
  
 }
`
const Wrapper = styled(NavLink)`
  padding: 1em 2em;
  display: flex;  
  align-items: center;
  transition: all 0.2s;
  cursor: pointer;
  text-decoration: none;
  color: ${props => props.active ? props.theme.primary : '#1F2937'}
  border-left: 4px solid #FFF;
  &:hover {
    color: ${props => props.theme.primary};
    ${IconWrapper} {
      path {
        fill: ${props => props.theme.primary};
      }
    }
  }
`
const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
`
const TextWrapper = styled.div`
font-size:  ${props =>  props.theme.font_size.medium}
line-height: 24px;
font-weight: 500;
`

NavbarTab.propTypes = {
  link: PropTypes.string,
  name: PropTypes.string,
  active: PropTypes.bool
}

export default NavbarTab