import React, { Component } from 'react'
import Overview from './Overview'

class OverviewContainer extends Component {
  render() {
    return (
      <Overview {...this.props} />
    )
  }
}

export default OverviewContainer