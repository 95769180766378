import React from 'react'
import styled, { withTheme } from 'styled-components'
import t from '../../utils/i18n'
import NavbarHeader from '../NavbarHeader/index'
import NavbarTab from '../NavbarTab/index'
import Logo from '../Logo'
import { withRouter } from 'react-router-dom';
import Dashboard from '../Icons/Dashboard'
import GameScene from '../Icons/GameScene'
import ConfigScene from '../Icons/ConfigScene'
import UserScene from '../Icons/UserScene'
import LogOut from '../Icons/LogOut'

const Navbar = (props) => {
  const { location } = props
  
  const handleLogout = () => {
    localStorage.removeItem('token')
    window.location = '/'
  }

  return (
    <Wrapper>
      <HeaderWrapper>
      <Logo imgUrl={props.imgUrl} />
      </HeaderWrapper>
      <NavbarTab 
        link="/overview" 
        name={ t('navbar.overview') } 
        active={!!(location.pathname.includes('/overview'))}
        icon={(<Dashboard color={!!(location.pathname.includes('/overview')) ? props.theme.primary : '#1F2937'} />)}
      />
      <NavbarTab 
        link="/game" 
        name={ t('navbar.game') } 
        active={!!(location.pathname.includes('/game'))}
        icon={(<GameScene color={!!(location.pathname.includes('/game')) ? props.theme.primary : '#1F2937'} />)}
      />
      <NavbarTab 
        link="/organization" 
        name={ t('navbar.institution') } 
        active={!!(location.pathname.includes('/organization/'))}
        icon={(<UserScene color={!!(location.pathname.includes('organization/')) ? props.theme.primary : '#1F2937'} />)}
      />
      <NavbarTab 
        link="/profile/main" 
        name={ t('navbar.edit') } 
        active={!!(location.pathname.includes('/profile/main'))}
        icon={(<ConfigScene color={!!(location.pathname.includes('/profile/main')) ? props.theme.primary : '#1F2937'} />)}
      />
      <Line />
      <LogoutWrapper>
        <StyledLogout onClick={() => handleLogout()}>

          <LogOut color={''}/>
        { t('navbar.logout') }
        </StyledLogout>
      </LogoutWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 18%;
  background-color: #FFF;
  border-right: 1px solid ${props => props.theme.primary};
  height: 100vh;
  position: sticky;
  top: 0;

`

const Line = styled.div`
  width: 60%;
  height: 1px;
  background-color: ${props => props.theme.slate_gray}; 
  margin-left: 40px;
  margin-top: 40px;
`;


const HeaderWrapper = styled.div`
  padding: 2rem;
  margin-bottom: 0.5rem;
`

const LogoutWrapper = styled.div`

`

const StyledLogout = styled.button`
  display: flex;
  align-items:center;
  gap: 10px;
  margin-left: 10px;
  border: none;
  background: none;
  color: #464648;
  cursor: pointer;
  font-size:  ${props =>  props.theme.font_size.medium}
  line-height: 24px;
  font-weight: 500;
  height: 40px;
  padding: 2rem;
  outline: none;
  
`
export default withTheme(withRouter(Navbar))
