import React from "react";

      
function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 352 512"      
    >
      <path
        fill="#FFF"
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        d="M175.668 0C78.805 0 0 78.805 0 175.668c0 40.355 14.047 79.746 39.563 110.926 26.292 32.054 40.773 72.539 40.773 114v16.074c0 29.102 22.738 41.812 33.469 45.082 5.984 28.656 31.441 50.25 61.863 50.25s55.879-21.594 61.863-50.25C249.203 458.195 271 444.926 271 416.668v-16.074c0-41.461 14.48-81.946 40.773-114 25.516-31.18 39.563-70.57 39.563-110.926C351.336 78.805 272.53 0 175.668 0zm.016 482H175.648c-12.906 0-24.117-7.406-29.601-18.2h59.238c-5.484 10.794-16.695 18.2-29.601 18.2zm48.183-48.2H127.47c-7.61 0-17.133-5.519-17.133-18.198H241c0 12.675-9.527 18.199-17.133 18.199zM157.461 298.63l-21.238-53.094 35.808 8.95c1.426.359 4.082.8 7.274 0l35.808-8.95-21.238 53.094a14.968 14.968 0 00-1.074 5.57v81.403h-34.27v-81.403c0-1.906-.363-3.797-1.07-5.57zm131.105-31.05c-27.492 33.515-43.918 74.948-47.027 118.023h-18.738V307.09l31.058-77.652c4.54-11.34-5.703-23.086-17.562-20.122l-60.629 15.157-60.629-15.157c-11.848-2.964-22.105 8.774-17.566 20.121l31.062 77.653v78.512h-18.742c-3.11-43.07-19.531-84.508-47.023-118.024C41.637 241.762 30 209.118 30 175.668 30 95.348 95.348 30 175.668 30c80.32 0 145.668 65.348 145.668 145.668 0 33.45-11.64 66.094-32.77 91.91z"
      ></path>
    </svg>
  );
}

export default Icon;
