import React from 'react'
import styled, {keyframes} from 'styled-components'
const SkeletonTable = () => (
  <Wrapper>
    <Card>
      <ContentBox></ContentBox>
    </Card>
    {/* <Card>
      <ContentBox></ContentBox>
    </Card>
    <Card>
      <ContentBox></ContentBox>
    </Card> */}
  </Wrapper>
)

const gradientBG = keyframes`
  0%{
    background-position: 0% 50%;
  }
  50%{
    background-position: 100% 50%;
  }
  100%{
    background-position: 0% 50%;
  }
`

const Wrapper = styled.div`
  background-color: white;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`

const Card = styled.div`
  background-color: white;
  border: ${props => props.active ? `2px solid ${props.theme.primary}` : "1px solid #979797"};
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 130px;
  margin-bottom: 2rem;
  padding: 2rem 1rem;
  position: relative;
  width: 280px;
`

const ContentBox = styled.div`
  width: 100%;
  height: 2.5rem;
  margin-right: 4rem;
  background: linear-gradient(-45deg, #fbfbfb, #cccccc, #fbfbfb );
  background-size: 400% 400%;
  animation: ${gradientBG} 2s ease infinite;
  
`

export default SkeletonTable