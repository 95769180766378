import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const TextInput = ({ label, required = false, errorLabel = "", ...props }) => {
  return (
    <Wrapper {...props}>
      {label && <Label>{label}</Label>}
      <Input {...props} />
      {required && <ErrorLabel>{errorLabel}</ErrorLabel>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  font-family: sans-serif;
  padding: 1.25rem 0rem 0.75rem;
`;

const ErrorLabel = styled.label`
  color: red;
  font-weight: 500;
  font-size: 0.75rem;
  padding: 1rem 0;
`;

const Label = styled.label`
  color: #464648;
  font-weight: 500;
  font-size: 0.75rem;
  padding: 1rem 0;
`;

const Input = styled.input`
  border: none;
  padding: 1rem 0;
  border-bottom: 2px solid #d8d8d8;
  color: ${(props) => (props.status === "failure" ? "#d43328" : "#000000")};
  font-size: 1rem;
  width: 100%;
  outline: none;
  transition: border-color 0.2s;

  &:focus {
    border-bottom: 2px solid ${(props) => props.theme.primary};
  }

  &:read-only,
  &:disabled {
    background: transparent;
    color: #d8d8d8;
    cursor: not-allowed;
  }
  ::placeholder {
    opacity: 1;
  }
`;

TextInput.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
};

export default TextInput;
