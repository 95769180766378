import React, {useState} from 'react'
import Modal from '../Modals/index'
import EditSuggestion from '../EditSuggestion'

const CreateSuggestionContainer = ({children, axisId, onSuccess}) => {
  const [isOpen, onModalOpen] = useState(false)
  return (
    <>
      {isOpen && (<Modal onCloseModal={() =>onModalOpen(false) }>
        <EditSuggestion 
          onCloseModal={(res) => {
            onModalOpen(false)
            onSuccess && onSuccess(res.createSuggestion._id)
          } } 
          axisId={axisId}
        />
      </Modal>)}
      <span onClick={() => { onModalOpen(true) }}>
        {children}
      </span>
    </>
  )
}

export default CreateSuggestionContainer