import React from 'react'
import styled from 'styled-components'


const Avatar = styled.div`
  align-items: center;
  background-color: ${props => props.color};
  border-radius: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 1px 1px 21px 6px;
  color: white;
  display: flex;
  font-family: Rubik, sans-serif;
  font-size: ${props => props.size * 0.5 + 'px'};
  font-weight: bold;
  height: ${props => props.size + 'px'};
  justify-content: center;
  width: ${props => props.size + 'px'};
`

const getRandomColor = (name) => {
  const colors = ['#e5b92e', '#84c743', '#6dd0e7', '#f44274']
  let hash = 0
  Array.from(name).map((i) => hash += name.charCodeAt(i))
  return colors[hash % colors.length]
}

const getInitials = (name) => {
  const names = name.split(" ")
  const initials = names.length > 1 ? names[0][0] + names[1][0] : names[0][0]
  return initials
}

const OrgAvatar = ({ name, size }) => {
  
  return (
    <Avatar color={getRandomColor(name)} size={size}>
      { getInitials(name).toUpperCase() }
    </Avatar>
  )
}

export default OrgAvatar