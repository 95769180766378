import { isNil, isEmpty} from 'ramda'
export const isAuthenticated = () => {
  const token = localStorage.getItem('token')
  if(token === 'undefined' || isNil(token) || isEmpty(token)) return false
  
  return true
}

export const customStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: 20,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    borderBottom: '2px solid #D8D8D8'
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
}

export const unauthenticated = () => {
  if (!window.location.href.includes('login')) {
    localStorage.removeItem('token')
    localStorage.removeItem('id')
    window.location.reload()
  }
}