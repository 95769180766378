import GameContainer from './scenes'
import QuestionsContainer from './scenes/Questions'
import * as constants from './constants'

export default {
  constants,
  scenes: {
    GameContainer,
    QuestionsContainer,
  }
}