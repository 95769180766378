import { gql } from 'apollo-boost'

export const CREATE_QUESTION = gql`
  mutation CreateQuestion($content: String!, $axis: String!, $answers: [AnswerInput]) {
    createQuestion(content: $content, axis: $axis, answers: $answers) {
      _id,
      content
      answers {
        _id
        content
      }
    }
  }
`