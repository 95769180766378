import React, { useState } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Overview from "../modules/overview/index";
import Organization from "../modules/organization/index";
import Game from "../modules/game/index";
import Login from "../modules/auth/index";
import ForgotPassword from '../modules/auth/components/ForgotPasswordContainer'
import ResetPassword from '../modules/auth/components/ResetPasswordContainer'
import Profile from "../modules/profile/index";
import MainLayout from "../modules/_common/MainLayout";
import { isAuthenticated } from "../utils/helpers";
import themes, { ThemeContext } from "../utils/themes";
import NotFound from "../components/NotFound";
import FetchGameWrapper from "../components/FetchGameWrapper";

import { ApolloProvider } from "@apollo/react-hooks";
import { createUploadLink } from "apollo-upload-client";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";

const uploadLink = createUploadLink({
  uri: `${process.env.REACT_APP_GAME_URL}/graphql`,
  headers: {
    "keep-alive": "true",
    "Apollo-Require-Preflight": "true"
  }
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      token: token ? `${token}` : "",
      game_id: process.env.REACT_APP_ORGANIZATION_ID
    }
  };
});
const cache = new InMemoryCache();
const client = new ApolloClient({
  link: authLink.concat(uploadLink),
  cache
});

const Root = ({ modalOpen, organization }) => {
  const [gameParams, toggleGameParams] = useState({
    palette: "default",
    name: "game",
    lang: "en",
    logo: ""
  });

  const value = {
    gameParams,
    toggleGameParams
  }
  return (
    <FetchGameWrapper
      onGameChange={gameParams => {
        toggleGameParams(gameParams);
      }}
    >
      <ApolloProvider client={client}>
      <ThemeContext.Provider value={value}>
        <ThemeProvider theme={themes[gameParams.palette]}>
            <Router>
              {isAuthenticated() && (
                <MainLayout modalOpen={modalOpen} organization={organization}>
                  <div>
                    <Switch>
                      <Route
                        exact
                        path="/"
                        render={() => <Redirect to="/overview" />}
                      />
                      <Route
                        path="/overview"
                        component={Overview.scenes.OverviewContainer}
                      />
                      <Route
                        path="/organization"
                        component={Organization.scenes.OrganizationContainer}
                      />
                      <Route path="/game" component={Game.scenes.GameContainer} />
                      <Route
                        path="/profile"
                        component={Profile.scenes.ProfileContainer}
                      />
                      <Route component={NotFound} />
                    </Switch>
                  </div>
                </MainLayout>
              )}
              {!isAuthenticated() && (
                <Switch>
                  <Route exact path="/forgot" component={ForgotPassword} />
                  <Route exact path="/reset/:hash?" component={ResetPassword} />
                  <Route exact path="/" render={() => <Redirect to="/login" />} />
                  <Route path="/login" component={Login.scenes.login} />
                  {/* <Route render={() => <Redirect to="/login" />} /> */}
                </Switch>
              )}
            </Router>
          </ThemeProvider>
        </ThemeContext.Provider>
      </ApolloProvider>
    </FetchGameWrapper>
  );
};

const mapStateToProps = state => ({
  modalOpen: state.modals.isOpen
});

export default connect(mapStateToProps)(Root);
