import React from 'react'
import styled, { keyframes } from 'styled-components'

const Background = props => (
  <BgWrapper>
    <Svg viewBox="0 24 150 28" {...props}>
      <defs>
        <path
          id="prefix__a"
          d="M-160 44c30 0 58-18 88-18s58 18 88 18 58-18 88-18 58 18 88 18v44h-352z"
        />
      </defs>
      <g className="wavies">
        <use xlinkHref="#prefix__a" x={50} y={6} fill="currentColor" />
      </g>
      <g className="wavies">
        <use xlinkHref="#prefix__a" x={50} y={8} fill="currentColor" />
      </g>
    </Svg>
  </BgWrapper>
)

const Waves = keyframes`
  0% { transform: translate(-90px , 0%) }
  100% { transform: translate(85px , 0%) }
`
const Swell = keyframes`
0% { transform: translate(-90px , 0%) }
50% { transform: translate(0px , 0%) }
100% { transform: translate(85px , 0%) }
`

const BgWrapper = styled.div`
  width: 70%;
  max-height: 100vh;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  @media(max-width: 768px) {
    display: none;
  }
`

const Svg = styled.svg`
  height: 40vh;
  position: absolute;
  bottom: 0;
  max-width: 100%;

  & >.wavies {
    transform: translate3d(0, 0, 0);
    animation: ${Waves} 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
    color: ${props => props.theme.primary};
    opacity: 0.5;
  }

  & >.wavies:nth-of-type(2) {
    top: -10px;
    transform: translate3d(0, -25px, 0);
    animation: ${Swell} 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
    
    opacity: 1;
  }
`

export default Background