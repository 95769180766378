import React from "react";
import styled, { css } from "styled-components";
import TrophyIcon from "../Icons/TrophyCupRed";
import TrophyIconWhite from "../Icons/TrophyCupWhite";

const AchievementCount = ({ achievement }) => {

    const hasAchievement = achievement >= '1';
    
  return (
    <Wrapper>
       {hasAchievement ? <TrophyIconWhite/> : <TrophyIcon/>} {achievement}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border: 1px solid ${(props) => props.theme.primary};
  width: max-content;
  padding: 5px;
  border-radius: 15px;
  background-color: ${(props) => props.children[2] >= '1' ? props.theme.primary : 'white'};
  color: ${(props) => props.children[2] >= '1' ? 'white' : 'black'};
`;

export default AchievementCount;
