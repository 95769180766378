import React, { useState, useEffect } from "react";
import styled from "styled-components";
import TextInput from "../../../TextInput";
import t from "../../../../utils/i18n";
const Wrapper = styled.div``;

const Achievement = (props) => {
  const [title, changeTitle] = useState(props.data.title || "");
  const [description, changeDesc] = useState(props.data.description || "");

  useEffect(() => {
    props.onChange({
      _id: props.data._id,
      title: title,
      description: description,
    });
  }, [title, description]);

  return (
    <Wrapper>
      <TextInput
        label={t("game.questions.achievements.title")}
        value={title}
        type="text"
        errorLabel={t("game.questions.achievements.titleError")}
        required={props.achievementsWithError && title == ""}
        onChange={(e) => changeTitle(e.target.value)}
      />
      <TextInput
        label={t("game.questions.achievements.description")}
        value={description}
        type="text"
        errorLabel={t("game.questions.achievements.descriptionError")}
        required={props.achievementsWithError && description == ""}
        onChange={(e) => changeDesc(e.target.value)}
      />
    </Wrapper>
  );
};

Achievement.defaultProps = {
  data: {},
};

export default Achievement;
