import React from 'react'
import styled, { keyframes } from 'styled-components'
import PropTypes from 'prop-types'
import Spinner from '../Spinner'

const Button = (props) => (
  <StyledButton onClick={!props.loading && props.onClick} {...props}>
    { props.loading ? (
      <SpinnerContent>
        <Spinner/>
      </SpinnerContent>
    ) : props.children }
  </StyledButton>
)

const ripple = keyframes`
  from {
    opacity: 1;
    transform: scale(0);
  }
  to {
    opacity: 0;
    transform: scale(10)
  }
`

const SpinnerContent = styled.div`
  position: relative;
  width: 100%;
`

const StyledButton = styled.button`
  display: inline-block;
  position: relative;
  overflow: hidden;
  background-color: ${props => props.outline ? 'white' : props.theme.primary};
  border: ${props => props.outline ? `1px solid ${props.theme.secondary}` : 'none'};
  border-radius: 16px;
  color: ${props => props.outline ? props.theme.secondary : 'white'};
  cursor: pointer;
  font-weight: bold;
  // width: ${props => props.fullWidth ? '100%' : 'auto'};
  width: fit-content;
  padding: 0.90rem 2rem;
  outline: none;
  text-align: center;
  font-size: 0.9rem;
  min-height: 3rem;
  ${props => props.white && `
    background-color: white;
    -webkit-box-shadow: 1px 1px 21px 6px rgba(0,0,0,0.16);-moz-box-shadow: 1px 1px 21px 6px rgba(0,0,0,0.16);box-shadow: 1px 1px 21px 6px rgba(0,0,0,0.16);
    border: none;
    color: #5B5E62;
  `};
  &:after{
    display: none;
    content: "";
    position: absolute;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);
    
    width: 100px;
    height: 100px;
    margin-top: -50px;
    margin-left: -50px;
    
    /* Center the ripple */
    top: 50%;
    left: 50%;
    animation: ${ripple} 1s;
    opacity: 0;
  }
  &:focus:not(:active)::after{
    display: block;
  }
`

Button.defaultProps = {
  fullWidth: true
}

Button.propTypes = {
  color: PropTypes.string,
  handleClick: PropTypes.func,
  outline: PropTypes.bool,
  loading: PropTypes.bool
}

export default Button