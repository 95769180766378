import React from 'react'
import EditSuggestion from './EditSuggestion';

class EditSuggestionContainer extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      id: props.selectedSuggestion._id || null,
      title: props.selectedSuggestion.title || '',
      description: props.selectedSuggestion.description || '',
      materials: props.selectedSuggestion.materials || []
    }
  }

  handleOnChange = (e) =>{
    this.setState({
      [e.target.name] : e.target.value
    })
  }

  handleMaterialChange = (value, field, id) => {
    const {materials} = this.state
    this.setState({
      materials: materials.map(m =>m._id === id ? {
        ...m,
        [field]: value 
      }: m)
    })
  }

  handleAddMaterial = () => {
    const { materials } = this.state
    materials.push({
      _id: '_' + Math.random().toString(36).substr(2, 9),
    })

    this.setState({
      materials
    })
  }

  handleRemoveMaterial = (id) => {
    const {materials} = this.state
    const filteredMaterials = materials.filter( m => m._id !== id)
    this.setState({
      materials: filteredMaterials
    })
  }

  render(){
    const { id, title, description, materials} = this.state
    return(
      <EditSuggestion
        id={id} 
        axisId={this.props.axisId}
        title={title}
        description={description}
        materials={materials}
        onChange={this.handleOnChange}
        handleMaterialChange={this.handleMaterialChange}
        addMaterial={this.handleAddMaterial}
        removeMaterial={this.handleRemoveMaterial}
        handleSubmit={this.handleSubmit}
        onCloseModal={this.props.onCloseModal}
      />
    )
  }
}

EditSuggestionContainer.defaultProps = {
  selectedSuggestion: {}
}

export default EditSuggestionContainer