import { gql } from 'apollo-boost'

export const UPDATE_GAME = gql`
  mutation UpdateGame($_id: String!, $name: String, $lang: String, $palette: String, $logo: String, $description: String, $isShow: Boolean, $title: String) {
    updateGame(_id: $_id, name: $name, lang: $lang, palette: $palette, logo: $logo, description: $description, isShow: $isShow, title: $title) {
      _id
      name
      lang
      palette
      logo
      description
      title
      isShow
    }
  }
`

export const UPLOAD_FILE = gql`
  mutation SingleUpload($file: Upload!){
    singleUpload(file: $file){
      filename
      mimetype
      encoding
    }
  }
`

export const UPLOAD_FILE_STREAM = gql`
  mutation SingleUploadStream($file: Upload!){
    singleUploadStream(file: $file){
      filename
      mimetype
      encoding
    }
  }
`
