import React from "react";
import styled from "styled-components";

function Icon({ color, width, height, padding }) {
  return (
    <FlagFilled color={color} width={width} height={height} padding={padding}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.778 0.085C14.8462 0.130637 14.9022 0.192369 14.9409 0.264746C14.9796 0.337123 14.9999 0.417918 15 0.5V8C15 8.09985 14.9701 8.19741 14.9141 8.28011C14.8581 8.36281 14.7787 8.42685 14.686 8.464L14.5 8L14.686 8.464L14.683 8.465L14.677 8.468L14.654 8.477C14.5225 8.52925 14.3902 8.57926 14.257 8.627C13.993 8.722 13.626 8.85 13.21 8.977C12.394 9.229 11.331 9.5 10.5 9.5C9.653 9.5 8.952 9.22 8.342 8.975L8.314 8.965C7.68 8.71 7.14 8.5 6.5 8.5C5.8 8.5 4.862 8.73 4.063 8.977C3.70526 9.08849 3.35078 9.21021 3 9.342V15.5C3 15.6326 2.94732 15.7598 2.85355 15.8536C2.75979 15.9473 2.63261 16 2.5 16C2.36739 16 2.24021 15.9473 2.14645 15.8536C2.05268 15.7598 2 15.6326 2 15.5V0.5C2 0.367392 2.05268 0.240215 2.14645 0.146447C2.24021 0.0526784 2.36739 0 2.5 0C2.63261 0 2.75979 0.0526784 2.85355 0.146447C2.94732 0.240215 3 0.367392 3 0.5V0.782C3.226 0.703 3.496 0.612 3.79 0.522C4.606 0.272 5.67 0 6.5 0C7.34 0 8.024 0.277 8.621 0.519L8.664 0.537C9.286 0.788 9.828 1 10.5 1C11.2 1 12.138 0.77 12.937 0.523C13.3923 0.380812 13.8423 0.22204 14.286 0.047L14.305 0.04L14.309 0.038H14.31L14.778 0.085ZM14 1.221C13.78 1.299 13.52 1.388 13.234 1.476C12.424 1.728 11.362 1.999 10.5 1.999C9.614 1.999 8.908 1.713 8.297 1.465L8.289 1.462C7.662 1.21 7.139 1 6.5 1C5.831 1 4.894 1.229 4.085 1.478C3.72011 1.59058 3.35831 1.71296 3 1.845V8.278C3.22 8.2 3.48 8.111 3.766 8.023C4.576 7.77 5.638 7.5 6.5 7.5C7.347 7.5 8.048 7.78 8.658 8.025L8.686 8.035C9.32 8.29 9.86 8.5 10.5 8.5C11.168 8.5 12.106 8.271 12.915 8.022C13.2799 7.90941 13.6417 7.78703 14 7.655V1.222V1.221Z"
          fill={color}
        />
      </svg>
    </FlagFilled>
  );
}

const FlagFilled = styled.span`
  fill: ${(props) => props.color || props.theme.primary || "white"};
  width: ${(props) => props.width || "16px"};
  height: ${(props) => props.height || "16px"};
  height: ${(props) => props.padding || "18px"};
`;

export default Icon;
