import React, {useState} from 'react'
import styled from 'styled-components'
import SuggestionCard from '../../../components/SuggestionCard'
import Button from '../../../components/Button'
import Modal from '../../../components/Modals'
import EditSuggestion from '../../../components/EditSuggestion'
import CreateSuggestion from '../../../components/CreateSuggestion'
import t from '../../../utils/i18n'

const Suggestions = ({suggestions, selectedAxis}) => {
  const [{isOpen, selectedSuggestion}, onModalOpen] = useState(false, {})

  return(
    <Wrapper>
      {isOpen &&(
        <Modal onCloseModal={() => onModalOpen(false)}>
          <EditSuggestion selectedAxis={selectedAxis} selectedSuggestion={selectedSuggestion} onCloseModal={() => onModalOpen(false)} />
        </Modal>
      )}
      {suggestions.map( (data, key) => (
        <CardWrapper key={data._id}>
          <SuggestionCard 
            data={data} 
            handleShowSuggestion={() => onModalOpen({isOpen: true, selectedSuggestion:{...data}})}
          />
        </CardWrapper>
      ))}
      <ButtonContainer>
        <CreateSuggestion axisId={selectedAxis._id}>
          <Button>
            {t('game.questions.suggestions.create')}
          </Button>
        </CreateSuggestion>
      </ButtonContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`

`
const ButtonContainer = styled.div`
  position: fixed;
  right: 15px;
  bottom: 10px;
  width: 270px;
`

const CardWrapper = styled.div`
  max-width: 48rem;
  margin-bottom: 2rem;
`

export default Suggestions