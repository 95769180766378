import { connect} from 'react-redux' 

import * as actions from '../actions'
import OrganizationContainer from '../components/OrganizationContainer'

import * as modalActions from '../../modals/actions'

const mapStateToProps = state => ({
  organizations: state.organizations.list
})

const mapDispatchToProps = dispatch => ({
  onMount: () => dispatch(actions.fetchAll()),
  onInviteOrganization: () => dispatch({
    type: modalActions.openModal,
    payload: {
    type: 'inviteOrganization'
    }
  })
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationContainer)