import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom' 
import img from './assets/not-found.png'
import Button from '../Button'
import t from '../../utils/i18n'

const NotFound = () => (
  <Wrapper>
    <Img src={img} alt="Not found" />
    <Heading>{t('not_found.message')}</Heading>
    <StyledLink to='/'>
      <Button>{t('not_found.call_to_action')}</Button>
    </StyledLink>
  </Wrapper>
)

const Wrapper = styled.div`
  height: calc(100vh - 35vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Img = styled.img`
  height: 200px;
`

const Heading = styled.h3`
  font-size: 2rem;
  color: #8E8E93;
  font-family: 'Roboto', sans-serif;
`

const StyledLink = styled(NavLink)``

export default NotFound