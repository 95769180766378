import React from 'react'
import Request from './Request'

class RequestContainer extends React.Component{
  componentDidMount(){
    this.props.onMount()
  }

  handleEditOrganization = (data) => {
    const organizationData = {
      ...data,
      active: true
    }
    this.props.editOrganization(organizationData)
  }

  render(){
    const { requests, status } = this.props
    return(
      <Request data={requests} status={status} editOrganization={this.handleEditOrganization} />
    )
  }
}

export default RequestContainer