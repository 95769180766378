import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import t from "../../utils/i18n";
import AxisSelector from "./components/AxisSelector";
import SuggestionsCount from "./components/SuggestionsCount";
import AchievementsCount from "./components/AchievementsCount";
import Award from "../../components/Icons/Award";
import Sign from "../../components/Icons/Sign";
import Flag from "../../components/Icons/Flag";
import Arrow from "../../components/Icons/ArrowClockWise";
import BroadCard from "../../components/BroadCard";
import Title from "../Title";
import SadFace from "../../components/Icons/SadFace";
import ComparisonProgressBar from "./components/ComparisonProgressBar";
import themes from "../../utils/themes";
// import { findMatchingAxis } from "./findMatchingAxis";
import BigLoader from "../BigLoader";
// import { dataAxisInMetrics } from "./dataAxisInMetrics";
import Card from "../Card";
import CardsContainer from "../CardsContainer";
// import calculateAxiMostCompleted from "../../utils/calculateAxiMostCompleted";
import calculateAxesInMetricsCards from "../../utils/calculateAxesInMetricsCards";
// import { mostAndLessCompletedByAxe } from "./mostAndLessCompletedByAxe.js";
import { calculateUpdate } from "../../utils/calculateUpdate";
import calculateAchievement from "../../utils/calculateAchievement";
import { findMostAndLeastCompletedAxis } from "../../utils/calculateMostAndLessCompletedAxis";

const GET_AXIS = gql`
  query getAxes($_id: String) {
    game(_id: $_id) {
      _id
      axes {
        _id
        name
        updatedAt
      }
    }
  }
`;
const GET_PROGRESS = gql`
  query getProgress($_id: String) {
    users(game: $_id) {
      progress {
        axis {
          _id
        }
        axisComplete
      }
    }
  }
`;
const GET_USERS_RANKING = gql`
query usersRanking($game: String) {
  usersRanking(game: $game) {
    axesComplete
    axesNotStarted
    axesInProgress
    achievementsByAxis {
      axisName
      count
     }
  }
}
`
const GET_METRICS = gql`
  query getMetrics {
    axisTopSuggestions {
      axis {
        _id
      }
      count
      suggestion {
        _id
        title
        axis {
          _id
        }
      }
    }
    axisTopAchievements {
      _id
      title
      answer
      wins
      question {
        content
        axis {
          _id
        }
      }
    }
  }
`;



const AxesMetrics = () => {
  const [selectedAxis, handleAxis] = useState(null);

  const axesRes = useQuery(GET_AXIS, {
    variables: {
      _id: process.env.REACT_APP_ORGANIZATION_ID,
    },
  });

  const metricsProgress = useQuery(GET_PROGRESS, {
    variables: {
      // _id: "6504ae33823afc1d281b2172",
      _id: process.env.REACT_APP_ORGANIZATION_ID,
    },
  });
  const userStatus = useQuery(GET_USERS_RANKING, {
    variables: {
      game: process.env.REACT_APP_ORGANIZATION_ID,
    },
  });

  const metricsRes = useQuery(GET_METRICS);
  const axes = axesRes.data && axesRes.data.game.axes;
  const axesUpdateAt = axesRes?.data?.game;
  const usersAxes = userStatus?.data?.usersRanking;


  const axisMoreCompleted = t("axes.completed");
  const axisAchievements = t("axes.achievements");
  const axisShorter = t("axes.shorter");
  const selection = t("axes.selection");
  const achievement = t("axes.achievement");
  const timesCompleted = t("axes.times_completed");
  const incomplete = t("axes.incompleted");
  const lastUpdate = t("axes.last_update");

  const achievements = metricsRes?.data?.axisTopAchievements || [];
  const {completedAndIncompleteAxes} = calculateAxesInMetricsCards(usersAxes, axes);
  const dataInCards = findMostAndLeastCompletedAxis(completedAndIncompleteAxes)
  const findUpdateByAxes = axesRes?.data?.game?.axes.find((ax) => ax._id === selectedAxis)
  const parsedDataUpdatedAt = new Date(Number(findUpdateByAxes?.updatedAt))
  const dateFormatted = calculateUpdate(parsedDataUpdatedAt)
  const [selectAxis, setSelectAxis] = useState('');
  const dataByAxis = completedAndIncompleteAxes?.[selectAxis || ''];
  const dataAchievements = calculateAchievement(usersAxes)

  return (
    <div>
      {!Boolean(dataAchievements) ? (
        <LoaderWrapper>
          <BigLoader />
        </LoaderWrapper>
      ) : (
      <>
      <CardsContainer>
            <Card
              title={dataInCards?.mostCompletedAxis}
              icon={ <Flag width={"20px"} />}
              description={axisMoreCompleted}
            />

            <Card
              title={dataAchievements ?? '-'}
              icon={  <Award width={"20px"}  padding={'0px 0px 20px 0px'}/>}
              description={axisAchievements}
            />

            <Card
              title={dataInCards?.leastCompletedAxis}
              icon={ <SadFace width={"20px"}  padding={'5px 0px 20px 0px'}/>}
              description={axisShorter}
            />

      </CardsContainer>

          <AxesSection>
            <TextSection>{selection}</TextSection>
            {axesRes.data && (
              <div>
                <AxisSelector
                  axes={axesRes.data.game.axes && axesRes.data.game.axes}
                  selected={selectedAxis}
                  setSelectAxis={setSelectAxis}
                  onChange={(axis) => {
                    handleAxis(axis);
                  }}
                />
              </div>
            )}
          </AxesSection>
          <InfoCard>
            <Item>
              <Arrow width={"20px"} />
              <p>
                {lastUpdate}: {dateFormatted ?? '-'}
              </p>
            </Item>
            <Item>
              <Award width={"20px"} padding={'0px 0px 20px 0px'}/>
              <p>{achievement}</p>
              <div>
                {axesRes.data && metricsRes.data && (
                  <div>
                    <ComparisonProgressBar
                      selectedAxis={selectedAxis}
                      axes={axesRes.data.game.axes || []}
                      achievements={metricsRes.data.axisTopAchievements || []}
                    />
                  </div>
                )}
              </div>
            </Item>
            <Item>
              <Flag width={"20px"} />
              <p>
                {timesCompleted}: {dataByAxis?.completed}
              </p>
            </Item>
            <Item>
              <Sign width={"20px"} />
              {/* <p>{incompleted}: {usersWithFalseAxisComplete}</p> */}
              <p>
                {incomplete}:  {dataByAxis?.incompleted}
              </p>
            </Item>
          </InfoCard>
          <SuggestionsAchievements>
            <SuggestionsCount
              selectedAxis={selectedAxis}
              suggestions={
                metricsRes.data && metricsRes.data.axisTopSuggestions
              }
            />

            <AchievementsCount
              selectedAxis={selectedAxis}
              achievements={
                metricsRes.data && metricsRes.data.axisTopAchievements
              }
            />
          </SuggestionsAchievements>
        
      
        
        </>
      )}
    </div>
  );
};

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 6rem;
`;

const Cards = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #1f2937;
  font-size: ${(props) => props.theme.font_size.small};
  font-weight: 600;
`;
const SuggestionsAchievements = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr; 
  align-items: baseline;
  justify-content: initial
  gap: 20px;
`;

const AxesSection = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 1rem 0;
`;
const TextSection = styled.p`
  font-weight: 700;
`;

const InfoCard = styled.div`
  margin: 2rem 0;
  background: #f9fafb;
  //  border-radius: ${(props) => props.theme.border_radius.rounded_md};
  border-radius: 20px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  padding: 10px 20px;
`;

export default AxesMetrics;
