import { combineReducers } from 'redux'

import auth from '../modules/auth/index'
import game from '../modules/game/index'
import modals from '../modules/modals/index'
import organizations from '../modules/organization/index'
import overview from '../modules/overview/index'
import profile from '../modules/profile/index'

export default combineReducers({
  [auth.constants.NAMESPACE]: auth.reducer,
  [game.constants.NAMESPACE]: game.reducer,
  [modals.constants.NAMESPACE]: modals.reducer,
  [organizations.constants.NAMESPACE]: organizations.reducer,
  [overview.constants.NAMESPACE] : overview.reducer,
  [profile.constants.NAMESPACE]: profile.reducer,
})