export const NAMESPACE = 'organizations'

export const INNER_PATHS = [
  {url:'list', name: 'organizations.list.title'},
  {url:'requests', name: 'organizations.requests.title'},
  //{url:'invites', name: 'organizations.invites.title'},
]

export const ORGANIZATION_LIST = [
  { 
    name: 'Wingu', 
    type: 'Non profit',
    level: '1',
    img: 'https://store.playstation.com/store/api/chihiro/00_09_000/container/AR/es/99/UP2538-CUSA05620_00-AV00000000000101//image?_version=00_09_000&platform=chihiro&w=720&h=720&bg_color=000000&opacity=100',
    country: 'argentina',
    email: 'email@nadanada.com',
    created_at: 'Diciembre 2016',
    last_played: '24 de abril 2018',
    users_amount: 30,
    merits: [
      { name: 'New merit' },
      { name: 'New merit' },
      { name: 'New merit' },
      { name: 'New merit' },
      { name: 'New merit' },
      { name: 'New merit' },
      { name: 'New merit' },
    ]
  },
  { 
    name: 'Wingu', 
    type: 'Non profit',
    level: '1',
    img: 'https://store.playstation.com/store/api/chihiro/00_09_000/container/AR/es/99/UP2538-CUSA05620_00-AV00000000000101//image?_version=00_09_000&platform=chihiro&w=720&h=720&bg_color=000000&opacity=100',
    country: 'argentina',
    email: 'email@nadanada.com',
    created_at: 'Diciembre 2016',
    last_played: '24 de abril 2018',
    users_amount: 30,
    merits: [
      { name: 'New merit' },
      { name: 'New merit' },
      { name: 'New merit' },
      { name: 'New merit' },
      { name: 'New merit' },
      { name: 'New merit' },
      { name: 'New merit' },
    ]
  },
  { 
    name: 'Wingu', 
    type: 'Non profit',
    level: '1',
    img: 'https://store.playstation.com/store/api/chihiro/00_09_000/container/AR/es/99/UP2538-CUSA05620_00-AV00000000000101//image?_version=00_09_000&platform=chihiro&w=720&h=720&bg_color=000000&opacity=100',
    country: 'argentina',
    email: 'email@nadanada.com',
    created_at: 'Diciembre 2016',
    last_played: '24 de abril 2018',
    users_amount: 30,
    merits: [
      { name: 'New merit' },
      { name: 'New merit' },
      { name: 'New merit' },
      { name: 'New merit' },
      { name: 'New merit' },
      { name: 'New merit' },
      { name: 'New merit' },
    ]
  },
  { 
    name: 'Wingu', 
    type: 'Non profit',
    level: '1',
    img: 'https://store.playstation.com/store/api/chihiro/00_09_000/container/AR/es/99/UP2538-CUSA05620_00-AV00000000000101//image?_version=00_09_000&platform=chihiro&w=720&h=720&bg_color=000000&opacity=100',
    country: 'argentina',
    email: 'email@nadanada.com',
    created_at: 'Diciembre 2016',
    last_played: '24 de abril 2018',
    users_amount: 30,
    merits: [
      { name: 'New merit' },
      { name: 'New merit' },
      { name: 'New merit' },
      { name: 'New merit' },
      { name: 'New merit' },
      { name: 'New merit' },
      { name: 'New merit' },
    ]
  },
  { 
    name: 'Wingu', 
    type: 'Non profit',
    level: '1',
    img: 'https://store.playstation.com/store/api/chihiro/00_09_000/container/AR/es/99/UP2538-CUSA05620_00-AV00000000000101//image?_version=00_09_000&platform=chihiro&w=720&h=720&bg_color=000000&opacity=100',
    country: 'argentina',
    email: 'email@nadanada.com',
    created_at: 'Diciembre 2016',
    last_played: '24 de abril 2018',
    users_amount: 30,
    merits: [
      { name: 'New merit' },
      { name: 'New merit' },
      { name: 'New merit' },
      { name: 'New merit' },
      { name: 'New merit' },
      { name: 'New merit' },
      { name: 'New merit' },
    ]
  },
  { 
    name: 'Wingu', 
    type: 'Non profit',
    level: '1',
    img: 'https://store.playstation.com/store/api/chihiro/00_09_000/container/AR/es/99/UP2538-CUSA05620_00-AV00000000000101//image?_version=00_09_000&platform=chihiro&w=720&h=720&bg_color=000000&opacity=100',
    country: 'argentina',
    email: 'email@nadanada.com',
    created_at: 'Diciembre 2016',
    last_played: '24 de abril 2018',
    users_amount: 30,
    merits: [
      { name: 'New merit' },
      { name: 'New merit' },
      { name: 'New merit' },
      { name: 'New merit' },
      { name: 'New merit' },
      { name: 'New merit' },
      { name: 'New merit' },
    ]
  },
  { 
    name: 'Wingu', 
    type: 'Non profit',
    level: '1',
    img: 'https://store.playstation.com/store/api/chihiro/00_09_000/container/AR/es/99/UP2538-CUSA05620_00-AV00000000000101//image?_version=00_09_000&platform=chihiro&w=720&h=720&bg_color=000000&opacity=100',
    country: 'argentina',
    email: 'email@nadanada.com',
    created_at: 'Diciembre 2016',
    last_played: '24 de abril 2018',
    users_amount: 30,
    merits: [
      { name: 'New merit' },
      { name: 'New merit' },
      { name: 'New merit' },
      { name: 'New merit' },
      { name: 'New merit' },
      { name: 'New merit' },
      { name: 'New merit' },
    ]
  },
  { 
    name: 'Wingu', 
    type: 'Non profit',
    level: '1',
    img: 'https://store.playstation.com/store/api/chihiro/00_09_000/container/AR/es/99/UP2538-CUSA05620_00-AV00000000000101//image?_version=00_09_000&platform=chihiro&w=720&h=720&bg_color=000000&opacity=100',
    country: 'argentina',
    email: 'email@nadanada.com',
    created_at: 'Diciembre 2016',
    last_played: '24 de abril 2018',
    users_amount: 30,
    merits: [
      { name: 'New merit' },
      { name: 'New merit' },
      { name: 'New merit' },
      { name: 'New merit' },
      { name: 'New merit' },
      { name: 'New merit' },
      { name: 'New merit' },
    ]
  },
  
]