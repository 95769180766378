export const findCountryWithMostOrganizations = (organizations) => {
    // contar org por pais
    const countryCount = organizations.reduce((acc, item) => {
      const country = item.location;
      if (country) {
        acc[country] = (acc[country] || 0) + 1;
      }
      return acc;
    }, {});
  
    // pais con mas org
    let mostOrganizationsCountry = "";
    let mostOrganizationsCount = 0;
  
    for (const country in countryCount) {
      if (countryCount[country] > mostOrganizationsCount) {
        mostOrganizationsCountry = country;
        mostOrganizationsCount = countryCount[country];
      }
    }

    return mostOrganizationsCountry;

  };