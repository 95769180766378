import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks' 
import ConfirmDeleteAxis from '../ConfirmDeleteAxis'

const DELETE_AXIS = gql`
  mutation DeleteAxis($_id: String!) {
    deleteAxis(_id: $_id)
  }
`
const UPDATE_AXIS = gql`
  mutation UpdateAxis($_id: String!, $name: String){
    updateAxis(_id: $_id, name: $name)
  }
`

const AxisCard = ({ axis, handleClick, ...props }) => {

  const [isOpen, onOpenModal] = useState(false)
  const [title, setTitle] = useState(axis.name)
  const [deleteAxis] = useMutation(DELETE_AXIS, {
    refetchQueries: ['game'],
  })
  const [updateAxis] = useMutation(UPDATE_AXIS, {
    refetchQueries: ['game']
  })
  

  return (
    <>
      <ExternalWrapper>
        <AxisWrapper onClick={handleClick} {...props}>
          <AxisTitle
            active={props.active} 
            size={title.length + 1}
            value={title} 
            onChange={(ev) => setTitle(ev.target.value)} 
            onBlur={() => updateAxis({ variables: {
              _id: axis._id,
              name: title
            }})}
            />
        </AxisWrapper>
        <DeleteButton onClick={() => onOpenModal(!isOpen) }>-</DeleteButton>

      </ExternalWrapper>
      {isOpen &&<ConfirmDeleteAxis closeModal={onOpenModal} name={axis.name} id={axis._id} deleteAxis={deleteAxis}/>}
    </>
  )
}

const AxisWrapper = styled.div`
  background-color: white;
  background: ${props => props.active ? props.theme.primary : "white"};
  border: 1px solid ${props => props.theme.primary};
  box-sizing: border-box;
  border-radius: 16px;
  cursor: pointer;
  padding: 0.8rem;
  text-align: center;
  
`

const AxisTitle = styled.input`
  // font-family: sans-serif;
  // font-size: 1.2rem;
  // font-weight: bold;
  font-size: ${props => props.theme.font_size.medium};
  border: none;
  background: transparent;
  outline: none;
  box-sizing: border-box;
  // letter-spacing: -2px; 
  text-align: center;
  color: ${props => props.active ? 'white' : 'black'};
`

const DeleteButton = styled.button`
  align-items: center;
  // bottom: -12px;
  background-color: #fd4771;
  border: none;
  box-sizing: border-box;
  border-radius: 100%;
  color: white;
  cursor: pointer;
  opacity: 0;
  font-size: 1rem;
  font-weight: bold;
  height: 40px;
  justify-content: center;
  position: absolute;
  right: -12px;
  top: 30px;
  width: 40px;
  transition: opacity 0.1s;
`

const ExternalWrapper = styled.div`
  position: relative;
  margin-bottom: 2rem;
  margin-right: 2rem;
  &:hover ${DeleteButton} {
    opacity: 1;
  }
`

AxisCard.propTypes = {
  axis: PropTypes.object.isRequired,
  handleClick: PropTypes.func,
  active: PropTypes.bool
}

export default AxisCard
