import React from 'react'
import styled, { withTheme } from 'styled-components'
import { lighten, darken } from 'polished'
import { HorizontalBar } from 'react-chartjs-2'
import t from '../../../../utils/i18n'


const BarChart = ({ theme, bars }) => {
  
  const options = {
    legend: { 
      display: false
    },
    scales: {
      yAxes: [{ 
        display: true,
          gridLines: {
            display: false
          },
          ticks: {
            fontColor: '#8D9AA9',
            fontFamily: 'Helvetica',
            fontSize: 14
          }
      }],
      xAxes: [{
        gridLines: {
          border: [0, 0],
          color: '#E9E9E9'
        },
        ticks: {
          beginAtZero: true,
          fontColor: '#D1D1D6',
          fontFamily: 'Helvetica',
          fontSize: 14
        }
      }]
    }
  }
  
  const usersWithInstitution = bars.filter((obj) => obj.name !== 'none').reduce((ac, currentValue) => ac + currentValue.counter, 0)   
  const data = {
     labels: [t('overview.metrics.axis.together'), t('overview.metrics.axis.alone')], 
     datasets: [{
      data: [usersWithInstitution, bars.find((obj) => obj.name === 'none').counter],
      backgroundColor: theme.primary
    }]
  }

  return (
    <Wrapper>
      <Title>{t('overview.metrics.axis.clasification')}</Title>
      <BarWrapper>
        <HorizontalBar
          // width={500} 
          height={100}
          options={options}
          data={data}
        />
      </BarWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  border: 1px solid #E5E5E5;
  width: 100%;
  padding: 20px;
  border-radius: 25px;
  margin-right: 5px;
`

const Title = styled.span`
  color: #1F2937;
  font-size: 1rem;
  font-weight: 400;
`

const BarWrapper = styled.div`
  margin-top: 2.8rem;
`

export default withTheme(BarChart)