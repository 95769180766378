import { connect} from 'react-redux' 

import * as actions from '../actions'
import RequestContainer from '../components/RequestContainer'

const mapStateToProps = state => ({
  requests: state.organizations.requests,
  status: state.organizations.status
})

const mapDispatchToProps = dispatch => ({
  onMount: () => dispatch(actions.fetchRequests()),
  editOrganization: ( data ) => dispatch(actions.editOrganization({ data }))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestContainer)