export const findMostAndLeastCompletedAxis = (completedAndIncompleteAxes) => {
    let mostCompletedAxis = null;
    let leastCompletedAxis = null;
    let maxCompletedValue = -1;
    let minCompletedValue = Infinity;
  
    Object.keys(completedAndIncompleteAxes ?? {}).forEach(key => {
      const completedValue = completedAndIncompleteAxes[key].completed;
  
      // Para el más completado
      if (completedValue > maxCompletedValue) {
        maxCompletedValue = completedValue;
        mostCompletedAxis = key;
      }
  
      // Para el menos completado
      if (completedValue < minCompletedValue) {
        minCompletedValue = completedValue;
        leastCompletedAxis = key;
      }
    });
    return {
      mostCompletedAxis,
      leastCompletedAxis,
    };
  };
  