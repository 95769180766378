import React from 'react'
import { connect } from 'react-redux'
import { useQuery } from '@apollo/react-hooks'
import styled from 'styled-components' 
import Navbar from '../../../components/Navbar'
import Modal from '../../modals/components'
import { GET_GAME } from '../../../queries/Game/getGame'
import { unauthenticated } from '../../../utils/helpers'

const MainLayout = ({ children, modalOpen, ...props }) => {
  const { loading, error, data } = useQuery(GET_GAME, {
      variables: {
        _id: process.env.REACT_APP_ORGANIZATION_ID
      },
      onError: ({ networkError }) => {
        if (networkError.statusCode === 400 || networkError.statusCode === 400) {
          unauthenticated()       
        }
      }
    })
  

  if (loading || error) {
    return null
  }

  return (
    <Wrapper>
      <Navbar title={data.game.name} imgUrl={data.game.logo} />
      <ContentWrapper modalOpen={modalOpen}>
        <ChildrenWrapper>
          {children}
        </ChildrenWrapper>
      </ContentWrapper>
      <Modal />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
`

const ContentWrapper = styled.div`
  height: 100vh;
  overflow: ${props => props.modalOpen ? 'hidden' : 'scroll'};
  width: 82%;
`

const ChildrenWrapper = styled.div`
  padding: 0 2rem;
`
const mapStateToProps = state => ({
  name: state.auth
})

export default connect(mapStateToProps)(MainLayout)