import React from 'react'
import styled, { keyframes } from 'styled-components'

const Spinner = ({primary}) => {
  return (
    <Wrapper>
      <Content primary={primary}>
        <div></div>
        <div></div>
      </Content>
    </Wrapper>
  )
}

const ripple = keyframes`
  0% {
    top: 18px;
    left: 18px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 36px;
    height: 36px;
    opacity: 0;
  }
`

const Content = styled.div`
  display: inline-block;
  position: relative;
  > div {
    position: absolute;
    border: 2px solid ${props => props.primary ? props.theme.primary : "#FFF"};
    opacity: 1;
    border-radius: 50%;
    animation: ${ripple} 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  > div:nth-child(2) {
    animation-delay: -0.5s;
  }
`

const Wrapper = styled.div`
  position: absolute;
  top: -36px;
  left: calc(50% - 24px);
`

Spinner.defaultProps = {
  primary: false
}

export default Spinner