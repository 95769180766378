import React from 'react'
import Auth from './Auth'

class AuthContainer extends React.Component{

  handleAuth = (data) => {
    const { loginUser } = this.props
    const { email, password } = data
    loginUser(email, password)
  }

  render(){
    return(
      <Auth
        {...this.props}
        handleAuth={this.handleAuth}
      />
    )
  }
}

export default AuthContainer