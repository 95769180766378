import { withFormik } from 'formik'
import * as Yup from 'yup'
import i18next from 'i18next'


export const LoginFormEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .required(() => i18next.t('auth.signin.email_required')),
    password: Yup.string()
      .required(() => i18next.t('auth.signin.password_required')),
  }),
  mapPropsToValues: props => ({
    email: '',
    password: '',
    handleAuth: props.handleAuth 
  }),
  handleSubmit: (values, { setSubmitting}) => {
    const data = {
      email: values.email,
      password: values.password
    }
    
    values.handleAuth(data)

    setSubmitting(false)
  },
  displayName: 'LoginForm'
})