import React, { Component } from "react";
import styled from "styled-components";
import Button from "../Button";
import _ from "lodash";
import Answer from "./components/Answer";
import { normalizeAnswers } from "./helpers";

class EditQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.question.content,
      answers: props.question.answers,
      loading: props.loading,
      achievements: [],
      achievementsWithError: false,
    };
    this.answersEndRef = React.createRef();
  }

  handleAnswer = (id, name, value) => {
    this.setState({
      answers: this.state.answers.map((a) =>
        a._id === id
          ? {
              ...a,
              [name]: value,
            }
          : a
      ),
    });
  };

  removeAnswer = (id) => {
    this.setState({
      answers: this.state.answers.filter((a) => a._id !== id),
    });
  };

  addAnswer = () => {
    const newAnswers = this.state.answers;

    newAnswers.push({
      _id: "_" + Math.random().toString(36).substr(2, 9),
      content: "",
      next: "",
    });

    this.setState(
      {
        answers: newAnswers,
      },
      () => {
        this.answersEndRef.current.scrollIntoView({ behavior: "smooth" });
      }
    );
  };

  handleAchievements = (newAchievement, answerId) => {
    let newAchievements = this.state.achievements.filter((obj) => {
      return obj.answer !== answerId;
    });

    if (newAchievement) {
      newAchievement["axis"] = this.props.axisId;
      newAchievements.push(newAchievement);
    }

    this.setState(
      {
        achievements: newAchievements,
      },
      () => {
        this.setState({
          achievementsWithError: !!this.state.achievements.find(
            (achievement) => {
              return achievement.description == "" || achievement.title == "";
            }
          ),
        });
      }
    );
  };

  handleSuggestion = (answerId, suggestionId) => {
    this.setState({
      answers: this.state.answers.map((obj) => {
        return {
          ...obj,
          suggestion: obj._id === answerId ? suggestionId : obj.suggestion,
        };
      }),
    });
  };

  submit = () => {
    if (this.state.achievementsWithError) {
      return;
    }
    this.props.updateQuestion({
      variables: {
        _id: this.props.question._id,
        content: this.state.title,
        answers: normalizeAnswers(this.state.answers),
        achievements: this.state.achievements,
      },
    });
  };

  render() {
    return (
      <Wrapper>
        <Title
          type="text"
          value={this.state.title}
          onChange={(e) => {
            this.setState({ title: e.target.value });
          }}
        />
        {this.state.answers &&
          this.state.answers.map((ans, key) => (
            <div key={ans._id}>
              <Answer
                translations={this.props.translations}
                itemNumber={key + 1}
                next={ans.next}
                value={ans.content}
                axisId={this.props.axisId}
                achievement={ans.achievement}
                addAnswer={this.addAnswer}
                suggestion={ans.suggestion}
                suggestions={this.props.suggestions}
                achievementsWithError={this.state.achievementsWithError}
                updateAnswer={(name, value) => {
                  this.handleAnswer(ans._id, name, value);
                }}
                removeAnswer={() => {
                  this.removeAnswer(ans._id);
                }}
                cleanAnswer={() => {
                  this.setState({
                    answers: this.state.answers.map((a) =>
                      a._id === ans._id
                        ? {
                            ...a,
                            // achievement: null,
                            deleteAch: !!(
                              !a.deleteAch &&
                              a.achievement &&
                              a.achievement._id
                            ),
                            suggestion: null,
                          }
                        : a
                    ),
                    achievements: [],
                  });
                }}
                onAchievementChange={(ach) => {
                  this.handleAchievements(
                    ach
                      ? {
                          ...ach,
                          answer: ans._id,
                        }
                      : null,
                    ans._id
                  );
                }}
                onSuggestionChange={(suggestionId) => {
                  this.handleSuggestion(ans._id, suggestionId);
                }}
                questionsList={this.props.questionsList}
              />
              {key !== this.state.answers.length - 1 && (
                <Separator>
                  <div></div>
                </Separator>
              )}
            </div>
          ))}
        <AddAnswerWrapper onClick={this.addAnswer}>
          <span>{this.props.translations.edit.answer.option}</span>
        </AddAnswerWrapper>

        <ButtonsWrapper>
          <Button onClick={this.submit} loading={this.state.loading}>
            {this.props.translations.edit.save}
          </Button>
        </ButtonsWrapper>
        <div ref={this.answersEndRef}></div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  width: 80%;
  padding: 1rem;
`;

const Title = styled.input`
  width: 100%;
  color: #3f4b63;
  padding: 1rem;
  flex: 1;
  border: none;
  border-bottom: 2px solid #d8d8d8;
  font-size: 1.2rem;
  transition: border-color 0.2s;
  outline: none;

  &:focus {
    border-bottom: 2px solid ${(props) => props.theme.primary};
  }
`;

const Separator = styled.div`
  display: flex;
  justify-content: center;

  > div {
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background: ${(props) => props.theme.primary};
  }
`;

const AddAnswerWrapper = styled.div`
  display: inline-block;
  margin: 2rem 0;
  cursor: pointer;
  color: ${(props) => props.theme.primary};
  border-bottom: 2px solid white;
  transition: border-color 0.2s;

  &:hover {
    border-bottom: 2px solid ${(props) => props.theme.primary};
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default EditQuestion;
