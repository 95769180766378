import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const ErrorMessage = ({message}) => (
  <Wrapper>
    <ErrorLabel>{message}</ErrorLabel>
  </Wrapper>
)

const Wrapper = styled.div`
  margin-bottom: 0.75rem;
`

const ErrorLabel = styled.span`
  color: #F44336;
  font-family: Rubik,Roboto,sans-serif;
  margin-bottom: 1rem;
`


ErrorMessage.propTypes = {
  message: PropTypes.string
}

export default ErrorMessage