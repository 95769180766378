const searchItem = (values, keys, searchValues) => {
  const filteredValues = values.filter((value) => {
    let match = true; 

    for (let i = 0; i < keys.length; i++) {
      if (
        value.hasOwnProperty(keys[i]) &&
        !(value[keys[i]] || '').toLowerCase().includes(searchValues[i].toLowerCase())
      ) {
        match = false; 
        break; 
      }
    }

    return match;
  });

  return filteredValues;
};

export default searchItem;
