import React from "react";
import styled from "styled-components";

function Icon({ color, height, width, paddingLeft }) {
  return (
    <IconFilled color={color} height={height} width={width} paddingLeft={paddingLeft}>
      <svg width={width} height={height} viewBox="0 0 10 12" fill={color} xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.646039 7.14601C0.692485 7.09945 0.747661 7.0625 0.808406 7.0373C0.869151 7.01209 0.934272 6.99911 1.00004 6.99911C1.06581 6.99911 1.13093 7.01209 1.19167 7.0373C1.25242 7.0625 1.30759 7.09945 1.35404 7.14601L5.00004 10.793L8.64604 7.14601C8.73993 7.05212 8.86726 6.99938 9.00004 6.99938C9.13281 6.99938 9.26015 7.05212 9.35404 7.14601C9.44793 7.2399 9.50067 7.36723 9.50067 7.50001C9.50067 7.63278 9.44793 7.76012 9.35404 7.85401L5.35404 11.854C5.30759 11.9006 5.25242 11.9375 5.19167 11.9627C5.13093 11.9879 5.06581 12.0009 5.00004 12.0009C4.93427 12.0009 4.86915 11.9879 4.80841 11.9627C4.74766 11.9375 4.69248 11.9006 4.64604 11.854L0.646039 7.85401C0.599476 7.80756 0.562533 7.75239 0.537327 7.69164C0.51212 7.6309 0.499146 7.56578 0.499146 7.50001C0.499146 7.43424 0.51212 7.36912 0.537327 7.30838C0.562533 7.24763 0.599476 7.19245 0.646039 7.14601ZM0.646039 4.85401C0.692485 4.90057 0.747661 4.93752 0.808406 4.96272C0.869151 4.98793 0.934272 5.0009 1.00004 5.0009C1.06581 5.0009 1.13093 4.98793 1.19167 4.96272C1.25242 4.93752 1.30759 4.90057 1.35404 4.85401L5.00004 1.20701L8.64604 4.85401C8.73993 4.9479 8.86726 5.00064 9.00004 5.00064C9.13281 5.00064 9.26015 4.9479 9.35404 4.85401C9.44793 4.76012 9.50067 4.63278 9.50067 4.50001C9.50067 4.36723 9.44793 4.2399 9.35404 4.14601L5.35404 0.146009C5.30759 0.0994455 5.25242 0.0625027 5.19167 0.0372963C5.13093 0.0120898 5.06581 -0.00088501 5.00004 -0.00088501C4.93427 -0.00088501 4.86915 0.0120898 4.80841 0.0372963C4.74766 0.0625027 4.69248 0.0994455 4.64604 0.146009L0.646039 4.14601C0.599476 4.19245 0.562533 4.24763 0.537327 4.30838C0.51212 4.36912 0.499146 4.43424 0.499146 4.50001C0.499146 4.56578 0.51212 4.6309 0.537327 4.69164C0.562533 4.75239 0.599476 4.80756 0.646039 4.85401Z" fill={color}/>
        </svg>
    </IconFilled>
  );
}

const IconFilled = styled.span`
  fill: ${(props) => props.color || props.theme.primary || '#6B7280'};
  width: ${props => props.width || "14px"};
  padding: ${props => props.padding || "0 0 5px 0"};
  height: ${props => props.height || "11px"};
  padding-left: ${props => props.paddingLeft || "10px"};
`;

export default Icon;