import OrganizationContainer from './scenes'
import ListContainer from './scenes/List'
import * as constants from './constants'
import reducer from './reducers'
import sagas from './sagas'

export default {
  constants,
  reducer,
  scenes: {
    OrganizationContainer,
    ListContainer
  },
  sagas: [
    ...sagas
  ]
}