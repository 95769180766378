import React from 'react'
import styled from 'styled-components'
import { darken } from 'polished'
import { Translation } from 'react-i18next';
import t from '../../../utils/i18n'
import { LoginFormEnhancer } from '../enhancers'
import LoginInput from '../../../components/LoginInput'
import Button from '../../../components/Button'
import ErrorMessage from '../../../components/ErrorMessage'
import Background from '../../../components/Background'
import GameLogo from '../../../components/GameLogo'
import LoginFooter from '../../../components/LoginFooter'
import { Link } from 'react-router-dom'

const Auth = (props) => {
  const { 
    onChange, 
    handleAuth,
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    isSubmitting,
    error
  } = props

  return (
    <Wrapper>
      <Background />
      <FormContainer>
        <Header>
          <GameLogo />
          <Subtitle>admin</Subtitle>
        </Header>
        <Form onSubmit={handleSubmit}>
          <FormTitle>{t('auth.signin.login')}</FormTitle>
          <LoginInput 
            {...props}
            name="email" 
            label="Email" 
            placeholder={t('auth.signin.email')}
            type="text" 
            value={values.email}
            onChange={handleChange}
          />
          {errors.email &&
            touched.email && (
              <ErrorMessage message={errors.email} />
          )}
          <LoginInput 
            {...props}
            name="password" 
            label="Contraseña" 
            placeholder={t('auth.signin.pass')} 
            type="password" 
            onChange={handleChange}
            value={values.password}
            password
          />
          {errors.password &&
            touched.password && (
              <ErrorMessage message={errors.password} />
          )}
            {error &&
              <Translation>
                {
                  t => <ErrorMessage message={t('auth.signin.unauthorized')} />
                }
              </Translation>
            }
          <div>
            <Button
              color="#000000"
              loaderColor="#ffffff"
              disabled={isSubmitting}
              loading={isSubmitting}
              >
              {t('auth.signin.login')} 
            </Button>
          </div>
          <div>
            <ForgotLink>
              <StyledLink to='/forgot'>
                {t('auth.signin.forgot')}
              </StyledLink>
            </ForgotLink>
          </div>
        </Form>
        <LoginFooter />
      </FormContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  align-items: center;
  background: ${props => darken(0.15, props.theme.primary)};
  display: flex;
  flex-wrap: wrap;
  height: 100vh;
  overflow: hidden;
`

const FormContainer = styled.div`
  align-content: center;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  font-family: Rubik, Roboto, sans-serif;
  height: 100vh;
  justify-content: center;
  padding: 1rem;
  width: 30%;
  z-index: 10;

  @media(max-width: 768px) {
    width: 100vw;
  }
`

const Header = styled.header`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  padding: 0 1rem;
`

const Title = styled.h1`
  color: ${props => props.theme.primary};
  font-size: 3.25rem;
  font-weight: bold;
  margin: 0;
`

const Subtitle = styled.h2`
  color: #616161;
  font-size: 1.25rem;
  margin: 0.5rem 0 0 0.5rem;
  opacity: 0.7;
`

const FormTitle = styled.h2`
  color: ${props => props.theme.secondary};
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
  padding: 1rem 0;
  text-align: left;
`

const Logo = styled.img`
  border: 8px solid white;
  border-radius: 50%;
  box-shadow:
  rgba(0, 0, 0, 0.16) 1px 1px 21px 6px;
  width: 125px;
  height: 125px;
`


const Form = styled.form`
  width: 90%;
  margin: 1rem auto 0;
`

const ForgotLink = styled.div`  
  text-align: center;
  margin-top: 2rem;
`

const StyledLink = styled(Link)`
  color: ${props => props.theme.secondary};
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
`

export default LoginFormEnhancer(Auth)