import { call, put, takeEvery } from 'redux-saga/effects'
import { get } from '../../utils/api'
import * as actions from './actions'

export function * fetchMetricsWorker () {
  yield put (actions.fetchMetrics.start())

  const { res, error } = yield call(get, '/metrics')

  if (error) {
    return yield put(actions.fetchMetrics.failure({ error: error.data.error }))
  }

  return yield put(actions.fetchMetrics.success({ metrics: res }))
}

export function * fetchRankingWorker () {
  yield put (actions.fetchRanking.start())

  const { res, error } = yield call(get, '/organizations')

  if (error) {
    return yield put(actions.fetchRanking.failure({ error: error.data.error }))
  }
  
  return yield put(actions.fetchRanking.success({ ranking: res.organizations }))
}


export function * fetchMetricsWatcher () {
  yield takeEvery(actions.fetchMetrics.type, fetchMetricsWorker)
}

export function * fetchRankingWatcher () {
  yield takeEvery(actions.fetchRanking.type, fetchRankingWorker)
}


export default [
  fetchMetricsWatcher,
  fetchRankingWatcher
]