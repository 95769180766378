import React from 'react'
import styled from 'styled-components'
import Button from '../../../components/Button'
import OrgAvatar from '../../../components/OrgAvatar'
import OrganizationTable from './_commons/OrganizationTable'
import SkeletonTable from '../../../components/SkeletonTable';
import t from '../../../utils/i18n'

const List = ({data, status, onSelectOrganization }) => {

  if(status === 'pending'){
    return <SkeletonTable />
  } else {
    return (
      <OrganizationTable type='list'>
        {data.map( (d, key) => (
            <Row key={key}>
              <Item highlight>{key + 1}</Item>
              {/* <Item>
                <OrgAvatar name={d.name} size={45} />
              </Item> */}
              <HighlighItem>{d.name}</HighlighItem>
              <Item >{d.country}</Item>
              <StyledAnchor href={d.site} target="_blank" minWidth>{d.site}</StyledAnchor>
              <HoverItem>
                {/* <ButtonWrapper width="9rem">
                  <Button outline>{ t('organizations.list.item.message') }</Button>
                </ButtonWrapper> */}
                 <ButtonWrapper width="9rem" onClick={() => onSelectOrganization(d)}>
                  <Button>{ t('organizations.list.item.view') }</Button>
                </ButtonWrapper>
                {/* <ButtonWrapper width="9rem">
                  <Button>{ t('organizations.list.item.view') }</Button>
                </ButtonWrapper> */}
              </HoverItem>
            </Row>
          ))}
      </OrganizationTable>
    )
  }
  
}

const Item = styled.div`
  padding-right: 1rem;
  width: ${props => props.minWidth ? '9rem': ''};
  color: #AEAEB2;
`

const StyledAnchor = styled.a`
  color: #AEAEB2;
  font-weight: bold;
  text-decoration: none;
  padding-right: 1rem;

`
const HighlighItem = styled.div`
  color: #000000;
  width: 9rem;
`
const HoverItem = styled.div`
  display: none;
  margin-left: auto;
`

const ButtonWrapper = styled.div`
  padding-right: 1rem;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  height: 80px;
  border-bottom:1px solid #D8D8D8;
  &:hover{
    ${HoverItem}{
      display: flex;
    }
    ${HighlighItem}{
      font-weight: bold;
    }
  } 
`

export default List