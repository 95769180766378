import React from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'

const BigLoader = () => {
  return (
    <Loader>
      <PrimaryWrapper>
        <Primary
          animate={{ 
            top: ['5px', '35px', '5px'],
            left: ['5px', '35px', '5px'],
            height: ['60px', '0px', '60px'],
            width: ['60px', '0px', '60px']
          }}
          transition={{ repeat: Infinity, duration: 2 }}
        ></Primary>
      </PrimaryWrapper>
      <SecondaryWrapper>
        <Secondary
          animate={{ 
            top: ['35px', '5px', '35px'],
            left: ['35px', '5px', '35px'],
            height: ['0px', '60px', '0px'],
            width: ['0px', '60px', '0px']
          }}
          transition={{ repeat: Infinity, duration: 2 }}
        ></Secondary>
      </SecondaryWrapper>
    </Loader>
  )
}



const Loader = styled.div`
  display: flex;
  align-items: center;
`

const LoaderText = styled.div`
  font-size: 18px;
  padding: 0 2rem;
`

const Primary = styled(motion.div)`  
  position: absolute;
  left: 0;
  background: ${props => props.theme.secondary}
  border-radius: 50%;
`

const PrimaryWrapper = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 5px solid ${props => props.theme.primary};  
`

const Secondary = styled(motion.div)`
  position: absolute;
  left: 0;
  background: ${props => props.theme.primary}
  border-radius: 50%;
`

const SecondaryWrapper = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 5px solid ${props => props.theme.secondary};  
`

export default BigLoader