import React from 'react'
import styled from 'styled-components'
import Button from '../../../components/Button'
import { Route, Redirect } from 'react-router-dom'
import InnerNavbar from '../../../components/InnerNavbar'
import List from '../scenes/List'
import Request from '../scenes/Request'
import Invite from '../scenes/Invite'
import { INNER_PATHS } from '../constants'
import t from '../../../utils/i18n'
import Title from '../../../components/Title'

const Organization = ({match, onInviteOrganization}) => (
  <div>
    <HeaderWrapper>
      <Title fontSize={'large'} color={'black'} padding={'2rem 0 0 0'}>{ t('organizations.title') }</Title>
      {/*<ButtonWrapper>
        <Button color="#000" handleClick={onInviteOrganization}>{ t('organizations.invite_button') }</Button>
      </ButtonWrapper>*/}
    </HeaderWrapper>
    <InnerNavbar match={match} paths={INNER_PATHS} />
    <Route exact path={`${match.url}/`} render={() => (<Redirect to={`${match.url}/list`} />)} />
    <Route exact path={`${match.url}/list`}  component={List}/>
    <Route exact path={`${match.url}/requests`}  component={Request}/>
    <Route exact path={`${match.url}/invites`}  component={Invite}/>
  </div>
)

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export default Organization