import React from 'react'
import styled, { withTheme } from 'styled-components'

import { Pie } from 'react-chartjs-2'
import t from '../../../../utils/i18n'

const PieChart = (props) => {
  // const data = {
  //   labels: props.data.map((axis => axis.name )),
  //   datasets: [{
  //     data: props.data.map((axis => axis.count)),
  //     backgroundColor: props.data.map((value, i) =>  lighten(`0.${i}`, props.theme.primary))
  //   }]
  // }
  
  const options = {
    legend: {  
      'display': false,
      'position': 'right',
      'fullWidth': false,
      'reverse': false,
      'labels': {
        boxWidth: 10,
        fontColor: 'red',
        fontSize: 14,
        padding: 25,
        usePointStyle: true
      }
    },
    rotation: 45
  }

  return (
    <Wrapper>
      <Tab active>{ props.label }</Tab>
      <PieWrapper>
        <Pie 
          data={props.data}
          options={options} 
          height={100}
        />
      </PieWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  border: 1px solid #E5E5E5;
  width: 100%;
  padding: 20px;
  border-radius: 25px;
  margin-left: 5px;
`

const Tab = styled.span`
  color: #1F2937;
  margin-right: 3rem;
`

const PieWrapper = styled.div`
 margin-top: 2.8rem;
 `

export default withTheme(PieChart)