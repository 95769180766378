export const getOrgMaxUsersName = (orgUsersAchievements, organizations) => {
    let organizationWithMostUsersId = "";
    let maxUserCount = 0;

    for (const i in orgUsersAchievements) {
      const userCount = parseInt(orgUsersAchievements[i].users);
      if (userCount > maxUserCount) {
        maxUserCount = userCount;
        organizationWithMostUsersId = orgUsersAchievements[i]._id;
      }
    }

    const organizationName = organizations.filter(
      (org) => org.id === organizationWithMostUsersId
    );

    return organizationName[0]?.name 
  };