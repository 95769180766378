import Axios from 'axios'
const URL = process.env.REACT_APP_GAME_URL + '/api'
const METHODS = ['get', 'put', 'post', 'patch', 'delete']

const handleUnauthorized = response => {
  if (response.status === 401 || response.message === 'Not Authorized') {
    return window.location = '/login'
  }

  return response
}

const axios = (method, url, data, responseType) => {
  const TOKEN = localStorage.getItem('token')
  return Axios
  .create({
    baseURL: URL+ '/',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/vnd.safaritec.v1+json',
      'Authorization': `Token ${TOKEN}`,
      'X-Language': localStorage.getItem('i18nextLng'),
      'Game-id': process.env.REACT_APP_ORGANIZATION_ID
    },
  })
  .request({
    responseType: responseType || 'json',
    transformResponse: data => {
      if (responseType === 'arraybuffer') return data

      return handleUnauthorized(data)
    },
    url,
    method,
    data
  })
}

/**
 * I'm returning an array of functions for each method I defined
 * earlier in the 'METHODS' variable.
 * @type {Array}
 */
const axiosConstructor = METHODS
.map((method) =>
  /**
   * This is the function that handles the request.
   * If you want to send extra things to the Axios function that
   * handles requests this is the place it should go.
   * @param path
   * @param data
   */
    (path, data, responseType) =>
    axios(method, path, data, responseType)
    .then(res => ({ res: res.data }))
    .catch(error => ({ error: error.response }))
)

/**
 * I'm using destructuring to avoid duplicating multiple functions that do
 * the same thing for different methods.
 * Destructuring: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Destructuring_assignment
 */
export const [get, put, post, patch, del] = axiosConstructor