import organization from '../modules/organization/index'
import auth from '../modules/auth/index'
import profile from '../modules/profile/index'
import overview from '../modules/overview/index'


import { fork, all } from 'redux-saga/effects'

function startSagas (...sagas) {
  return function * rootSaga() {
    yield all(sagas.map( saga => fork(saga)))
  }
}

export default startSagas(
  ...organization.sagas,
  ...auth.sagas,
  ...profile.sagas,
  ...overview.sagas
)