import React, { useState, useEffect } from 'react'
import AxisSkeleton from '../../../components/AxisSkeletonView'
import { useQuery } from '@apollo/react-hooks' 
import { GET_GAME_AXES } from '../../../queries/Game/getGame'
import Axes from './Axes'
import styled from 'styled-components'
import OptionsWrapper from './OptionsWrapper'

const QuestionsContainer = (props) => {
  const [selectedAxis, changeSelectedAxis] = useState(null)

  const { loading, error, data } = useQuery(GET_GAME_AXES, {
    variables: {
      _id: process.env.REACT_APP_ORGANIZATION_ID
    }
  })

  useEffect( () => {
    if(data && !selectedAxis){
      const {axes} = data.game
      if(axes.length > 0){
        changeSelectedAxis(axes[0])
      }
    }
  })
  if (loading) {
    return <AxisSkeleton />
  }

  const { axes } = data.game
  return (
    <div>
      <Axes 
        changeSelectedAxis={(id) => changeSelectedAxis(id)}
        data={axes}
        selectedAxis={selectedAxis}
      />
      {selectedAxis && (
        <OptionsWrapper axes={axes} selectedAxis={selectedAxis} />
      )}
        
    </div>
  )
}

const CardWrapper = styled.div`
  padding: 2rem 0;
  background: white;
`

export default QuestionsContainer
