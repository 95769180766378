export const calculateUpdate = (date) => {

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    
    // Crear una cadena en el formato día/mes/año
    const formattedDate = `${day}/${month}/${year}`;
    
   
    return formattedDate
}
