import React from 'react'
import List from './List';

class ListContainer extends React.Component {
  componentDidMount(){
    this.props.onMount()
  }
  render(){
    const { list, status, onSelectOrganization } = this.props
    return(
      <List data={list} status={status} onSelectOrganization={onSelectOrganization}/>
    )
  }
}

export default ListContainer