import { handleActions } from 'redux-actions'
import * as actions from './actions'
import { filterRequests } from './utils'

const initialState = {
  status: 'init',
  error: '',
  list: [],
  requests: [],
  invites: []
}

const reducer = handleActions({
  [actions.fetchAll.START]: (state) => ({
    ...state,
    status: 'pending'
  }),
  [actions.fetchAll.FAILURE]: (state, action) => ({
    ...state,
    status: 'failure',
    error: action.payload.error
  }),
  [actions.fetchAll.SUCCESS]: (state, action) => ({    
    ...state,
    status: 'success',
    list: [
      ...action.payload.list
    ]
  }),
  [actions.fetchRequests.START]: (state) => ({
    ...state,
    status: 'pending'
  }),
  [actions.fetchRequests.FAILURE]: (state, action) => ({
    ...state,
    status: 'failure',
    error: action.payload.error
  }),
  [actions.fetchRequests.SUCCESS]: (state, action) => ({
    ...state,
    status: 'success',
    requests: [
      ...action.payload.requests
    ]
  }),
  [actions.fetchInvites.START]: (state) => ({
    ...state,
    status: 'pending',
  }),
  [actions.fetchInvites.FAILURE]: (state, action) => ({
    ...state,
    status: 'failure',
    error: action.payload.error
  }),
  [actions.fetchInvites.SUCCESS]: (state, action) => ({
    ...state, 
    status: 'success',
    invites: [
      ...action.payload.invites
    ]
  }),
  [actions.sendInvite.START]: (state) => ({
    ...state,
    status: 'pending'
  }),
  [actions.sendInvite.FAILURE]: (state, action) => ({
    ...state,
    status: 'failure',
    error: action.payload.error
  }),
  [actions.sendInvite.SUCCESS]: (state) => ({
    ...state,
    status: 'success',
  }),
  [actions.editOrganization.START]: (state) => ({
    ...state,
    status: 'pending'
  }),
  [actions.editOrganization.FAILURE]: (state, action) => ({
    ...state,
    status: 'failure',
    error: action.payload.error
  }),
  [actions.editOrganization.SUCCESS]: (state, action) => ({
    ...state,
    status: 'success',
    requests: filterRequests(state.requests,action.payload.organization_id )
  })
}, initialState)

export default reducer