import React, { useState } from 'react'
import styled from 'styled-components'
import {useMutation} from '@apollo/react-hooks'
import Material from '../../components/Material'
import {DELETE_SUGGESTION} from '../../queries/suggestion'
import t from '../../utils/i18n'
import ConfirmDeleteSuggestion from './components/ConfirmDeleteSuggestion'
import TrashIcon from '../Icons/Trash'
import Title from '../Title'
import themes from '../../utils/themes'

const SuggestionCard = ({data, handleShowSuggestion}) => {
  const [isOpen, toggleModal] = useState(false)
  const [deleteSuggestion] = useMutation(DELETE_SUGGESTION, {
    refetchQueries: ['game']
  })

  return(
    <>
    <Wrapper onClick={() => handleShowSuggestion()}>
      <ContentWrapper>
        <TitleWrapper>
          <div>
            <Title fontSize={'large'} color={'#4A5568'} padding={'20px 0 0 0'}>{data.title}</Title>
            <DescriptionContent>
              {data.description}
            </DescriptionContent>
          </div>
          <DeleteButton
            onClick={(e) => {          
              e.stopPropagation()
              toggleModal(!isOpen)
            }}
          >
            <TrashIcon />
          </DeleteButton>     
        </TitleWrapper>        
      </ContentWrapper>
      <ContentWrapper>
      <Title fontSize={'medium'} color={'#4A5568'} padding={'20px 0 0 0'}>
          {t('game.suggestions.new.material')}
        </Title>
        {data.materials.map( (material) => (
          <MaterialsWrapper key={material._id}>
            <Material data={material} />
          </MaterialsWrapper>
        ))}
      </ContentWrapper>
    </Wrapper>
    { isOpen &&
      <ConfirmDeleteSuggestion
        handleClose={toggleModal}
        deleteSuggestion={deleteSuggestion}
        id={data._id}
      />
    }
    </>
  )
}

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const DeleteButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #d8d8d8;
  cursor: pointer;
  min-height: 50px;
  min-width: 50px;
  height: 50px;
  width: 50px;
  transition: all 0.2s;

  > svg {
    height: 20px;
    width: 20px;
    fill: #3F4B63;
  }

  &:hover {
    background: ${props => props.theme.primary};  
    > svg {
      fill: white;
    }
  }
`

const ContentWrapper = styled.div`
  padding: 1rem 1.5rem;
  position: relative;
`

const DescriptionContent = styled.p`
  line-height: 1;
`

const AbsoluteWrapper = styled.div`
  display: none;
  position: absolute;
  cursor: pointer;
  color: ${props => props.theme.gray};
  border-bottom: 1px solid ${props => props.theme.gray};
  top:20px;
  right: ${props => props.right};
  text-transform: lowercase;
`

const StyledSubtitle = styled.h3`
  color: ${props => props.theme.gray};
`
const MaterialsWrapper = styled.div`
  padding: 1rem;
  border-bottom: 1px solid ${props => props.theme.primary};
`
const Wrapper = styled.div`
  cursor: pointer;
  width: 100%;  
  border-radius: 16px;
  border: 1px solid #d8d8d8;

  &:hover{
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);    
  }
`


export default SuggestionCard