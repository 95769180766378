
export const parseDate = (dateString) => {
    if (!dateString) {
      return 0; // Manejar el caso de null
    }
  
    const dateComponents = dateString.split('/');
    if (dateComponents.length === 3) {
      // formato es día/mes/año
      const day = parseInt(dateComponents[0], 10);
      const month = parseInt(dateComponents[1], 10) - 1;
      const year = parseInt(dateComponents[2], 10);
      const parsedDate = new Date(year, month, day);
  
      // Verificar si la fecha es válida antes de devolver el valor
      return isNaN(parsedDate.getTime()) ? 0 : parsedDate.getTime();
    } else {
      // Si no se puede analizar, devolver 0
      return 0;
    }
  };
  