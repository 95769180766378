import React from "react";
import styled, { keyframes } from "styled-components";

const BounceAnimation = keyframes`
  0% { margin-bottom: 0; }
  50% { margin-bottom: 15px }
  100% { margin-bottom: 0 }
`;
const DotWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;
const Dot = styled.div`
  background-color: black;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: ${props => props.color ? props.color : '#000000'};
  /* Animation */
  animation: ${BounceAnimation} 0.5s linear infinite;
  animation-delay: ${props => props.delay};
`;


const Loader = ({ color }) => (
  <DotWrapper>
    <Dot delay="0s" color={color}/>
    <Dot delay=".1s" color={color}/>
    <Dot delay=".2s" color={color}/>
  </DotWrapper>
)
export default Loader