import React from "react";
import styled from "styled-components";

const TableRow = ({ row }) => {
  const keys = Object.keys(row);
  const filteredKeys = keys.filter((key) => key !== "id");
  const cellsData = filteredKeys.map((k) => row[k]);

  return (
    <RowTr>
      {cellsData.map((cellData, i) => (
        <RowTd key={i}>{cellData}</RowTd>
      ))}
    </RowTr>
  );
};

const Table = ({ headerTable, rowTable }) => {
  return (
    <TableContainer>
      {headerTable.map((text) => (
        <HeaderTh key={text}>{text}</HeaderTh>
      ))}

      <tbody>
        {rowTable.map((row) => {
          return <TableRow row={row} />;
        })}
      </tbody>
    </TableContainer>
  );
};

const TableContainer = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: 15px;
  margin-bottom: 20px;
`;

const HeaderTh = styled.th`
  padding: 10px;
  margin: 10px;
  text-align: left;
  font-weight: 500;
  color: #808080;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom: 2px solid #f2f2f2;
  `;

const RowTd = styled.td`
  padding: 20px 10px 20px 10px;
  font-weight: 500;
`;
const RowTr = styled.tr`
  border-bottom: 2px solid #f2f2f2;
`;

export default Table;
