import React, { useRef, useState } from "react";
import QuestionCard from "../../../components/QuestionCard";
import NewQuestion from "../../../components/NewQuestion";
import styled from "styled-components";
import EditQuestion from "../../../components/EditQuestion";
import SidebarScreen from "../../../components/SidebarScreen";
import t from "../../../utils/i18n";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

const UPDATE_QUESTION = gql`
  mutation UpdateQuestion(
    $_id: String!
    $content: String
    $answers: [AnswerInput]
    $achievements: [AchievementInput]
  ) {
    updateQuestion(
      _id: $_id
      content: $content
      answers: $answers
      achievements: $achievements
    )
  }
`;

const Questions = ({ questions, suggestions, selectedAxis }) => {
  const questionsEndRef = useRef(null);

  const [question, showQuestion] = useState(null);
  const gameTranslations = t("game.questions", { returnObjects: true });

  const [updateQuestion, { loading }] = useMutation(UPDATE_QUESTION, {
    errorPolicy: "all",
    refetchQueries: ["game"],
    onCompleted: () => showQuestion(null),
  });

  const onNewQuestionSuccess = (newQuestion) => {
    setTimeout(() => {
      questionsEndRef.current.scrollIntoView({ behavior: "smooth" });
    }, 50);

    setTimeout(() => {
      showQuestion(newQuestion);
    }, 500);
  };

  return (
    <Wrapper>
      <SidebarScreen
        show={!!question}
        toggle={() => {
          showQuestion(null);
        }}
        title={gameTranslations.edit.title}
      >
        {question && (
          <EditQuestion
            updateQuestion={updateQuestion}
            loading={loading}
            translations={gameTranslations}
            question={question}
            axisId={selectedAxis._id}
            suggestions={suggestions}
            questionsList={questions.filter((q) => q._id !== question._id)}
          />
        )}
      </SidebarScreen>

      {questions &&
        questions.map((question, key) => (
          <CardWrapper key={question._id}>
            <QuestionCard
              onClick={() => {
                showQuestion(question);
              }}
              translations={gameTranslations}
              data={question}
              itemCount={key + 1}
            />
          </CardWrapper>
        ))}
      {selectedAxis && (
        <NewQuestion
          axisId={selectedAxis._id}
          onSuccess={onNewQuestionSuccess}
        />
      )}
      <div ref={questionsEndRef}></div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;
const CardWrapper = styled.div`
  padding: 2rem 0;
  background: white;
`;

Questions.defaultProps = {
  questions: [],
};
export default Questions;
