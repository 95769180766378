import React from 'react'
import styled, {keyframes} from 'styled-components'

const SkeletonLogo = () => (
  <div>
    <Circle />
  </div>
)

const gradientBG = keyframes`
  0%{
    background-position: 0% 50%;
  }
  50%{
    background-position: 100% 50%;
  }
  100%{
    background-position: 0% 50%;
  }
`

const Circle = styled.div`
  width: 125px;
  height: 125px;
  border-radius: 100%;
  background: linear-gradient(-45deg, #fbfbfb, #cccccc, #fbfbfb );
  background-size: 400% 400%;
  animation: ${gradientBG} 2s ease infinite;
`

export default SkeletonLogo