import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import CloseIcon from './assets/CloseIcon'

const Modal = ({children, onCloseModal}) => {  
  const node = useRef()

  const handleClick = e => {
    if (!node.current.contains(e.target)) {
      onCloseModal()
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });

  return (
    <OverlayBackground>
      <ModalWrapper ref={node}>
        <CancelButton onClick={onCloseModal}>
          <CloseIcon />
        </CancelButton>
        {children}
      </ModalWrapper>
    </OverlayBackground>  
  )
}

const OverlayBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  padding: 2rem;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ModalWrapper = styled.div`
  display: inline-block;
  position: relative;
  margin: 0 auto;  
  background: white;
  border-radius: 4px;
  overflow: scroll;
  box-shadow:0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  padding: 2rem;
  min-height: 5rem;
  min-width: 20rem;
`

const CancelButton = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
`
export default Modal