import React from 'react'
import { connect } from 'react-redux'

import InviteOrganization from './components/Modal'

import * as organizationActions from '../../../organization/actions'

class InviteOrganizationContainer extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      email: '',
      subject: ''
    }
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  render(){
    const { sendInvite, onCloseModal } = this.props

    const {email, subject} = this.state
    return (
      <InviteOrganization sendInvite={sendInvite} email={email} subject={subject} onCloseModal={onCloseModal}  onChange={this.onChange}/>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  sendInvite: (email, subject) => dispatch(organizationActions.sendInvite({email, subject}))
})

export default connect(
  null,
  mapDispatchToProps
)(InviteOrganizationContainer)