export const NAMESPACE = 'profile'

export const INNER_PATHS = [
  { url: 'main', name: 'profile.subtitle' },
  // { url: 'domain', name: 'Dominio' },
  // { url: 'payment', name: 'Pagos' }
]

export const PROFILE = {
  name: 'Riley Cooper',
  organization: 'Wingu',
  email: 'rileycooper@winguweb.org',
  date: '2018-07-22',
  lang: 'en',
  password: 'nothingnothing'
}