import React from 'react'
import styled from 'styled-components'
import TextInputLabel from '../TextInput/TextInputLabel'
import TextArea from '../TextArea'
import Select from 'react-select'
import { customStyles } from '../../utils/helpers'
import Button from '../../components/Button'
import CloseIcon from '../../components/CloseIcon'
import {useMutation} from '@apollo/react-hooks'
import { UPDATE_SUGGESTION, CREATE_SUGGESTION } from '../../queries/suggestion'
import t from '../../utils/i18n'

const options = [{value: 'VIDEO', label: 'VIDEO'}, {value:'PDF', label: 'PDF'}, {value:'PPT', label:'PPT'}, {value:'DOC', label:'DOC'}, {value:'OTHER', label:'OTHER'}]
const formatMaterials = (materials) => {
  const newMaterial = materials.map( m => ({
    type: m.type,
    name: m.name,
    link: m.link
  }))
  return newMaterial
}

const EditSuggestion = ({id, axisId, title, description, materials, onChange, handleMaterialChange, addMaterial, removeMaterial, onCloseModal}) => {
  const titleLabel = t('game.suggestions.new.title')

  const nameLabel = t('game.suggestions.new.name')
  const linkLabel = t('game.suggestions.new.link')
  const typeLabel = t('game.suggestions.new.type')

  const [handleSuggestion, { loading }] = useMutation(id ? UPDATE_SUGGESTION : CREATE_SUGGESTION ,{
    refetchQueries: ['game'],
    onCompleted: (res) => onCloseModal(res)
  })
  
  return (
    <div>
      <h3>{ t('game.suggestions.new.header')}</h3>
      <TextInputLabel 
        type="text"
        name="title"
        label={titleLabel}
        value={title}
        onChange={onChange}
      />
      <TextArea 
        name="description"
        label={t('game.suggestions.new.description')}
        value={description}
        onChange={onChange}
      />
      <h4>{t('game.suggestions.new.material')}</h4>
      <MaterialsList>
        {materials && materials.map(material => (
          <MaterialWrapper key={material._id}>
            <SelectWrapper>
              <Select 
                styles={customStyles}
                options={options}
                placeholder={typeLabel}
                value={options.filter( option => option.value === material.type)}
                onChange={(ev) => handleMaterialChange(ev.value,'type', material._id)}
              /> 
            </SelectWrapper>
            <TextWrapper>
              <TextInputLabel 
                type="text"
                value={material.name}
                label={nameLabel}
                name="name"
                onChange={(ev) => handleMaterialChange(ev.target.value, ev.target.name, material._id)}
              />
            </TextWrapper>
            <TextWrapper>
              <TextInputLabel 
                type="text"
                label={linkLabel}
                name="link"
                value={material.link}
                onChange={(ev) => handleMaterialChange(ev.target.value, ev.target.name, material._id)}
              />
            </TextWrapper>
            <RemoveItem onClick={() => removeMaterial(material._id)}>
              <CloseIcon />
            </RemoveItem>
          </MaterialWrapper>
        ))}
        <AddMaterialWrapper>
          {materials.length < 4 &&
            <AddMaterial onClick={addMaterial}>
              {t('game.suggestions.new.add')}
            </AddMaterial>
          }
        </AddMaterialWrapper>
      </MaterialsList>
      <ButtonWrapper>
        <Button 
          loading={loading}
          onClick={() => handleSuggestion({variables: {
            _id: id,
            axis: axisId,
            title,
            description,
            materials: formatMaterials(materials)
        }})}>{t('game.suggestions.new.save')}</Button>
      </ButtonWrapper>
    </div>
  )
}

const TextWrapper = styled.div`
  padding-right: 1rem;
  width: 25rem;
`
const RemoveItem = styled.div`
  cursor: pointer;
`

const SelectWrapper = styled.div`
  margin-top: 0.25rem;
  padding-right: 1rem;
  width: 25rem;
`

const MaterialsList = styled.div`
  position: relative;
`

const AddMaterialWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
`
const AddMaterial = styled.span`
  cursor: pointer;
  border-bottom: 1px solid;
`

const ButtonWrapper = styled.div`
  padding-top: 1rem;
`
const MaterialWrapper = styled.div`
  display: flex;
  position: relative;
  padding-bottom: 1rem;
  align-items: center;
`
export default EditSuggestion