import React from 'react'
import styled from 'styled-components'
import {UPLOAD_FILE_STREAM} from '../../../queries/Game/updateGame'
import { useMutation } from '@apollo/react-hooks' 
import SkeletonLogo from '../../../components/SkeletonLogo'
import Logo from '../../../components/Logo';

const LogoUploader = ({imgUrl}) => {
  const [singleUploadStream, {loading, error, data} ]= useMutation(UPLOAD_FILE_STREAM, {
    refetchQueries: ['game']
  })

  return (
    <Wrapper>
      <LogoWrapper>
        {loading ? <SkeletonLogo /> : <Logo imgUrl={imgUrl} />}
      </LogoWrapper>
      <HiddenInput name="logo" id="logo" type={'file'} onChange={ ({target: {files}}) => {
        const file = files[0]
        file && singleUploadStream({ 
            variables: { 
              file: file 
            }
          })
        }}
      />
      {error && <p>El logo debe ser una imagen </p>}
      <LabelUpload htmlFor="logo">Actualizar logo</LabelUpload>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding-left: 3rem;
`

const LogoWrapper = styled.div`
  text-align: center;
  margin-bottom: 1rem;
`

const HiddenInput = styled.input`
  display: none;
`

const LabelUpload = styled.label`
  cursor: pointer;
  border: 2px solid ${props => props.theme.primary};
  font-weight: bold;
  color: ${props => props.theme.primary};
  padding: 0.5rem 1.5rem;
  border-radius: 9999px;
  transition: opacity .3s ease-in-out;
  transition: background-color 0.3s;
  &:hover{
    color: white;
    background-color: ${props => props.theme.primary};
  }
  &:active{
    opacity: 0.5;
  }
`
export default LogoUploader