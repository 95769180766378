import { connect} from 'react-redux' 

import * as actions from '../actions'
import MetricsContainer from '../components/MetricsContainer'

const mapStateToProps = state => {
  return {
    metrics: state.overview.metrics,
    fetching: state.overview.status
  }
}

const mapDispatchToProps = dispatch => ({
  onMount: () => dispatch(actions.fetchMetrics())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MetricsContainer)