import { connect} from 'react-redux' 

import * as actions from '../actions'
import RankingContainer from '../components/OrganizationsRankingContainer'
import * as modalActions from '../../modals/actions'

const mapStateToProps = state => ({
  ranking: state.overview.ranking
})

const mapDispatchToProps = dispatch => ({
  onMount: () => dispatch(actions.fetchRanking()),
  onSelectOrganization: (organization) => dispatch({
    type: modalActions.openModal,
    payload: {
      type: 'organizationProfile',
      selectedOrganization: organization,
      fetchOrganizations: () => dispatch(actions.fetchRanking())
    }
  })
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RankingContainer)