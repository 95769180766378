import React,{useState} from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Email from '../Icons/Email'
import Lock from '../Icons/Lock'

const LoginInput = ({ label, password, email, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Wrapper {...props}>
    {label && <Label>{label}</Label>}

    <Input {...props}
    
    type={showPassword ? 'text' : password ? 'password' : 'text'}

    />
    {password ? (
     <div style={{backgroundColor: 'white'}} onClick={() => togglePasswordVisibility()}> <Lock /></div>
    ) : (
      <Email />
    )}
  </Wrapper>
  );
};

const Wrapper = styled.div`
  align-items: flex-end;
  border-bottom: 1px solid;
  border-color: ${props => props.status === 'failure' ? '#F44336':'rgb(210, 215, 220)'};
  color: ${props => props.status === 'failure' ? '#F44336' : '#616161'};
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 0.5rem 0.75rem 0.5rem 0;
  width: 100%;
`
const Label = styled.label`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`

const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
  background-color: ${props => props.status === 'failure' ? 'rgba(244, 67, 54, .1)': 'transparent'};
  appearance: none;
  outline: none;
  // font-family: Rubik, Roboto, sans-serif;
  font-size: 1rem;
  border: none;
  border-color: ${props => props.status === 'failure' ? '#F44336':'#616161'}
`

LoginInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string
}

export default LoginInput