import React, { useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks' 
import { GET_GAME } from '../../../queries/Game/getGame'
import Main from './Main'

  const MainContainer = (props) => {
    const { loading, error, data } = useQuery(GET_GAME, {
      variables: {
        _id: process.env.REACT_APP_ORGANIZATION_ID
      }
    })

    useEffect(() => {
      const id = localStorage.getItem('id')
      props.onMount(id)
    }, [])

    if (loading || !props.profile) {
      return null
    }

    return (
      <Main data={data} profile={props.profile} />
    )
  }

export default MainContainer