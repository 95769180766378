import OverviewContainer from './scenes'
import MetricsContainer from './scenes/Overview'
import OrganizationsRankingContainer from './scenes/OrganizationsRanking'
import * as constants from './constants'
import reducer from './reducers'
import sagas from './sagas'

export default {
  constants,
  reducer,
  scenes: {
    OverviewContainer,
    MetricsContainer,
    OrganizationsRankingContainer
  },
  sagas: [
    ...sagas
  ]
}