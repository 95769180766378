import React, { useState, useEffect } from "react";
import BroadCard from "../../../components/BroadCard";
import styled from "styled-components";
import Building from "../../../components/Icons/UserScene";
import People from "../../../components/Icons/People";
import Location from "../../../components/Icons/Location";
import { useQuery } from "@apollo/react-hooks";
import t from "../../../utils/i18n";
import { gql } from "apollo-boost";
import _ from "lodash";
import AchievementCount from "../../../components/Achievements";
import BigLoader from "../../../components/BigLoader";
import Table from "../../../components/Table";
import searchItem from "./searchItem";
import Title from "../../../components/Title";
import themes from "../../../utils/themes";
import DownloadIcon from "../../../components/Icons/DownloadIcon";
import DownIcon from "../../../components/Icons/DownIcon";
import SearchIcon from "../../../components/Icons/SearchIcon";
import TableIcon from "../../../components/Icons/TableSortIcon";
import { findCountryWithMostOrganizations } from "./_commons/findCountryWithMoreOrganizations";
import { getOrgMaxUsersName } from "./_commons/getOrgMaxUsersName";
import Card from "../../../components/Card";
import CardsContainer from "../../../components/CardsContainer";
import NoElements from "../../../components/NoElements";

const GET_ORGANIZATIONS = gql`
  query organizations($game_id: String!) {
    organizations(game_id: $game_id) {
      _id
      users
      achievements
    }
  }
`;
export const GET_RANKING = gql`
  query usersRanking($game: String) {
    usersRanking(game: $game) {
      achievementsCount
      global_id
      organization_id
      playerStatus
    }
  }
`;
const sortOrganizations = (res, data) => {
  if (!data || data.length < 1) {
    return [];
  }
  // from graphql
  let organizations = _.clone(res);
  // ids of verified organizations
  const verifiedIds = _.clone(data).map((org) => org.id);
  // filter only verified organizations in rails
  const filteredOrganizations = organizations.filter((org) =>
    verifiedIds.includes(org._id)
  );
  return filteredOrganizations.map((obj) => {
    const additionalData = data.find((d) => d.id === obj._id);
    return {
      ...additionalData,
      achievements: obj.achievements,
    };
  });
};

const Ranking = ({ data, onSelectOrganization, users }) => {
  const [orgUsersAchievements, handleorgUsersAchievements] = useState([]);
  const [organizations, updateOrganizations] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [sortDirection, setSortDirection] = useState(null);
  const [achievementsSortDirection, setAchievementsSortDirection] = useState(null);

  const registeredOrgText = t('organizations.innerNabvarSections.registered')
  const morePlayersText = t("organizations.innerNabvarSections.more_players")
  const moreOrgCountryText = t("organizations.innerNabvarSections.country")
  const countryTitle = t("country")
  const searchText = t("search.organizations")
  const selectText = t("search.all")
  //translations table
  const achievementsTable = t("tables.organizations.achievements")
  const nameTable = t('tables.organizations.name')
  const countryTable = t('tables.organizations.country')
  const playersTable =  t('tables.organizations.players')
  const playingTable =  t('tables.organizations.playing')
  const webTable =  t('tables.organizations.web')
  const textButton = t('tables.organizations.export')

  const numberOfOrganizations = organizations.filter((item) => item.name).length;
  const mostOrganizationsCountry =findCountryWithMostOrganizations(organizations);
  const handleSearch = (e) => { setSearchTerm(e.target.value)};
  
  const handleLocationChange = (e) => {setSelectedLocation(e.target.value);};

  const userStatus = useQuery(GET_RANKING, {
    variables: {
      game: process.env.REACT_APP_ORGANIZATION_ID,
    },
  });

  const { loading } = useQuery(GET_ORGANIZATIONS, {
    variables: {
      game_id: process.env.REACT_APP_ORGANIZATION_ID,
    },
    onCompleted: (res) => {

      handleorgUsersAchievements(res.organizations);
      updateOrganizations(sortOrganizations(res.organizations, data));
    },
  });
  useEffect(() => {
    if (
      data &&
      data.length > 0 &&
      orgUsersAchievements &&
      orgUsersAchievements.length > 0
    ) {
      updateOrganizations(sortOrganizations(orgUsersAchievements, data));
    }
  }, [data, loading]);

  const MAX_DISPLAY_LENGTH = 30;
  // Función para la URL con longitud d caracteres máxima
  const getShortenedURL = (url) => {
    if (url?.length > MAX_DISPLAY_LENGTH) {
      return url?.slice(0, MAX_DISPLAY_LENGTH) + "..."; 
    }
    return url;
  };

  let filteredOrganizations = searchItem(organizations,["name", "location"],[searchTerm, selectedLocation]);

  const uniqueLocations = [...new Set(organizations.map((item) => item.location.split(",").pop().trim())),];

  const userStatusData = userStatus.data && userStatus.data.usersRanking;

  const userStatusByPlayerStatus = userStatusData || [];

  filteredOrganizations.sort((a, b) => b.achievements - a.achievements);
  const formatedRow = filteredOrganizations.map((row, i) => {
  const players = orgUsersAchievements.filter((org) => org._id === row.id)[0].users || 0;

  const playingCount = userStatusByPlayerStatus.reduce((count, user) => {
    if (user.playerStatus === "PLAYING" && user.organization_id === row.id) {
      return count + 1;
    }
    return count;
  }, 0);
  
    return {
      index: i + 1,
      achievements: <AchievementCount achievement={row.achievements} />,
      organization: row.name || "No tiene",
      location: row.location.split(",").pop().trim(),
      players: players,
      // playing: playerStatus,
      playing: playingCount,
      site: (
        <a
        style={{ textDecoration: "none", color: "black" }}
        href={row.site?.includes("http") ? row.site : `https://${row.site}`}
        target="_blank"
      >
        {getShortenedURL(row.site)} 
      </a>
      ),
    };
  });
  
  const headerTable = [
    "#",
    // achievementsTable,
    (
      <div
        onClick={() => toggleSortDirection('achievements')}
        style={{ cursor: 'pointer', textWrap: 'nowrap'}}
      >
        {achievementsTable}
        <TableIcon height={'11px'} color={'#6B7280'}/>
      </div>
    ),
    nameTable,
    countryTable,
    <div onClick={() => toggleSortDirection('players')} style={{ cursor: "pointer", textWrap: 'nowrap' }}>
      {playersTable}
      <TableIcon height={"11px"} color={"#6B7280"} />
    </div>,
    playingTable,
    webTable,
  ];
  const rowTable = formatedRow;

  const toggleSortDirection = (type) => {
    if (type === 'achievements') {
      if (achievementsSortDirection === 'asc') {
        setAchievementsSortDirection('desc');
        setSortDirection(null); 
      } else {
        setAchievementsSortDirection('asc');
        setSortDirection(null);
      }
    } else if (type === 'players') {
      if (sortDirection === 'asc') {
        setSortDirection('desc');
        setAchievementsSortDirection(null);
      } else {
        setSortDirection('asc');
        setAchievementsSortDirection(null);
      }
    }
  };
  
  if (sortDirection) {
    const playerSortOrder = sortDirection === 'asc' ? 1 : -1;
    rowTable.sort((a, b) => (a.players - b.players) * playerSortOrder);
  } else if (achievementsSortDirection) {
    const achievementsSortOrder = achievementsSortDirection === 'asc' ? 1 : -1;
    rowTable.sort((a, b) => (a.achievements.props.achievement - b.achievements.props.achievement) * achievementsSortOrder);
  }


  return (
    <>
      {loading ? (
        <LoaderWrapper>
          <BigLoader />
        </LoaderWrapper>
      ) : (
        <>
          <CardsContainer>
            <Card
              title={numberOfOrganizations}
              icon={<Building color="#1F2937" width="20px" />}
              description={registeredOrgText}
            />
            <Card
              title={getOrgMaxUsersName(orgUsersAchievements, organizations)}
              icon={<People color={"#1F2937"} width={"20px"} />}
              description={morePlayersText}
            />
            <Card
              title={mostOrganizationsCountry}
              icon={<Location color={"#1F2937"} width={"20px"} />}
              description={moreOrgCountryText}
            />
          </CardsContainer>
          <Section>
            <Input>
              <SearchIcon className="search-icon" />
              <input
                placeholder={searchText}
                value={searchTerm}
                onChange={handleSearch}
              />
              <DownIcon className="close-icon" />
            </Input>
            <CountrySection>
              <Title
                color={"#1F2937"}
                fontSize={"small"}
                fontWeight={"600"}
                padding={"0px 0 12px 0"}
              >
                {countryTitle}
              </Title>
              <Select>
                <select
                  style={{ width: "300px" }}
                  value={selectedLocation}
                  onChange={handleLocationChange}
                >
                  <option value="">{selectText}</option>
                  {uniqueLocations.map((location, index) => (
                    <option key={index} value={location}>
                      {location}
                    </option>
                  ))}
                </select>
                {/* <DownIcon className="icon-down" /> */}
              </Select>
            </CountrySection>
            <Button onClick={() => console.log("hi hi")}>
              <DownloadIcon color={themes.primary} />
              {textButton}
            </Button>
          </Section>
          {filteredOrganizations.length === 0 ?
          ( <NoElements text={""}/>) 
            : (
            <Table headerTable={headerTable} rowTable={rowTable} />
          )}
        </>
      )}
    </>
  );
};
const Cards = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;
const Items = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #1f2937;
  font-size: ${(props) => props.theme.font_size.small};
  font-weight: 600;
`;
const Button = styled.div`
  cursor: pointer;
  display: flex;
  font-weight: 600;
  color: ${(props) => props.theme.primary};
  border: 2px solid ${(props) => props.theme.primary};
  border-radius: ${(props) => props.theme.border_radius.rounded_sm};
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
`;
const CountrySection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;
`;
const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin: 0.5rem 0;
`;
const Input = styled.div`
  width: 637px;
  heigth: 46px;
  display: flex;
  align-items: center;
  border: 1px solid ${(props) => props.theme.slate_gray};
  border-radius: ${(props) => props.theme.border_radius.rounded_sm};
  padding: 10px;
  gap: 10px;
  input {
    border: none;
    outline: none;
    flex: 1;
    padding: 0;
  }
  input::placeholder {
    color: #6b7280;
  }
  .search-icon {
    fill: ${(props) => props.theme.slate_gray};
    width: 20px;
  }
  .close-icon {
    cursor: pointer;
    fill: ${(props) => props.theme.slate_gray};
    width: 20px;
  }
`;
const Select = styled.div`
  width: 330px;
  display: flex;
  align-items: center;
  border: 1px solid ${(props) => props.theme.slate_gray};
  border-radius: ${(props) => props.theme.border_radius.rounded_sm};
  padding: 10px;
  gap: 10px;
  select {
    border: none;
    outline: none;
  }
  select::placeholder {
    color: #6b7280;
  }
  .icon-down {
    cursor: pointer;
    fill: ${(props) => props.theme.slate_gray};
    width: 20px;
  }
`;
const Item = styled.div`
  padding-right: 1rem;
  width: ${(props) => (props.minWidth ? "9rem" : "")};
  color: #aeaeb2;
`;
const HighlighItem = styled.div`
  color: #000000;
  width: 9rem;
`;
const HoverItem = styled.div`
  display: none;
  margin-left: auto;
`;
const ButtonWrapper = styled.div`
  padding-right: 1rem;
`;
const Achievement = styled.div`
  > svg {
    width: 15px;
    height: 15px;
    margin-right: 1rem;
    fill: black;
  }
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #d8d8d8;
  &:hover {
    ${HoverItem} {
      display: flex;
    }
    ${HighlighItem} {
      font-weight: bold;
    }
    ${Achievement} {
      display: none;
    }
  }
`;
const EndWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;
const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 6rem;
`;
export default Ranking;
