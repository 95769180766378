import React from 'react'

import Button from '../Button/index'
import Loader from '../Loader/index'

const LoaderButton = ({text, status, loaderColor, ...props }) => (
  <Button {...props} status={status}>
    {status === 'pending' 
      ? <Loader color={loaderColor}  />
      : text }
  </Button>
)

export default LoaderButton