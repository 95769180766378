import ProfileContainer from './scenes'
import MainContainer from './scenes/Main'
import * as constants from './constants'
import reducer from './reducers'
import sagas from './sagas'

export default {
  constants,
  reducer,
  scenes: {
    ProfileContainer,
    MainContainer,
  },
  sagas: [
    ...sagas
  ]
}