import React from 'react'
import styled from 'styled-components'
import QuestionsTree from '../../../components/QuestionsTree'
import Title from '../../../components/Title'

const QuestionsTreeContainer = (props) => {

  if (props.questions.length == 0) {
    return null
  }

  return (
    <div>
      <HelpBox>
        <Title fontSize={'medium'} color={'#fff'} padding={'10px 0 10px 0'} fontWeight={'400'}>        
          { props.t('game.tree.tutorial.block_1') }
        </Title>
        <Title fontSize={'medium'} color={'#fff'} padding={'0 0 10px 0'} fontWeight={'400'}>   
          { props.t('game.tree.tutorial.block_2') }
        </Title>      
      </HelpBox>
      <div>
        <QuestionsTree 
          questions={props.questions}
          selectedAxis={props.selectedAxis}
        />
      </div>
    </div>
  )
}

const HelpBox = styled.div`
  width: 100%;
  font-weight: 800;
  color: white;
  font-size: 18px;
  border-radius: 16px;
  background: ${props => props.theme.primary};
  padding: 1rem;
  margin: 1rem 0;
`

export default QuestionsTreeContainer