import React from "react";
import styled from "styled-components";

function Icon({ color, width, height, padding }) {
  return (
    <SignFilled color={color} width={width} height={height} padding={padding}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_445_3950)">
          <path
            d="M7 7V1.414C7 1.14878 7.10536 0.894431 7.29289 0.706895C7.48043 0.519358 7.73478 0.414001 8 0.414001C8.26522 0.414001 8.51957 0.519358 8.70711 0.706895C8.89464 0.894431 9 1.14878 9 1.414V2H14C14.1552 2 14.3084 2.03615 14.4472 2.10557C14.5861 2.175 14.7069 2.27581 14.8 2.4L15.775 3.7C15.8399 3.78655 15.875 3.89182 15.875 4C15.875 4.10819 15.8399 4.21345 15.775 4.3L14.8 5.6C14.7069 5.7242 14.5861 5.825 14.4472 5.89443C14.3084 5.96386 14.1552 6 14 6H9V16H7V11H2C1.84475 11 1.69164 10.9639 1.55279 10.8944C1.41393 10.825 1.29315 10.7242 1.2 10.6L0.225 9.3C0.160089 9.21345 0.125 9.10819 0.125 9C0.125 8.89182 0.160089 8.78655 0.225 8.7L1.2 7.4C1.29315 7.27581 1.41393 7.175 1.55279 7.10557C1.69164 7.03615 1.84475 7 2 7H7ZM8 10V8H2L1.25 9L2 10H8ZM8 5H14L14.75 4L14 3H8V5Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_445_3950">
            <rect width="16" height="16" fill={color} />
          </clipPath>
        </defs>
      </svg>
    </SignFilled>
  );
}

const SignFilled = styled.span`
  fill: ${(props) => props.color || props.theme.primary || "#1F2937"};
  width: ${(props) => props.width || "16px"};
  height: ${(props) => props.height || "16px"};
`;

export default Icon;
