import { handleActions } from 'redux-actions'

import * as actions from './actions'
import types from './components/types'

const initialState = {
  type: '',
  data: {},
  onConfirm: '',
  onCancel: '',
  selectedOrganization: {},
  fetchOrganizations: () => {},
  isOpen: false
}

const reducer = handleActions({
  [actions.openModal]: (state, action) => ({
    ...state,
    type: types[action.payload.type],
    onConfirm: action.payload.onConfirm,
    onCancel: action.payload.onCancel,
    data: action.payload.data,
    selectedOrganization: action.payload.selectedOrganization,
    fetchOrganizations: action.payload.fetchOrganizations,
    isOpen: true
  }),

  [actions.closeModal]: () => ({
    ...initialState,
    isOpen: false,
  })

}, initialState)

export default reducer