import { call, put, takeEvery } from 'redux-saga/effects'
import { get } from '../../utils/api'
import * as actions from './actions'

export function * fetchProfileWorker (action) {
  yield put(actions.fetchProfile.start())

  const { id } = action.payload

  const { res, error } = yield call(get, `/users/${id}`)

  if (error) {
    return yield put(actions.fetchProfile.failure({error: error.data.error }))
  }

  return yield put (actions.fetchProfile.success({ profile: res.user }))
}

export function * fetchProfileWatcher () {
  yield takeEvery(actions.fetchProfile.type, fetchProfileWorker)
}

export default [
  fetchProfileWatcher
]