import React, {useRef, useEffect} from 'react'
import styled from 'styled-components'
import t from '../../../../utils/i18n'
import Button from '../../../Button'

function useOutsideModal(ref, closeModal) {
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      closeModal(false)
    }
  }
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
}

const ConfirmDeleteSuggestion = ({handleClose, id, deleteSuggestion}) => {
  const wrapperRef = useRef(null);
  useOutsideModal(wrapperRef, handleClose);
  return (
    <Overlay>
      <Wrapper>
        <Card ref={wrapperRef}>
          <Content dangerouslySetInnerHTML={{__html: t('game.suggestions.delete.warning') }}/>          
          <ButtonWrapper>
            <CancelButtonWrapper>
              <Button outline onClick={()=>handleClose(false)}>{t('game.suggestions.delete.cancel')}</Button>
            </CancelButtonWrapper>
            <Button onClick={() => {
              handleClose(false)
              deleteSuggestion({
                variables: {
                  _id: id
                }
              })
            }}>{t('game.suggestions.delete.confirm')}</Button>
          </ButtonWrapper>
        </Card>
      </Wrapper>
    </Overlay>
  )
}

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,.5);
  z-index: 10;
`
const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
`
const Card = styled.div`
  box-sizing: border-box;
  width: 400px;
  padding: 2rem;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`
const Content = styled.p`
  color: #3F4B63;
  text-align: center;
  
`
const ButtonWrapper = styled.div`
  padding: 0.5rem;
  display: flex;
  justify-content: space-around;
  width: 80%;
`
const CancelButtonWrapper = styled.div`
margin-right: 1rem;
`
export default ConfirmDeleteSuggestion