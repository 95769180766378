import React from 'react'
import styled from 'styled-components'
import Button from '../../../components/Button'
import OrganizationTable from './_commons/OrganizationTable'
import SkeletonTable from '../../../components/SkeletonTable';
import { useTranslation } from 'react-i18next'
import Title from '../../../components/Title';

const Request = ({data, status, editOrganization}) => {
  const { t } = useTranslation()
  const requestText = t("organizations.requests.no_requests")
  if(status === 'pending'){
    return <SkeletonTable />
  }else {
    return (
      data.length === 0 
      ? (<TextContainer><Title fontSize={'medium'} color={'black'} fontWeight={'400'} padding={'0'}>{requestText}</Title></TextContainer>)
      : (
      <OrganizationTable type='request'>
        {data.map( (d, key) => (
          <Row key={key}>
            <Bullet></Bullet>
            <HighlighItem>{d.name}</HighlighItem>
            <Item minWidth><StyledAnchor href={d.site} target="_blank" rel="noopener noreferrer">{d.site}</StyledAnchor></Item>
            <Item minWidth>{d.country}</Item>
            <Item minWidth>{d.manager ? d.manager.email : '---'}</Item>      
            <HoverItem>
              <ButtonWrapper width="9rem">
                <Button outline>{ t('organizations.requests.buttons.reject') }</Button>
              </ButtonWrapper>
              <ButtonWrapper width="9rem">
                <Button onClick={() => editOrganization(d)}>{ t('organizations.requests.buttons.accept') }</Button>
              </ButtonWrapper>
            </HoverItem>
          </Row>
        ))}
      </OrganizationTable>
      )
    )
  }
}

const Bullet = styled.div`
  background-color: #000000;
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid #000000;
  border-radius: 100%;
  margin-right: 1rem;
`

const Item = styled.div`
  padding-right: 1rem;
  width: ${props => props.minWidth ? '9rem': ''};
  color: #AEAEB2;
`

const TextContainer = styled.div`
  background: #f7f7f7;
  padding: 10px;
  border-radius: 5px;
  background-color: #f7f7f7;
  width: fit-content;
`
const HighlighItem = styled.div`
  color: #000000;
  width: 9rem;
`
const HoverItem = styled.div`
  display: none;
  margin-left: auto;
`
const ButtonWrapper = styled.div`
  padding-right: 1rem;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem;
  height: 2.5rem;
  border-bottom:1px solid #D8D8D8;
  &:hover{
    ${HoverItem}{
      display: flex;
    }
    ${HighlighItem}{
      font-weight: bold;
    }
  } 
`

const StyledAnchor = styled.a`
  text-decoration: none;
  color: #AEAEB2;
  font-weight: bold;
`

export default Request