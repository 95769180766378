import React from 'react'
import styled from 'styled-components'
import Arrow from '../Icons/Arrow'

const SidebarScreen = ({children, show, toggle, title}) => {
  return (
    <Wrapper show={show}>
      <Content>
        <Header>
          <ArrowWrapper onClick={toggle}>
            <Arrow color="#3F4B63"/>
          </ArrowWrapper>
          <Title>
            { title }
          </Title>
        </Header>
        <div>
          { children }
        </div>
      </Content>
    </Wrapper>
  )
}

SidebarScreen.defaultProps = {
  title: 'Title'
}

const Wrapper = styled.div`
  background: white;
  position: fixed;
  top: 0;
  bottom: 0;  
  z-index: 2;  
  transition: .2s ease;
  width: 0;
  left: 100%;

  ${props => props.show && `
    width: 82%;
    left: 18%;
  `}
`

const Content = styled.div`
  height: 100%;
  overflow-y: scroll;
  padding: 0 2rem;
`

const ArrowWrapper = styled.div`
  cursor: pointer;
  transform: rotate(180deg);
  display: inline-block;  
  > svg {
    height: 40px;
    width: 40px;
  }
`

const Header = styled.div`
  padding: 1rem 0;  
  display: flex;
  align-items: center;
`

const Title = styled.div`
  padding-left: 1rem;
  font-size: 2rem;
  color: #3F4B63
`

export default SidebarScreen