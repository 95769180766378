import { connect } from 'react-redux'

import * as actions from '../actions'
import * as selectors from '../selectors'

import Auth from '../components/AuthContainer'

const mapStateToProps = state => ({
  user: selectors.user(state),
  status: selectors.status(state),
  error: selectors.error(state)
})

const mapDispatchToProps = dispatch => ({
  loginUser: (email, password) => dispatch(actions.loginUser({ email, password }))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Auth)