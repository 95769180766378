import { call, put, takeEvery } from 'redux-saga/effects'

import { post } from '../../utils/api'
import * as actions from './actions'

export function * loginUserWorker(action) {
  yield put(actions.loginUser.start())
  const {email, password} = action.payload

  if(!email || !password){
    return yield put(actions.loginUser.failure({error: 'Mail y contraseña son obligatorios'}))
  }

  const { res: user, error } = yield call(post, 'auth/login', {email, password, game_id: process.env.REACT_APP_ORGANIZATION_ID})

  if( !user || user.role !== 'admin'){
    return yield put(actions.loginUser.failure({error: 'unauthorized'}))
  }
  if(error){
    return yield put(actions.loginUser.failure({error: error.data.errors}))
  }

  localStorage.setItem('token', user.token)
  localStorage.setItem('id', user.id)
  window.location.replace('/overview')

  return yield put(actions.loginUser.success({user}))
}

export function * loginUserWatcher () {
  yield takeEvery(actions.loginUser.type, loginUserWorker)
}

export default [
  loginUserWatcher
]