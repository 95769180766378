import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Thumbnail = ({ img, ...props }) => (
  <StyledThumbnail src={img}  {...props} />
)

const StyledThumbnail = styled.img`
  background-color: #C7C7CC;
  border-radius: 100%;
  height: ${props => props.large ? '105px': '45px'};
  width: ${props => props.large ? '105px': '45px'};
`

Thumbnail.propTypes = {
  img: PropTypes.string
}

export default Thumbnail