import { handleActions } from 'redux-actions'
import * as actions from './actions'

const initialState = {
  state: 'init',
  error: '',
  metrics: {},
  ranking: [],
  members: []
}

const reducer = handleActions({
  [actions.fetchMetrics.START]: (state) => ({
    ...state,
    status: 'pending'
  }),
  [actions.fetchMetrics.FAILURE]: (state, action) => ({
    ...state,
    status: 'failure',
    error: action.payload.error
  }),
  [actions.fetchMetrics.SUCCESS]: (state, action) => ({
    ...state,
    status: 'success',
    metrics: action.payload.metrics
  }),
  [actions.fetchRanking.START]: (state) => ({
    ...state,
    status: 'pending'
  }),
  [actions.fetchRanking.FAILURE]: (state, action) => ({
    ...state,
    status: 'failure',
    error: action.payload.error
  }),
  [actions.fetchRanking.SUCCESS]: (state, action) => ({
    ...state,
    status: 'success',
    ranking: action.payload.ranking
  }),
}, initialState)

export default reducer