import React, { useState } from 'react'
import styled from 'styled-components'
import t from '../../../utils/i18n'
import Questions from './Questions'
import Suggestions from './Suggestions';
import QuestionsTreeContainer from './QuestionsTreeContainer'

const OptionWrapper = ({axes, selectedAxis}) => {
  const axesData = axes.find( a=> a._id === selectedAxis._id) ? axes.find(a => a._id === selectedAxis._id) : []


  const {suggestions, questions} = axesData
  const [ selectedOption, changeOption ] = useState('questions')
  return (
    <div>
      <StyledUl>
        <StyledItem isActive={selectedOption === 'questions'} onClick={() => changeOption('questions')}>{t('game.questions.title')}</StyledItem>
        <StyledItem isActive={selectedOption === 'suggestions'} onClick={() => changeOption('suggestions')}>{t('game.suggestions.title')}</StyledItem>
        <StyledItem isActive={selectedOption === 'tree'} onClick={() => changeOption('tree')}>{t('game.tree.title')}</StyledItem>
      </StyledUl>
      {selectedOption === 'questions' && <Questions questions={questions} suggestions={suggestions} selectedAxis={selectedAxis} />}
      {selectedOption === 'suggestions' && <Suggestions suggestions={suggestions} selectedAxis={selectedAxis} />}
      {selectedOption === 'tree' && <QuestionsTreeContainer t={t} questions={questions} selectedAxis={selectedAxis}/>}
    </div>
  )
}

const StyledUl = styled.ul`
  list-style: none;
  display: flex;
  font-size: 1.2rem;
  // font-family: sans-serif;
  padding: 0;
`

const StyledItem = styled.li`
  text-align: center;
  margin-right: 2.5rem;
  padding-bottom: 0.3rem;
  cursor: pointer;  
  color: ${ props => props.isActive ? props.theme.primary : '#000'};
  font-weight: ${ props => props.isActive ? '600' : '400'};  
  &:hover{
    color: ${props => props.theme.primary};
  }

`

export default OptionWrapper