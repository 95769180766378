import React from 'react'
import styled from 'styled-components'
import Glass from './assets/glass'
import t from '../../utils/i18n'

const Wrapper = styled.div`
   align-items: center;
   border: 1px solid #EFEFF4;
   color: rgb(70, 70, 72);
   display: flex;
   flex-direction: column;
   height: 100%;
   justify-content: center;
   padding: 4rem;
   width: 100%;
`

const Title = styled.span`
  font-size: 1.75rem;
  font-weight: bold;
  margin: 1rem 0;
`

const Subtitle = styled.span``

const EmptyMetrics = () => (
  <Wrapper>
    <Glass />
    <Title>{t('overview.metrics.empty.title')}</Title>
    <Subtitle>{t('overview.metrics.empty.subtitle')}</Subtitle>
  </Wrapper>
)

export default EmptyMetrics