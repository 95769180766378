import React, { Component } from 'react'
import ReactDOMServer from 'react-dom/server'
import styled, { withTheme } from 'styled-components' 

const Wrapper = styled.div`
  margin-top: 2rem;
  border-radius: 4px;
  border: 1px solid #EFEFF4  

  > div {    
    height: 512px;
  }
`

const MarkerIcon = styled.div`
  height: 1rem;
  width: 1rem;
  background: ${props => props.brand};
  border-radius: 50%;
`

const MarkerWrapper = styled.div`
  position: relative;
  min-width: 250px;
`

const MarkerTooltip = styled.div`
  position: absolute;
  display: none;
  left: 25px;
  padding: 1rem;
  border-radius: 4px;
  background: white;
  
  > div {
    display: flex;
  }

  ${MarkerWrapper}:hover & {
    display: flex;
    flex-direction: column;
  }
`

const MarkerCount = styled.div`
  padding-top: 1rem;
`

const ClusterIcon = styled.div`
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  background: white;
  color: ${props => props.brand};
  font-weight: 600;
  border: 2px solid ${props => props.brand};
`

class Map extends Component {

  componentDidMount(){
    this.map = window.L.map('mapid', {
      minZoom: 2,
      scrollWheelZoom: false
    }).setView([0, 0], 1);

    window.L.tileLayer(`https://api.mapbox.com/styles/v1/juanlacueva/{id}/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`, {
          attribution: '',
          maxZoom: 5,
          id: 'ck54bxz8837z11ckv3hfj4prs',
          accessToken: process.env.REACT_APP_MAPBOX_TOKEN
      }).addTo(this.map);

      this.map.setMaxBounds(window.L.latLngBounds(
        window.L.latLng(-90, -180),
        window.L.latLng(90, 180),
      ))
      this.map.fitWorld()
      this.map.setZoom(2)

      if (this.props.data) {
        this.fillWorld()
      }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.data && this.props.data) {
      this.fillWorld()
    }
  }

  parseData = () => {
    let parsedValues = []
    this.props.data.forEach((obj) => { 
      if (parsedValues[obj.location_id]) {
        parsedValues[obj.location_id].count += 1
      } else {
        parsedValues[obj.location_id] = obj
        parsedValues[obj.location_id].count = 1
      }
    }) 
    
    return parsedValues
  }

  getClusterCount = (cluster) => {
    const childMarkers = cluster.getAllChildMarkers()
    let clusterCount = 0
    for (let i = 0; i < childMarkers.length; i++) {
      const {lat, lng} = childMarkers[i].getLatLng()      
      const clusterElements = this.props.data.filter((obj) => { 
        return obj.lat === (lat + '') && obj.lng === (lng + '')
      })
      clusterCount +=  clusterElements ? clusterElements.length : clusterCount
    }

    return clusterCount
  }

  fillWorld = () => {

    const filteredValues = this.parseData()
    let markers = window.L.markerClusterGroup({
      iconCreateFunction: (cluster) => {          
        const clusterCount = this.getClusterCount(cluster)
        return window.L.divIcon({ 
          className: 'remove-blank-square',
          html: ReactDOMServer.renderToString(<ClusterIcon brand={this.props.theme.primary}>{clusterCount}</ClusterIcon>),
        });
      }
    })
    
    Object.keys(filteredValues).forEach((key) => {
      if (filteredValues[key].lat && filteredValues[key].lng) {
        const marker = window.L.marker([filteredValues[key].lat, filteredValues[key].lng], {
          riseOnHover: true,
          icon: window.L.divIcon({
            html: ReactDOMServer.renderToString(
              <MarkerWrapper>
                <MarkerTooltip>
                  <div>
                    {filteredValues[key].location}
                  </div>
                  <MarkerCount>
                    { `${filteredValues[key].count} ${this.props.translations.institutions.institutions}` }
                  </MarkerCount>
                </MarkerTooltip>
                <MarkerIcon brand={this.props.theme.primary}/>
              </MarkerWrapper>
            ),
            className: 'remove-blank-square',
            iconAnchor:   [15, 15], // point of the icon which will correspond to marker's location
            popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
          })          
        })
        markers.addLayer(marker)
      }
    })

    this.map.addLayer(markers)
    
  }

  render(){
    return (
      <Wrapper>
        <div id='mapid'>
        </div>
      </Wrapper>
    )
  }
}

export default withTheme(Map)
