import React from "react";
import styled from "styled-components";

function Icon({ color, width, height, padding }) {
  return (
    <IconFilled color={color} width={width} height={height} padding={padding}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 14 14"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_897_759)">
          <path
            d="M0.4375 8.66251C0.553532 8.66251 0.664812 8.7086 0.746859 8.79065C0.828906 8.87269 0.875 8.98397 0.875 9.10001V11.2875C0.875 11.5196 0.967187 11.7421 1.13128 11.9062C1.29538 12.0703 1.51794 12.1625 1.75 12.1625H12.25C12.4821 12.1625 12.7046 12.0703 12.8687 11.9062C13.0328 11.7421 13.125 11.5196 13.125 11.2875V9.10001C13.125 8.98397 13.1711 8.87269 13.2531 8.79065C13.3352 8.7086 13.4465 8.66251 13.5625 8.66251C13.6785 8.66251 13.7898 8.7086 13.8719 8.79065C13.9539 8.87269 14 8.98397 14 9.10001V11.2875C14 11.7516 13.8156 12.1968 13.4874 12.5249C13.1592 12.8531 12.7141 13.0375 12.25 13.0375H1.75C1.28587 13.0375 0.840752 12.8531 0.512563 12.5249C0.184374 12.1968 0 11.7516 0 11.2875V9.10001C0 8.98397 0.0460936 8.87269 0.128141 8.79065C0.210188 8.7086 0.321468 8.66251 0.4375 8.66251Z"
            fill={color}
          />
          <path
            d="M6.69031 10.3722C6.73095 10.413 6.77923 10.4453 6.83239 10.4674C6.88554 10.4894 6.94252 10.5008 7.00006 10.5008C7.05761 10.5008 7.11459 10.4894 7.16774 10.4674C7.2209 10.4453 7.26917 10.413 7.30981 10.3722L9.93481 7.74725C10.017 7.6651 10.0631 7.55368 10.0631 7.4375C10.0631 7.32132 10.017 7.2099 9.93481 7.12775C9.85266 7.0456 9.74124 6.99945 9.62506 6.99945C9.50889 6.99945 9.39747 7.0456 9.31531 7.12775L7.43756 9.00638V1.3125C7.43756 1.19647 7.39147 1.08519 7.30942 1.00314C7.22738 0.921094 7.1161 0.875 7.00006 0.875C6.88403 0.875 6.77275 0.921094 6.69071 1.00314C6.60866 1.08519 6.56256 1.19647 6.56256 1.3125V9.00638L4.68481 7.12775C4.60266 7.0456 4.49124 6.99945 4.37506 6.99945C4.25889 6.99945 4.14747 7.0456 4.06531 7.12775C3.98316 7.2099 3.93701 7.32132 3.93701 7.4375C3.93701 7.55368 3.98316 7.6651 4.06531 7.74725L6.69031 10.3722Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_897_759">
            <rect width="14" height="14" fill={color} />
          </clipPath>
        </defs>
      </svg>
    </IconFilled>
  );
}

const IconFilled = styled.span`
  fill: ${(props) => props.color || props.theme.primary || "white"};
  width: ${(props) => props.width || "14px"};
  height: ${(props) => props.height || "14px"};
`;

export default Icon;