import React from "react";
import { ThemeContext } from "../../utils/themes";

const WithThemeContext = (Component) => {
 const  WrapperComponent = (props) => {
    return (
      <ThemeContext.Consumer>
        {({ gameParams, toggleGameParams }) => (
          <Component {...props} toggleGameParams={toggleGameParams} gameParams={gameParams} />
        )}
      </ThemeContext.Consumer>
    )
  }
  return WrapperComponent
}

export default WithThemeContext
