import React from 'react'
import styled from 'styled-components'
import TextInput from '../../../../../components/TextInput'
import t from '../../../../../utils/i18n'

const ModalForm = ({onChange}) => (
  <Wrapper>
    <InputWrapper>
      <TextInput label={ t('modal.email_label') } name="email" onChange={onChange} />
    </InputWrapper>
    <InputWrapper>
      <TextInput label={ t('modal.subject_label') } name="subject" onChange={onChange}/>
    </InputWrapper>
    <InputWrapper>
      <FormLabel>{ t('modal.message_label') }</FormLabel>
      <MessageBody>
        <p>Hola! Soy Ryan, administrador de Safaritec! Y me gustaría que participaras de nuestro juego para nivelar tu ONG GRATIS!</p>
        <p>Puedes ganar reconocimientos dentro de la red, así como recibir información para poder subir tu nivel como organización!</p>
        <p>Para crear tu cuenta ingresa a : <a href="/">www.safaritec.org/signup.com</a></p>
        <p>Nos vemos alla!</p>
        <p>Saludos,</p>
        <p>Ryan.</p>
      </MessageBody>
    </InputWrapper>
  </Wrapper>
)

const Wrapper = styled.div`
`
const InputWrapper = styled.div`
  padding-top: 1rem;
`
const FormLabel = styled.label`
  color: #D8D8D8;
  font-weight: 500;
  font-size: 0.95rem;
  margin-right: 0.5rem; 
`
const MessageBody = styled.div`
  width: 100%;
  border: none;
  font-size: 0.9rem;
  border-bottom: 1px solid #5856D6;

`

export default ModalForm